import React, { useContext } from 'react'

import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { IReference } from '../models/Reference'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'
import DroppableComponent from './DroppableComponent'
import ReferenceComponent from './ReferenceComponent'

const ReferencesComponent = ({ sectionBgColor = '' }: { sectionBgColor?: string }): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const { resume } = euContext

    return (
        <DroppableComponent<IReference>
            section='references'
            card='reference'
            items={resume.references.reference}
            label={resume.references.label}
            sectionBgColor={sectionBgColor}
            DraggableComponent={ReferenceComponent}
        />
    )
}

export default ReferencesComponent

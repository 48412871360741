import { Box, Button, ListItem, ListItemIcon, Stack } from '@mui/material'
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state'
import HoverPopover from 'material-ui-popup-state/HoverPopover'
import React, { MouseEvent, useContext, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import DragHandleIcon from '@mui/icons-material/DragHandle'
import { Draggable } from 'react-beautiful-dnd'

import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { useAddProject, useMinusProject, useUpdateResumeField } from '../hooks/useResume'
import { IStyles } from '../models/Styles'
import ViewStatementsDialog from '../pages/dialog/ViewStatementsDialog'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'
import { cardStyleHover, cardStyleToUse, dragIconStyleHover, dragIconStyleToUse } from '../utils/Util'
import ErrorState from '../views/ErrorState'
import LoadingState from '../views/LoadingState'
import FieldInputText from './forms/FieldInputText'
import FieldInputTextArea from './forms/FieldInputTextArea'

type Props = {
    compNum: number
    index: number
    isDraggable: boolean
}

const ProjectDraggableComponent = ({ compNum, index, isDraggable }: Props): JSX.Element => {
    const fieldName = `projects.topics[${compNum}]`
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const { resume, setResume } = euContext
    const { reset, setValue } = useFormContext()
    const [openDialog, setOpenDialog] = useState(false)

    const { mutate: addProject, isPending: isAdding, error: addError } = useAddProject()
    const { mutate: minusProject, isPending: isRemoving, error: removeError } = useMinusProject()
    const { mutate: updateResumeField, isPending: isUpdating, isError: updateError } = useUpdateResumeField()

    const handleAddProject = (evt: MouseEvent<HTMLElement>) => {
        evt.preventDefault()
        addProject(resume, {
            onSuccess: (data) => {
                setResume(data.resume)
                reset(data.resume)
            }
        })
    }

    const handleMinusProject = (evt: MouseEvent<HTMLElement>) => {
        evt.preventDefault()
        minusProject(
            { resume, compNum },
            {
                onSuccess: (data) => {
                    setResume(data.resume)
                    reset(data.resume)
                }
            }
        )
    }

    const openSkillsDialog = () => {
        setOpenDialog(true)
    }

    const closeSkillsDialog = () => {
        setOpenDialog(false)
    }

    const addValueToDescription = (value: string[]) => {
        if (value?.length > 0 && resume._id) {
            let addedDescription = resume.projects.topics[compNum].description?.slice() ?? ''
            if (addedDescription.length > 0) addedDescription += '\n'

            addedDescription += '- ' + value.join('\n')

            const descriptionField = `${fieldName}.description`
            updateResumeField(
                { id: resume._id, fieldName: descriptionField, fieldValue: addedDescription },
                {
                    onSuccess: (data) => {
                        setResume(data.resume)
                        setValue(descriptionField, addedDescription, { shouldValidate: true })
                    }
                }
            )
        }
    }

    const addValueToSkill = (value: string) => {
        console.log(value)
    }

    const addValueToTitle = (value: string) => {
        if (value && resume._id) {
            const descriptionField = `${fieldName}.topic`

            updateResumeField(
                { id: resume._id, fieldName: descriptionField, fieldValue: value },
                {
                    onSuccess: (data) => {
                        euContext.setResume(data.resume)
                        setValue(descriptionField, value, { shouldValidate: true })
                    }
                }
            )
        }
    }

    if (isAdding || isRemoving || isUpdating) {
        return <LoadingState />
    }

    if (addError || removeError || updateError) {
        return <ErrorState message={'Error updating project.'} />
    }

    return (
        <PopupState
            variant='popover'
            popupId='projectsList'>
            {(popupState) => (
                <>
                    {!!euContext.user?.email && (
                        <>
                            <HoverPopover
                                {...bindPopover(popupState)}
                                disableScrollLock={true}
                                sx={{ top: '-40px' }}
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
                                <Stack
                                    direction='row'
                                    columnGap={1}
                                    margin={0.5}>
                                    <Button
                                        onClick={openSkillsDialog}
                                        size='small'
                                        variant='outlined'
                                        sx={{ paddingLeft: 1, paddingRight: 1, margin: 0 }}>
                                        AI Assist
                                    </Button>
                                    <Button
                                        onClick={handleAddProject}
                                        type='button'
                                        size='small'
                                        variant='outlined'
                                        sx={{ padding: 0, margin: 0, width: '20px' }}>
                                        +
                                    </Button>
                                    <Button
                                        onClick={handleMinusProject}
                                        type='button'
                                        size='small'
                                        variant='outlined'
                                        sx={{ padding: 0, margin: 0, width: '20px' }}>
                                        -
                                    </Button>
                                </Stack>
                            </HoverPopover>
                            <ViewStatementsDialog
                                open={openDialog}
                                onClose={closeSkillsDialog}
                                addValueToDescription={addValueToDescription}
                                selectJobSkill={addValueToSkill}
                                selectJobTitle={addValueToTitle}
                            />
                        </>
                    )}
                    <Draggable
                        draggableId={(resume.projects.topics[compNum]?.topic || 'Topic') + index}
                        index={index}
                        isDragDisabled={!euContext.user?.email}>
                        {(provided) => (
                            <ListItem
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                sx={{ width: '100%' }}>
                                <ListItemIcon
                                    {...provided.dragHandleProps}
                                    sx={popupState.isOpen ? { ...dragIconStyleToUse(isDraggable), ...dragIconStyleHover } : dragIconStyleToUse(isDraggable)}>
                                    <DragHandleIcon />
                                </ListItemIcon>

                                <Box
                                    width='100%'
                                    rowGap={0}
                                    sx={popupState.isOpen ? { ...cardStyleToUse(isDraggable, style.SpaceCard), ...cardStyleHover } : cardStyleToUse(isDraggable, style.SpaceCard)}
                                    {...bindHover(popupState)}>
                                    <Stack
                                        direction='column'
                                        spacing={0}
                                        rowGap={0}
                                        sx={{ flexGrow: 1 }}>
                                        <FieldInputText
                                            name={`${fieldName}.topic`}
                                            label=''
                                            variant='outlined'
                                            placeholder={popupState.isOpen ? 'Enter Project Topic' : ''}
                                            fontFamily={style[style.JobTitleFontName]}
                                            sx={{
                                                fontSize: `${style?.JobTitleFontSize ?? 5}px`,
                                                fontStyle: style?.JobTitleFontStyle ?? 'initial',
                                                alignSelf: style?.JobTitleAlign ?? 'end',
                                                fontWeight: style?.JobTitleFontWeight ?? 'initial'
                                            }}
                                        />
                                        <FieldInputTextArea
                                            name={`${fieldName}.description`}
                                            label=''
                                            placeholder={popupState.isOpen ? 'Enter Project Description' : ''}
                                            sx={{
                                                fontSize: `${style?.BodyFontSize ?? 5}px`,
                                                fontStyle: style?.BodyFontStyle ?? 'initial',
                                                alignSelf: style?.BodyAlign ?? 'end',
                                                fontWeight: style?.BodyFontWeight ?? 'initial'
                                            }}
                                        />
                                    </Stack>
                                </Box>
                            </ListItem>
                        )}
                    </Draggable>
                </>
            )}
        </PopupState>
    )
}

export default ProjectDraggableComponent

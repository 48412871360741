import { RefObject, useEffect, useState } from 'react'

const useGetElementPosition = (elementRef: RefObject<HTMLElement>, trigger: boolean, offsetX: number = 0, offsetY: number = 0) => {
    const [position, setPosition] = useState({ top: 0, left: 0 })

    useEffect(() => {
        if (elementRef.current && trigger) {
            const rect = elementRef.current.getBoundingClientRect()

            setPosition({
                top: rect.top + offsetY,
                left: rect.left + offsetX
            })
        }
    }, [elementRef, trigger, offsetX, offsetY])

    return position
}

export default useGetElementPosition

import React, { useContext } from 'react'
import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'
import DroppableComponent from './DroppableComponent'
import PublicationDraggableComponent from './PublicationDraggableComponent'

const PublicationsDroppableComponent = ({ sectionBgColor = '' }: { sectionBgColor?: string }): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const { resume } = euContext

    return (
        <DroppableComponent
            section='publications'
            card='publication'
            items={resume.publications.publication}
            label={resume.publications.label}
            sectionBgColor={sectionBgColor}
            DraggableComponent={PublicationDraggableComponent}
        />
    )
}

export default PublicationsDroppableComponent

import { Alignments, DateDelims, DateFormats, FontCases, FontNames, FontStyles, FontWeights, IStyles, ResumeAlignments } from '../models/Styles'
import { validResumeStyles } from '../utils/Constants'

const Resume8: IStyles = {
    StyleName: 'Resume8' as validResumeStyles,
    PrimaryFontName: FontNames.IBMPlexSansCondensed,
    SecondaryFontName: FontNames.IBMPlexSansCondensed,
    AlternateFontName: FontNames.Barlow,
    NameFontName: 'PrimaryFontName',
    NameFontSize: 48,
    NameFontWeight: FontWeights.Initial,
    NameFontStyle: FontStyles.Initial,
    NameAlign: Alignments.Center,
    NameFontCase: FontCases.Uppercase,
    TitleFontName: 'PrimaryFontName',
    TitleFontSize: 16,
    TitleFontWeight: FontWeights.Initial,
    TitleFontStyle: FontStyles.Initial,
    TitleAlign: Alignments.Center,
    TitleFontCase: FontCases.Uppercase,
    AddressFontName: 'PrimaryFontName',
    AddressFontSize: 12,
    AddressFontWeight: FontWeights.Initial,
    AddressFontStyle: FontStyles.Initial,
    AddressAlign: Alignments.Start,
    AddressFontCase: FontCases.None,
    SectionFontName: 'PrimaryFontName',
    SectionFontSize: 16,
    SectionFontWeight: FontWeights.Bold,
    SectionFontStyle: FontStyles.Initial,
    SectionAlign: Alignments.Start,
    SectionFontCase: FontCases.Uppercase,
    DateFontName: 'PrimaryFontName',
    DateFontSize: 12,
    DateFontWeight: FontWeights.Initial,
    DateFontStyle: FontStyles.Initial,
    DateAlign: Alignments.Start,
    DateFontCase: FontCases.None,
    DateFormat: DateFormats.MMyyyy,
    DateDelim: DateDelims.Dash,
    CompanyFontName: 'PrimaryFontName',
    CompanyFontSize: 12,
    CompanyFontWeight: FontWeights.Initial,
    CompanyFontStyle: FontStyles.Initial,
    CompanyAlign: Alignments.Start,
    CompanyFontCase: FontCases.None,
    JobTitleFontName: 'PrimaryFontName',
    JobTitleFontSize: 12,
    JobTitleFontWeight: FontWeights.Bold,
    JobTitleFontStyle: FontStyles.Initial,
    JobTitleAlign: Alignments.Start,
    JobTitleFontCase: FontCases.None,
    BodyFontName: 'PrimaryFontName',
    BodyFontSize: 12,
    BodyFontWeight: FontWeights.Initial,
    BodyFontStyle: FontStyles.Initial,
    BodyAlign: Alignments.Start,
    BodyFontCase: FontCases.None,
    Special: '',
    Alignment: ResumeAlignments.Left,
    SpaceHeader: 6,
    SpaceSectionTitle: 6,
    SpaceCard: 6,
    SpaceSection: 10,
    SpaceBorder: 1,
    BorderLocation: '',
    BorderStyle: '',
    seqNum: 1,
    ShowGraphic: false,
    hasGraphic: true,
    Colors: false
}

export default Resume8

import React, { FocusEvent, useContext, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { FormControl, FormHelperText, FormLabel, TextField, Typography, type TextFieldProps } from '@mui/material'
import { EmploymentUniverseContext } from 'context/EmploymentUniverseContext'
import { useUpdateResumeField } from '../../hooks/useResume'
import { IStyles } from '../../models/Styles'

type Props = {
    name: string
    label: string
    fontSize?: string
    fontStyle?: string
    fontWeight?: string
    alignSelf?: string
} & TextFieldProps

const FieldInputEmail = ({ name, label, fontSize = '12px', fontStyle = 'inherit', fontWeight = 'inherit', alignSelf = 'inherit', ...others }: Props): JSX.Element => {
    const { control } = useFormContext()
    const euContext = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const { setValue } = useFormContext()
    const [editable, setEditable] = useState(false)
    const { mutate: updateResumeField } = useUpdateResumeField()

    const handleBlur = async (evt: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value: string | null = evt.target.value

        if (value && euContext.resume._id) {
            // Update resume field in database.
            updateResumeField(
                { id: euContext.resume._id, fieldName: name, fieldValue: value },
                {
                    onSuccess: (data) => {
                        euContext.setResume(data.resume)
                    }
                }
            )

            // Update form field in context.
            setValue(name, value)

            setEditable(false)
        }
    }

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormControl error={!!error}>
                    {label && <FormLabel>{label}</FormLabel>}
                    {editable ? (
                        <TextField
                            type='email'
                            value={value}
                            size='small'
                            onChange={onChange}
                            onBlur={handleBlur}
                            {...others}
                        />
                    ) : (
                        <Typography
                            fontFamily={style[style.BodyFontName]}
                            fontSize={fontSize}
                            fontStyle={fontStyle}
                            fontWeight={fontWeight}
                            alignSelf={alignSelf}
                            onClick={() => setEditable(!!euContext.user?.email)}
                            mt={0.7}>
                            {value}
                        </Typography>
                    )}
                    <FormHelperText>{error?.message}</FormHelperText>
                </FormControl>
            )}
        />
    )
}

export default FieldInputEmail

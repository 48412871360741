// axiosConfig.ts
import axios from 'axios'
import { SERVER_LOCATION, SERVER_PORT } from '../utils/ServerConstants'

process.env.NODE_ENV !== 'production'
const instance = axios.create({
    baseURL: `http://${SERVER_LOCATION}:${SERVER_PORT}`, // Set your base URL here
    timeout: 5000 // Set a timeout for requests (optional)
})

instance.interceptors.request.use(
    (config) => {
        // Get the token from your authentication state
        const token: string | null = localStorage.getItem('token')
        // Set the token in the request header
        if (token && token.length > 0) {
            config.headers['Authorization'] = `Bearer ${token}`
        }
        return config
    },
    (error) => {
        console.error('Error in request', error)
        return Promise.reject(error)
    }
)
export default instance

import { Alert, Snackbar } from '@mui/material'
import React, { useContext } from 'react'
import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import useTokenExpiryAlert from '../hooks/useTokens'

const TokenExpiryAlert: React.FC = () => {
    const { token } = useContext(EmploymentUniverseContext)
    const isExpiringSoon = useTokenExpiryAlert(token)

    return (
        <Snackbar
            open={isExpiringSoon}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={60000}>
            <Alert severity='warning'>Your session is about to expire. Please renew your token or re-login to continue.</Alert>
        </Snackbar>
    )
}

export default TokenExpiryAlert

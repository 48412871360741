import { Box, Tab, Tabs } from '@mui/material'
import React, { useState } from 'react'
import ClientSettings from '../components/ClientSettings'
import ClientUsers from '../components/ClientUsers'
import SiteCustomization from '../components/SiteCustomization'
import SubscriptionInfo from '../components/SubscriptionInfo'

const SettingsPage = () => {
    const [tabIndex, setTabIndex] = useState(0)

    const handleTabChange = (event: React.SyntheticEvent, newIndex: number) => {
        setTabIndex(newIndex)
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                centered>
                <Tab label='Subscription Info' />
                <Tab label='Customization' />
                <Tab label='Client Settings' />
                <Tab label='Users' />
            </Tabs>
            <Box sx={{ p: 3 }}>
                {tabIndex === 0 && <SubscriptionInfo />}
                {tabIndex === 1 && <SiteCustomization />}
                {tabIndex === 2 && <ClientSettings />}
                {tabIndex === 3 && <ClientUsers />}
            </Box>
        </Box>
    )
}

export default SettingsPage

import React, { ChangeEvent, useContext, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Checkbox, FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, Typography, type SelectChangeEvent, type SelectProps } from '@mui/material'
import { EmploymentUniverseContext } from 'context/EmploymentUniverseContext'
import { format } from 'date-fns'
import { months, years } from 'utils/Util'
import { useUpdateResumeField } from '../../hooks/useResume'
import { IStyles } from '../../models/Styles'

type Props = {
    name: string
    label: string
    includePresent?: boolean
    fontSize?: string
    alignSelf?: string
    fontStyle?: string
    fontWeight?: string
    dateFormat?: string
    sx?: object
} & SelectProps

const FieldInputMonth = ({
    name,
    label,
    fontSize = '12px',
    fontStyle = 'inherit',
    alignSelf = 'inherit',
    fontWeight = 'inherit',
    dateFormat = 'yyyy',
    sx = {},
    includePresent = false,
    ...others
}: Props): JSX.Element => {
    const { control } = useFormContext()
    const euContext = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const { getValues, setValue } = useFormContext()
    const [editable, setEditable] = useState(false)
    const { mutate: updateResumeField } = useUpdateResumeField()

    const handleChangeMonth = async (event: SelectChangeEvent<unknown>) => {
        const newValue = event.target.value as string
        if (newValue && euContext.resume._id) {
            // Update form field in context.
            setValue(`${name}Month`, newValue)

            // Update resume field in database.
            updateResumeField(
                { id: euContext.resume._id, fieldName: `${name}Month`, fieldValue: newValue },
                {
                    onSuccess: (data) => {
                        euContext.setResume(data.resume)
                    }
                }
            )
        }

        setEditable(false)
    }

    const handleChangeYear = async (event: SelectChangeEvent<unknown>) => {
        const newValue = event.target.value as string
        if (newValue && euContext.resume._id) {
            // Update form field in context.
            setValue(`${name}Year`, newValue)

            // Update resume field in database.
            updateResumeField(
                { id: euContext.resume._id, fieldName: `${name}Year`, fieldValue: newValue },
                {
                    onSuccess: (data) => {
                        euContext.setResume(data.resume)
                    }
                }
            )
        }

        setEditable(false)
    }

    const handleChange = async (evt: ChangeEvent<HTMLInputElement>) => {
        const checked = evt.target.checked
        const today = new Date()

        if (euContext.resume._id) {
            // Update form field in context.
            setValue(`${name}Year`, checked ? 0 : today.getFullYear())
            setValue(`${name}Month`, checked ? 0 : today.getMonth() + 1)

            // Update resume field in database.
            updateResumeField({ id: euContext.resume._id, fieldName: `${name}Year`, fieldValue: checked ? 0 : today.getFullYear() })
            updateResumeField({ id: euContext.resume._id, fieldName: `${name}Month`, fieldValue: checked ? 0 : today.getMonth() + 1 })

            // Update resume field in context.
            euContext.setResume({ ...euContext.resume, [`${name}Year`]: checked ? 0 : today.getFullYear() })
            euContext.setResume({ ...euContext.resume, [`${name}Month`]: checked ? 0 : today.getMonth() + 1 })
        }
    }

    const yearValue: number = getValues(`${name}Year`)
    const monthValue: number = getValues(`${name}Month`)

    return (
        <Stack direction='row'>
            <Controller
                name={`${name}Month`}
                control={control}
                render={({ field: { value }, fieldState: { error } }) => (
                    <FormControl>
                        {label && <InputLabel>{label}</InputLabel>}
                        {editable && value > 0 && (
                            <Select
                                value={value}
                                onChange={handleChangeMonth}
                                onBlur={() => {
                                    setEditable(false)
                                }}
                                variant='outlined'
                                disabled={includePresent && value === 0}
                                size='small'
                                {...others}>
                                {months.map((month: string, idx: number) => (
                                    <MenuItem
                                        key={idx}
                                        value={idx + 1}>
                                        {month}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                        <FormHelperText>{error?.message ?? ''}</FormHelperText>
                    </FormControl>
                )}
            />
            {!editable && (
                <Typography
                    fontFamily={style[style.BodyFontName]}
                    fontSize={fontSize}
                    fontStyle={fontStyle}
                    alignSelf={alignSelf}
                    fontWeight={fontWeight}>
                    &nbsp;
                </Typography>
            )}
            <Controller
                name={`${name}Year`}
                control={control}
                render={({ field: { value }, fieldState: { error } }) => (
                    <FormControl>
                        {label && <InputLabel>{label}</InputLabel>}
                        {editable && value > 0 && (
                            <Select
                                value={value}
                                onChange={handleChangeYear}
                                onBlur={() => {
                                    setEditable(false)
                                }}
                                variant='outlined'
                                size='small'
                                {...others}>
                                {years.map((i) => (
                                    <MenuItem
                                        key={i}
                                        value={i}>
                                        {i}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                        <FormHelperText>{error?.message ?? ''}</FormHelperText>
                    </FormControl>
                )}
            />
            {!editable && yearValue !== 0 && monthValue && (
                <Typography
                    fontFamily={style[style.BodyFontName]}
                    fontSize={fontSize}
                    fontStyle={fontStyle}
                    alignSelf={alignSelf}
                    fontWeight={fontWeight}
                    onClick={() => setEditable(true && !!euContext.user?.email)}
                    sx={sx}>
                    {format(new Date(yearValue, monthValue - 1), dateFormat)}
                </Typography>
            )}
            {!editable && includePresent && yearValue === 0 && (
                <Typography
                    fontFamily={style[style.BodyFontName]}
                    fontSize={fontSize}
                    fontStyle={fontStyle}
                    alignSelf={alignSelf}
                    fontWeight={fontWeight}
                    sx={sx}>
                    Present
                </Typography>
            )}
            {editable && includePresent && (
                <Checkbox
                    checked={yearValue === 0}
                    onChange={handleChange}
                    sx={{ ml: 1, mt: 1 }}
                />
            )}
        </Stack>
    )
}

export default FieldInputMonth

import { Stack, Typography } from '@mui/material'
import React, { useContext } from 'react'

import { format } from 'date-fns'
import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { IEmployment } from '../models/Employment'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'
import FieldInputTypography from './forms/FieldInputTypography'

interface Props {
    sectionBgColor?: string
}

const LicenseListComponent = ({ sectionBgColor = '' }: Props): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style = euContext.resume.style[euContext.resume.currentStyle]
    const orderedLicense: IEmployment[] = euContext.resume.licenses.license.sort((a, b) => b.startYear - a.startYear)

    return (
        <Stack>
            <FieldInputTypography
                name='licenses.label'
                fontFamily={style[style.SectionFontName]}
                fontSize={`${style?.SectionFontSize ?? 5}px`}
                fontStyle={style?.SectionFontStyle ?? 'initial'}
                alignSelf={style?.SectionAlign ?? 'initial'}
                textAlign={style?.SectionAlign ?? 'initial'}
                width='100%'
                bgcolor={sectionBgColor}
                fontWeight={style?.SectionFontWeight ?? 'initial'}
                marginBottom={`${style?.SpaceSectionTitle}px`}
                sx={{ textTransform: style?.SectionFontCase ?? 'none' }}
            />
            <Stack
                direction='column'
                rowGap={1}>
                {orderedLicense.map((emp: IEmployment, index: number) => {
                    return (
                        <Stack
                            direction='column'
                            key={index}>
                            <Typography
                                fontFamily={style[style.CompanyFontName]}
                                fontSize={`${style.CompanyFontSize}px`}
                                fontStyle={style.CompanyFontStyle}
                                fontWeight={style.CompanyFontWeight}
                                textAlign={style.CompanyAlign}>
                                {emp.companyName}&nbsp;-&nbsp;{emp.title}
                            </Typography>
                            <Typography
                                fontFamily={style[style.BodyFontName]}
                                fontSize={`${style.BodyFontSize}px`}
                                fontStyle={style.BodyFontStyle}
                                fontWeight={style.BodyFontWeight}
                                textAlign={style.BodyAlign}>
                                {emp.summary.description}
                            </Typography>
                            <Typography
                                fontFamily={style[style.BodyFontName]}
                                fontSize={`${style.BodyFontSize}px`}
                                fontStyle={style.BodyFontStyle}
                                fontWeight={style.BodyFontWeight}
                                textAlign={style.BodyAlign}>
                                {format(new Date(emp.startYear, emp.startMonth - 1), style.DateFormat)}
                            </Typography>
                        </Stack>
                    )
                })}
            </Stack>
        </Stack>
    )
}

export default LicenseListComponent

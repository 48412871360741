import { Stack } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { type ClassNameMap, withStyles } from '@mui/styles'
import { SummaryComponent } from 'components/SummaryComponent'
import React from 'react'
import { AllDefaultCards, defaultCard } from 'utils/Util'
import { ContactComponent } from '../components/ContactComponent'
import { EducationSummaryComponent } from '../components/EducationSummaryComponent'
import FieldInputTypography from '../components/forms/FieldInputTypography'
import { NameComponent } from '../components/NameComponent'
import { PictureComponent } from '../components/PictureComponent'
import backgroundImage from '../images/background/th-2022402106.jpg'
import { IResume } from '../models/Resume'

const BackgroundStyle = withStyles({
    root: {
        backgroundImage: `url(${backgroundImage})`, // Replace with your image path
        backgroundSize: 'cover',
        height: '20vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'start'
    }
})(({ classes }: { classes: ClassNameMap }) => (
    <Grid
        container
        className={classes.root}>
        <Grid
            xs={4}
            paddingLeft={3}>
            <PictureComponent
                picWidth={140}
                picHeight={140}
                picAlign='start'
                picMT={2}
            />
        </Grid>
        <Grid
            xs={8}
            mt={4}>
            <NameComponent />
            <FieldInputTypography
                name='resumeName'
                canEdit={false}
            />
        </Grid>
    </Grid>
))

const Resume2 = (resume: IResume): JSX.Element => {
    const orderUsedCards = AllDefaultCards.Resume2.filter((value: defaultCard) => {
        const style = resume.cardOrder[resume.currentStyle]
        if (style) {
            return (style[value.cardType] ?? -1) >= 0
        }
        return false
    }).sort((a: defaultCard, b: defaultCard) => {
        return (resume.cardOrder[resume.currentStyle][a.cardType] ?? -1) - (resume.cardOrder[resume.currentStyle][b.cardType] ?? -1)
    })

    return (
        <Stack direction='column'>
            <BackgroundStyle />
            <Stack
                direction='row'
                fontSize={resume.style[resume.currentStyle].BodyFontSize}
                fontStyle={resume.style[resume.currentStyle].BodyFontStyle}
                fontWeight={resume.style[resume.currentStyle].BodyFontWeight}
                fontFamily={resume.style[resume.currentStyle].BodyFontName}
                marginTop={`${resume.style[resume.currentStyle].SpaceHeader}px`}
                columnGap={0}>
                <Stack
                    direction='column'
                    width='230px'
                    paddingLeft={3}
                    paddingRight={3}
                    rowGap={`${resume.style[resume.currentStyle].SpaceSection}px`}>
                    <SummaryComponent />
                    <ContactComponent includeAddress={true} />
                    <EducationSummaryComponent />
                </Stack>
                <Stack
                    direction='column'
                    width='585px'
                    paddingLeft={3}
                    paddingRight={3}
                    sx={{ backgroundColor: '#f7f9f9' }}>
                    {orderUsedCards.map((value: defaultCard) => {
                        return <value.cardComponent key={value.cardType} />
                    })}
                </Stack>
            </Stack>
        </Stack>
    )
}

export default Resume2

import { Box, Checkbox, IconButton, Stack, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { IStatements } from '../../models/Statements'
import CustomTable from '../CustomTable'

interface IStatementComponent {
    label: string
    statements: IStatements[]
    addStatements: Function
}

interface HeadCell {
    disablePadding: boolean
    id: keyof IStatements
    label: string
    numeric: boolean
    width: string
}

const headCells: readonly HeadCell[] = [
    {
        id: 'statement',
        numeric: false,
        disablePadding: false,
        label: 'Statement',
        width: '90%'
    }
]

interface EnhancedTableProps {
    numSelected: number
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
    rowCount: number
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, numSelected, rowCount } = props

    return (
        <TableHead>
            <TableRow>
                <TableCell padding='checkbox'>
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all statements'
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        style={{ width: headCell.width }}>
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

interface EnhancedTableToolbarProps {
    numSelected: number
    label: string
    addStatements: Function
    selected: number[]
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const { label, numSelected, addStatements, selected } = props

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                py: 1,
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) => theme.palette.action.selected
                })
            }}>
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    component='div'>
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    variant='h6'
                    sx={{ flex: '1 1 100%' }}
                    id='tableTitle'
                    component='div'>
                    {label}
                </Typography>
            )}

            <Stack
                direction='row'
                spacing={1}>
                {numSelected > 0 && (
                    <Tooltip title='Resolve'>
                        <IconButton
                            size='small'
                            onClick={() => addStatements(selected)}>
                            <CheckCircleOutlineIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </Stack>
        </Box>
    )
}

const ViewStatementsComponent = (props: IStatementComponent) => {
    const { label, statements, addStatements } = props
    const [selected, setSelected] = useState<readonly number[]>([])
    const isSelected = (i: number) => selected.indexOf(i) !== -1

    useEffect(() => {
        setSelected([])
    }, [statements])

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = statements.map((_, i) => i)
            setSelected(newSelected)
            return
        }
        setSelected([])
    }

    const handleClick = (event: React.MouseEvent<unknown>, selectedIndex: number) => {
        event.stopPropagation()

        let newSelected: readonly number[] = []
        const indexNum = selected.indexOf(selectedIndex)

        if (indexNum >= 0) {
            newSelected = newSelected.concat(selected.slice(0, indexNum), selected.slice(indexNum + 1))
        } else {
            newSelected = selected.concat(selectedIndex)
        }

        setSelected(newSelected)
    }

    const handleAddStatement = () => {
        addStatements(selected)
        setSelected([])
    }

    const rows = statements.map((statement, index) => ({
        statement: (
            <Tooltip title={statement.statement}>
                <Typography>{statement.statement}</Typography>
            </Tooltip>
        ),
        isSelected: isSelected(index)
    }))

    return (
        <Stack direction='column'>
            <EnhancedTableToolbar
                numSelected={selected.length}
                label={label}
                addStatements={handleAddStatement}
                selected={selected.slice()}
            />
            <CustomTable
                columns={headCells.map((headCell) => ({
                    id: headCell.id,
                    label: headCell.label,
                    align: headCell.numeric ? 'right' : 'left'
                }))}
                rows={rows}
                EnhancedTableHeadComponent={EnhancedTableHead}
                handleRowClick={handleClick}
                toolbarProps={{
                    numSelected: selected.length,
                    onSelectAllClick: handleSelectAllClick,
                    rowCount: statements.length,
                    label,
                    addStatements,
                    selected: selected.slice()
                }}
            />
        </Stack>
    )
}

export default ViewStatementsComponent

import { validResumeStyles } from '@resumebuilder/client/src/utils/Constants'

export enum FontNames {
    Aboreto = 'Aboreto',
    Barlow = 'Barlow Semi Condensed',
    BilboSwashCaps = 'Bilbo Swash Caps',
    BonaNovaSC = 'Bona Nova SC',
    Comic = 'Comic Neue',
    Electrolize = 'Electrolize',
    Gayathri = 'Gayathri',
    GFSNeohellenic = 'GFS Neohellenic',
    IBMPlexSansCondensed = 'IBM Plex Sans Condensed',
    Montserrat = 'Montserrat',
    NotoNaskhArabic = 'Noto Naskh Arabic',
    NotoSansOldItalic = 'Noto Sans Old Italic',
    NotoSerifKhitan = 'Noto Serif Khitan Small Script',
    Nunito = 'Nunito',
    NunitoSans = 'Nunito Sans',
    PlayfairDisplay = 'Playfair Display',
    RethinkSans = 'Rethink Sans',
    Roboto = 'Roboto Condensed',
    SairaSemiCondensed = 'Saira Semi Condensed',
    ShadowsIntoLight2 = 'Shadows Into Light Two',
    SortsMillGoudy = 'Sorts Mill Goudy',
    SixCaps = 'Six Caps',
    SofiaSansSemiCondensed = 'Sofia Sans Semi Condensed',
    STIXTwoText = 'STIX Two Text',
    YanoneKaffeesatz = 'Yanone Kaffeesatz'
}

export enum FontStyles {
    Initial = 'initial',
    Italic = 'italic'
}

export enum FontWeights {
    Initial = 'initial',
    Bold = 'bold'
}

export enum Alignments {
    Start = 'start',
    Center = 'center',
    End = 'end'
}

export enum FontCases {
    None = 'none',
    Capitalize = 'capitalize',
    Uppercase = 'uppercase',
    Lowercase = 'lowercase'
}

export enum DateDelims {
    To = 'to',
    Dash = '-'
}

export enum DateFormats {
    Myyyy = 'M/yyyy',
    MMyyyy = 'MM/yyyy',
    MMMyyyy = 'MMM yyyy',
    MMMMyyyy = 'MMMM yyyy',
    yyyy = 'yyyy'
}

export enum ResumeAlignments {
    Left = 'Left',
    Indented = 'Indented',
    SlightlyIndented = 'Slightly Indented',
    None = ''
}

export type ResumeAlignmentsKeys = keyof typeof ResumeAlignments

export enum FontNameFields {
    PrimaryFontName = 'Primary Font Name',
    SecondaryFontName = 'Secondary Font Name',
    AlternateFontName = 'Alternate Font Name'
}

export type FontNameKeys = keyof typeof FontNameFields

export interface IStyles {
    StyleName: validResumeStyles
    PrimaryFontName: FontNames
    SecondaryFontName: FontNames
    AlternateFontName: FontNames
    NameFontName: FontNameKeys
    NameFontSize: number
    NameFontWeight: FontWeights
    NameFontStyle: FontStyles
    NameAlign: Alignments
    NameFontCase: FontCases
    TitleFontName: FontNameKeys
    TitleFontSize: number
    TitleFontWeight: FontWeights
    TitleFontStyle: FontStyles
    TitleAlign: Alignments
    TitleFontCase: FontCases
    AddressFontName: FontNameKeys
    AddressFontSize: number
    AddressFontWeight: FontWeights
    AddressFontStyle: FontStyles
    AddressAlign: Alignments
    AddressFontCase: FontCases
    SectionFontName: FontNameKeys
    SectionFontSize: number
    SectionFontWeight: FontWeights
    SectionFontStyle: FontStyles
    SectionAlign: Alignments
    SectionFontCase: FontCases
    DateFontName: FontNameKeys
    DateFontSize: number
    DateFontWeight: FontWeights
    DateFontStyle: FontStyles
    DateAlign: Alignments
    DateFontCase: FontCases
    DateFormat: DateFormats
    DateDelim: DateDelims
    CompanyFontName: FontNameKeys
    CompanyFontSize: number
    CompanyFontWeight: FontWeights
    CompanyFontStyle: FontStyles
    CompanyAlign: Alignments
    CompanyFontCase: FontCases
    JobTitleFontName: FontNameKeys
    JobTitleFontSize: number
    JobTitleFontWeight: FontWeights
    JobTitleFontStyle: FontStyles
    JobTitleAlign: Alignments
    JobTitleFontCase: FontCases
    BodyFontName: FontNameKeys
    BodyFontSize: number
    BodyFontWeight: FontWeights
    BodyFontStyle: FontStyles
    BodyAlign: Alignments
    BodyFontCase: FontCases
    Special: string
    Alignment: ResumeAlignmentsKeys
    SpaceHeader: number
    SpaceSectionTitle: number
    SpaceCard: number
    SpaceSection: number
    SpaceBorder: number
    BorderLocation: string
    BorderStyle: string
    seqNum: number
    ShowGraphic: boolean
    hasGraphic: boolean
    Colors: boolean
}

import { Stack } from '@mui/material'
import React, { useContext, useRef, useState } from 'react'

import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import useGetElementPosition from '../hooks/useGetElementPosition'
import { getLabel } from '../labels/labelUtils'
import { IStyles } from '../models/Styles'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'
import EditModal from './EditModal'
import FieldInputTextArea from './forms/FieldInputTextArea'
import FieldInputTypography from './forms/FieldInputTypography'

const SummaryContent = ({ sectionBgColor, style, onClick, isHighlighted }: { sectionBgColor?: string; style: IStyles; onClick?: () => void; isHighlighted?: boolean }) => (
    <Stack
        spacing={0}
        direction='column'
        marginBottom={`${style?.SpaceCard ?? 10}px`}
        onClick={onClick}
        sx={{
            cursor: onClick ? 'pointer' : 'default'
        }}>
        <FieldInputTypography
            name='summary.label'
            fontFamily={style[style.SectionFontName]}
            fontSize={`${style?.SectionFontSize ?? 5}px`}
            fontStyle={style?.SectionFontStyle ?? 'initial'}
            alignSelf={style?.SectionAlign ?? 'initial'}
            textAlign={style?.SectionAlign ?? 'initial'}
            width='100%'
            bgcolor={sectionBgColor}
            fontWeight={style?.SectionFontWeight ?? 'initial'}
            marginBottom={`${style?.SpaceSectionTitle}px`}
            sx={{
                mt: 2,
                textTransform: style?.SectionFontCase ?? 'none',
                zIndex: 2,
                cursor: onClick ? 'pointer' : 'default',
                border: isHighlighted ? '2px solid #1976d2' : 'none',
                boxShadow: isHighlighted ? '0px 0px 10px rgba(25, 118, 210, 0.5)' : 'none',
                borderRadius: isHighlighted ? '8px' : 'none',
                padding: isHighlighted ? '10px' : '0'
            }}
        />
        <FieldInputTextArea
            name='summary.description'
            label=''
            placeholder={getLabel('my-domain', 'summaryCardDefaultDescription')}
            fontSize={`${style?.BodyFontSize ?? 5}px`}
            fontStyle={style?.BodyFontStyle ?? 'initial'}
            alignSelf={style?.BodyAlign ?? 'end'}
            fontWeight={style?.BodyFontWeight ?? 'initial'}
            sx={{
                textTransform: style?.BodyFontCase ?? 'none',
                zIndex: 2,
                cursor: onClick ? 'pointer' : 'default',
                border: isHighlighted ? '2px solid #1976d2' : 'none',
                boxShadow: isHighlighted ? '0px 0px 10px rgba(25, 118, 210, 0.5)' : 'none',
                borderRadius: isHighlighted ? '8px' : 'none',
                padding: isHighlighted ? '10px' : '0'
            }}
        />
    </Stack>
)

export const SummaryComponent = ({ sectionBgColor = '' }: { sectionBgColor?: string }): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]

    const [isModalOpen, setModalOpen] = useState(false)
    const summaryRef = useRef<HTMLDivElement | null>(null)

    const modalPosition = useGetElementPosition(summaryRef, isModalOpen, 290, 0)

    const handleOpenModal = () => {
        setModalOpen(true)
    }

    const handleCloseModal = () => {
        setModalOpen(false)
    }

    return (
        <>
            <div ref={summaryRef}>
                <SummaryContent
                    sectionBgColor={sectionBgColor}
                    style={style}
                    onClick={handleOpenModal}
                    isHighlighted={isModalOpen}
                />
            </div>
            <EditModal
                open={isModalOpen}
                onClose={handleCloseModal}
                modalPosition={modalPosition}
                title='Edit Summary'
                component={
                    <SummaryContent
                        sectionBgColor={sectionBgColor}
                        style={style}
                        key='summary-modal'
                    />
                }
            />
        </>
    )
}

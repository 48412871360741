import { Box, Button, Grid, ListItem, ListItemIcon, Stack } from '@mui/material'
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state'
import React, { MouseEvent, useContext } from 'react'

import DragHandleIcon from '@mui/icons-material/DragHandle'
import { Draggable } from 'react-beautiful-dnd'

import HoverPopover from 'material-ui-popup-state/HoverPopover'
import { useFormContext } from 'react-hook-form'
import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { useAddTalent, useMinusTalent } from '../hooks/useResume'
import { IStyles } from '../models/Styles'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'
import { cardStyleHover, cardStyleToUse, dragIconStyleHover, dragIconStyleToUse } from '../utils/Util'
import ErrorState from '../views/ErrorState'
import LoadingState from '../views/LoadingState'
import FieldInputText from './forms/FieldInputText'
import FieldInputTextArea from './forms/FieldInputTextArea'
import FieldInputTypography from './forms/FieldInputTypography'

type Props = {
    compNum: number
    index: number
    isDraggable: boolean
}

const TalentDraggableComponent = ({ compNum, index, isDraggable }: Props): JSX.Element => {
    const fieldName = `talents.talent[${compNum}]`
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const { resume, setResume } = euContext
    const { reset } = useFormContext()

    const { mutate: addTalent, isPending: isAdding, error: addError } = useAddTalent()
    const { mutate: minusTalent, isPending: isRemoving, error: removeError } = useMinusTalent()

    const handleAddTalent = (evt: MouseEvent<HTMLElement>) => {
        evt.preventDefault()
        addTalent(resume, {
            onSuccess: (data) => {
                setResume(data.resume)
                reset(data.resume)
            }
        })
    }

    const handleMinusTalent = (evt: MouseEvent<HTMLElement>) => {
        evt.preventDefault()
        minusTalent(
            { resume, compNum },
            {
                onSuccess: (data) => {
                    setResume(data.resume)
                    reset(data.resume)
                }
            }
        )
    }

    if (isAdding || isRemoving) {
        return <LoadingState />
    }

    if (addError || removeError) {
        return <ErrorState message={'Error updating talents'} />
    }

    return (
        <PopupState
            variant='popover'
            popupId='skillsList'>
            {(popupState) => (
                <>
                    {!!euContext.user?.email && (
                        <HoverPopover
                            {...bindPopover(popupState)}
                            disableScrollLock={true}
                            sx={{ top: '-40px' }}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
                            <Stack
                                direction='row'
                                columnGap={1}
                                margin={0.5}>
                                <Button
                                    onClick={handleAddTalent}
                                    type='button'
                                    size='small'
                                    variant='outlined'
                                    sx={{ padding: 0, margin: 0, width: '20px' }}>
                                    +
                                </Button>
                                <Button
                                    onClick={handleMinusTalent}
                                    type='button'
                                    size='small'
                                    variant='outlined'
                                    sx={{ padding: 0, margin: 0, width: '20px' }}>
                                    -
                                </Button>
                            </Stack>
                        </HoverPopover>
                    )}
                    <Draggable
                        draggableId={resume.talents.talent[compNum].producer + index}
                        index={index}
                        isDragDisabled={!euContext.user?.email}>
                        {(provided) => (
                            <ListItem
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                sx={{ width: '100%' }}>
                                <ListItemIcon
                                    {...provided.dragHandleProps}
                                    sx={popupState.isOpen ? { ...dragIconStyleToUse(isDraggable), ...dragIconStyleHover } : dragIconStyleToUse(isDraggable)}>
                                    <DragHandleIcon />
                                </ListItemIcon>

                                <Box
                                    width='100%'
                                    rowGap={0}
                                    sx={popupState.isOpen ? { ...cardStyleToUse(isDraggable, style.SpaceCard), ...cardStyleHover } : cardStyleToUse(isDraggable, style.SpaceCard)}
                                    {...bindHover(popupState)}>
                                    <Stack
                                        direction='column'
                                        spacing={0}
                                        rowGap={0}
                                        sx={{ flexGrow: 1 }}>
                                        <FieldInputText
                                            name={`${fieldName}.production`}
                                            label=''
                                            variant='standard'
                                            placeholder={popupState.isOpen ? 'Enter Production' : ''}
                                            fontFamily={style[style.JobTitleFontName]}
                                            fontSize={`${style?.JobTitleFontSize ?? 5}px`}
                                            fontStyle={style?.JobTitleFontStyle ?? 'initial'}
                                            alignSelf={style?.JobTitleAlign ?? 'end'}
                                            fontWeight={style?.JobTitleFontWeight ?? 'initial'}
                                        />
                                        <Grid
                                            container
                                            spacing={1}
                                            direction='row'
                                            justifyContent='space-between'
                                            sx={{ flexGrow: 1 }}>
                                            <Grid
                                                item
                                                xs={6}>
                                                <FieldInputText
                                                    name={`${fieldName}.producer`}
                                                    label=''
                                                    variant='standard'
                                                    fullWidth
                                                    placeholder={popupState.isOpen ? 'Producer' : ''}
                                                    fontFamily={style[style.CompanyFontName]}
                                                    fontSize={`${style?.CompanyFontSize ?? 5}px`}
                                                    fontStyle={style?.CompanyFontStyle ?? 'initial'}
                                                    alignSelf={style?.CompanyAlign ?? 'end'}
                                                    fontWeight={style?.CompanyFontWeight ?? 'initial'}
                                                    sx={{ textTransform: style?.CompanyFontCase ?? 'initial' }}
                                                />
                                            </Grid>
                                        </Grid>
                                        {popupState.isOpen && (
                                            <FieldInputTextArea
                                                name={`${fieldName}.role`}
                                                label=''
                                                placeholder={popupState.isOpen ? 'Enter Job Description' : ''}
                                                fontSize={`${style?.BodyFontSize ?? 5}px`}
                                                fontStyle={style?.BodyFontStyle ?? 'initial'}
                                                alignSelf={style?.BodyAlign ?? 'end'}
                                                fontWeight={style?.BodyFontWeight ?? 'initial'}
                                            />
                                        )}
                                        {!popupState.isOpen && (
                                            <FieldInputTypography
                                                name={`${fieldName}.role`}
                                                sx={{ width: '100%', overflow: 'hidden', whiteSpace: 'pre-wrap' }}
                                                fontFamily={style[style.BodyFontName]}
                                                fontSize={`${style?.BodyFontSize ?? 5}px`}
                                                fontStyle={style?.BodyFontStyle ?? 'initial'}
                                                alignSelf={style?.BodyAlign ?? 'end'}
                                                fontWeight={style?.BodyFontWeight ?? 'initial'}
                                            />
                                        )}
                                    </Stack>
                                </Box>
                            </ListItem>
                        )}
                    </Draggable>
                </>
            )}
        </PopupState>
    )
}

export default TalentDraggableComponent

import { FormControl, FormLabel, TextField, Typography, type TextFieldProps } from '@mui/material'
import { EmploymentUniverseContext } from 'context/EmploymentUniverseContext'
import React, { ChangeEvent, FocusEvent, useContext, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { useUpdateResumeField } from '../../hooks/useResume'

// 👇 Type of Props the FormInput will receive
type FormInputProps = {
    type?: 'text' | 'password'
    name: string
    label: string
    handleChange?: (evt: ChangeEvent<HTMLInputElement>) => void
    closeDialog?: () => void
    fontFamily: string
    fontSize?: string
    fontStyle?: string
    fontWeight?: string
    alignSelf?: string
    sx?: object
} & TextFieldProps

const FieldInputText = ({
    name,
    type = 'text',
    label,
    handleChange,
    closeDialog,
    fontFamily,
    fontSize = '12px',
    fontStyle = 'inherit',
    fontWeight = 'inherit',
    alignSelf = 'inherit',
    sx = {},
    ...others
}: FormInputProps): JSX.Element => {
    // 👇 Utilizing useFormContext to have access to the form Context
    const { control } = useFormContext()
    const euContext = useContext(EmploymentUniverseContext)
    const { setValue } = useFormContext()
    const [editable, setEditable] = useState(false)
    const { mutate: updateResumeField } = useUpdateResumeField()

    const handleBlur = async (evt: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value: string | null = evt.target.value

        if (value && euContext.resume._id) {
            // Update resume field in database.
            updateResumeField(
                { id: euContext.resume._id, fieldName: name, fieldValue: value },
                {
                    onSuccess: (data) => {
                        euContext.setResume(data.resume)
                    }
                }
            )

            // Update form field in context.
            setValue(name, value)
            setEditable(false)

            closeDialog && closeDialog()
        }
    }

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
                const makeChanges = (evt: ChangeEvent<HTMLInputElement>) => {
                    onChange(evt)
                    handleChange && handleChange(evt)
                }
                return (
                    <FormControl
                        id={name}
                        size='small'
                        error={!!error}>
                        {label && <FormLabel>{label}</FormLabel>}
                        {editable ? (
                            <TextField
                                type={type}
                                onChange={makeChanges}
                                onBlur={handleBlur}
                                {...others}
                                value={value || ''}
                                variant='outlined'
                                size='small'
                                error={!!error}
                            />
                        ) : (
                            <Typography
                                width='100%'
                                variant='body1'
                                fontFamily={fontFamily}
                                fontSize={fontSize}
                                fontStyle={fontStyle}
                                fontWeight={fontWeight}
                                alignSelf={alignSelf}
                                onClick={() => setEditable(!!euContext.user?.email)}
                                sx={sx}>
                                {value?.length > 0 ? value : others.placeholder}
                            </Typography>
                        )}
                    </FormControl>
                )
            }}
        />
    )
}

export default FieldInputText

import React, { FocusEvent, useContext, useState } from 'react'

import { Stack, Typography, type TypographyProps } from '@mui/material'
import { EmploymentUniverseContext } from 'context/EmploymentUniverseContext'
import { EmploymentUniverseType } from 'types/EmploymentUniverseType'
import { useUpdateResumeField } from '../hooks/useResume'
import { IStyles } from '../models/Styles'

// 👇 Type of Props the FormInput will receive
type FormInputProps = {
    sx?: object
    canEdit?: boolean
} & TypographyProps

export const NameVerticleComponent = ({ sx = {}, canEdit = true, ...others }: FormInputProps): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const { mutate: updateResumeField } = useUpdateResumeField()
    const [editable, setEditable] = useState(canEdit)
    const fullName: string[] = euContext.resume.personalInfo.fullName.split(' ')
    const [firstName, setFirstName] = useState<string>(fullName[0])
    const [lastName, setLastName] = useState<string>(fullName.length > 0 ? fullName[1] : '')

    const handleFirstBlur = async (evt: FocusEvent<HTMLSpanElement>) => {
        const value: string | null = evt.currentTarget.textContent

        // Update resume field in database
        if (value && euContext.resume._id) {
            setFirstName(value.trim())
            console.log('handleFirstBlur', value, firstName, lastName)

            // Update resume field in database.
            updateResumeField(
                { id: euContext.resume._id, fieldName: 'personalInfo.fullName', fieldValue: value.trim() + ' ' + lastName },
                {
                    onSuccess: (data) => {
                        euContext.setResume(data.resume)
                    }
                }
            )

            // Update form field in context.
            setEditable(false)
        }
    }

    const handleLastBlur = async (evt: FocusEvent<HTMLSpanElement>) => {
        const value: string | null = evt.currentTarget.textContent

        if (value && euContext.resume._id) {
            setLastName(value.trim())
            console.log('handleLastBlur', value, firstName, lastName)

            // Update resume field in database.
            updateResumeField(
                { id: euContext.resume._id, fieldName: 'personalInfo.fullName', fieldValue: firstName + ' ' + value.trim() },
                {
                    onSuccess: (data) => {
                        euContext.setResume(data.resume)
                    }
                }
            )

            // Update form field in context.
            setEditable(false)
        }
    }

    const handleClick = () => {
        setEditable(!!euContext.user?.email && canEdit)
    }

    return (
        <Stack direction='column'>
            <Typography
                onBlur={handleFirstBlur}
                suppressContentEditableWarning={true}
                contentEditable={editable}
                onClick={handleClick}
                fontFamily={style[style.NameFontName]}
                fontSize={`${style?.NameFontSize ?? 5}px`}
                fontWeight={'initial'}
                textAlign={style?.NameAlign ?? 'end'}
                fontStyle={style?.NameFontStyle ?? 'initial'}
                alignSelf={style?.NameAlign ?? 'end'}
                textTransform={style?.NameFontCase ?? 'none'}
                sx={sx}
                {...others}>
                {firstName}
            </Typography>
            <Typography
                onBlur={handleLastBlur}
                suppressContentEditableWarning={true}
                contentEditable={editable}
                onClick={handleClick}
                fontFamily={style[style.NameFontName]}
                fontSize={`${style?.NameFontSize ?? 5}px`}
                fontWeight={'initial'}
                textAlign={style?.NameAlign ?? 'end'}
                fontStyle={style?.NameFontStyle ?? 'initial'}
                alignSelf={style?.NameAlign ?? 'end'}
                textTransform={style?.NameFontCase ?? 'none'}
                sx={sx}>
                {lastName}
            </Typography>
        </Stack>
    )
}

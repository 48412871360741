import { Box, Button, Divider, Modal, Typography } from '@mui/material'
import React, { ReactNode } from 'react'
import Draggable from 'react-draggable'

interface EditModalComponentProps {
    open: boolean
    onClose: () => void
    title?: string
    component: ReactNode
    width?: number
    modalPosition?: {
        top: number
        left: number
    }
}

const EditModal: React.FC<EditModalComponentProps> = ({ open, onClose, title, component, width = 400, modalPosition }) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby='modal-title'
            aria-describedby='modal-description'>
            <Box sx={{ position: 'relative', width: '100%', height: '100vh' }}>
                <Draggable
                    handle='.draggable-handle'
                    cancel='.non-draggable'>
                    <Box
                        className='draggable-handle'
                        sx={{
                            position: 'absolute',
                            top: modalPosition?.top || '40%',
                            left: modalPosition?.left || '40%',
                            transform: 'translate(-50%, -50%)',
                            width,
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                            borderRadius: '8px',
                            cursor: 'grab'
                        }}>
                        {title && (
                            <>
                                <Typography
                                    id='modal-title'
                                    variant='h4'
                                    component='h2'>
                                    {title}
                                </Typography>
                                <Divider sx={{ paddingTop: 1 }} />
                            </>
                        )}

                        {component && (
                            <Box
                                id='modal-description'
                                sx={{ mt: 2 }}>
                                {component}
                            </Box>
                        )}
                        <Divider />
                        <Button
                            type='button'
                            size='small'
                            variant='outlined'
                            onClick={onClose}
                            sx={{ mt: 2 }}
                            className='non-draggable'>
                            Close
                        </Button>
                    </Box>
                </Draggable>
            </Box>
        </Modal>
    )
}

export default EditModal

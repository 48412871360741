import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography, type SelectChangeEvent, type SelectProps } from '@mui/material'
import { EmploymentUniverseContext } from 'context/EmploymentUniverseContext'
import { default as React, useContext, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { useUpdateResumeField } from '../../hooks/useResume'
import { IStyles } from '../../models/Styles'

type Props = {
    name: string
    label: string
    options: Array<{ value: string; label: string }>
    disabled?: boolean
    fontSize?: string
    fontStyle?: string
    fontWeight?: string
    alignSelf?: string
} & SelectProps

const FieldInputSelect = ({ name, label, options, disabled, fontSize = '12px', fontStyle = 'inherit', fontWeight = 'inherit', alignSelf = 'inherit', ...others }: Props): JSX.Element => {
    const { control } = useFormContext()
    const euContext = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const { setValue } = useFormContext()
    const [editable, setEditable] = useState(false)
    const { mutate: updateResumeField } = useUpdateResumeField()

    const handleChange = async (event: SelectChangeEvent<unknown>) => {
        const newValue = event.target.value
        if (newValue && euContext.resume._id) {
            // Update resume field in database.
            updateResumeField(
                { id: euContext.resume._id, fieldName: name, fieldValue: newValue },
                {
                    onSuccess: (data) => {
                        euContext.setResume(data.resume)
                    }
                }
            )

            // Update form field in context.
            setValue(name, newValue)
            setEditable(false)
        }
    }

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormControl error={!!error}>
                    <InputLabel>{label}</InputLabel>
                    {editable ? (
                        <Select
                            value={value || ''}
                            onChange={(event) => {
                                onChange(event)
                                handleChange(event)
                            }}
                            {...others}
                            disabled={disabled}>
                            {options.map((option) => (
                                <MenuItem
                                    key={option.value}
                                    value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    ) : (
                        <Typography
                            fontFamily={style[style.BodyFontName]}
                            fontSize={fontSize}
                            fontStyle={fontStyle}
                            fontWeight={fontWeight}
                            onClick={() => setEditable(!!euContext.user?.email)}
                            alignSelf={alignSelf}>
                            {value ? options.find((option) => option.value === value)?.label : others.placeholder}
                        </Typography>
                    )}
                    <FormHelperText>{error?.message}</FormHelperText>
                </FormControl>
            )}
        />
    )
}

export default FieldInputSelect

import { Alert, Box, Button, Grid, Snackbar, TextField, Typography } from '@mui/material'
import { ColorPicker } from 'material-ui-color'
import React, { useContext, useState } from 'react'
import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { useUpdateClientColor } from '../hooks/useClients'
import { HTMLColorNameValues, IHTMLColorName, IHTMLColorNameItem } from '../models/HTMLColors'
import { camelToTitle } from '../utils/Util'

const initialUICustomization: IHTMLColorName = {
    header: {
        backgroundColor: HTMLColorNameValues.euBlue,
        textColor: HTMLColorNameValues.white,
        borderBackgroundColor: HTMLColorNameValues.black,
        borderColor: HTMLColorNameValues.black,
        hoverBackgroundColor: HTMLColorNameValues.darkGrey,
        hoverTextColor: HTMLColorNameValues.lightGrey,
        buttonBackgroundColor: HTMLColorNameValues.teal,
        buttonTextColor: HTMLColorNameValues.black,
        inputBackgroundColor: HTMLColorNameValues.white,
        inputTextColor: HTMLColorNameValues.black,
        linkBackgroundColor: HTMLColorNameValues.blueGrey,
        linkTextColor: HTMLColorNameValues.white,
        buttonTextSecondary: HTMLColorNameValues.euBlue
    },
    navigationBar: {
        backgroundColor: HTMLColorNameValues.euBlue,
        textColor: HTMLColorNameValues.white,
        borderBackgroundColor: HTMLColorNameValues.grey,
        borderColor: HTMLColorNameValues.grey,
        hoverBackgroundColor: HTMLColorNameValues.lightGrey,
        hoverTextColor: HTMLColorNameValues.darkGrey,
        buttonBackgroundColor: HTMLColorNameValues.blue,
        buttonTextColor: HTMLColorNameValues.white,
        inputBackgroundColor: HTMLColorNameValues.white,
        inputTextColor: HTMLColorNameValues.black,
        linkBackgroundColor: HTMLColorNameValues.blue,
        linkTextColor: HTMLColorNameValues.white,
        buttonTextSecondary: HTMLColorNameValues.euBlue
    },
    footer: {
        backgroundColor: HTMLColorNameValues.grey,
        textColor: HTMLColorNameValues.white,
        borderBackgroundColor: HTMLColorNameValues.black,
        borderColor: HTMLColorNameValues.black,
        hoverBackgroundColor: HTMLColorNameValues.darkGrey,
        hoverTextColor: HTMLColorNameValues.lightGrey,
        buttonBackgroundColor: HTMLColorNameValues.blue,
        buttonTextColor: HTMLColorNameValues.black,
        inputBackgroundColor: HTMLColorNameValues.white,
        inputTextColor: HTMLColorNameValues.black,
        linkBackgroundColor: HTMLColorNameValues.blueGrey,
        linkTextColor: HTMLColorNameValues.white,
        buttonTextSecondary: HTMLColorNameValues.euBlue
    },
    login: {
        backgroundColor: HTMLColorNameValues.white,
        textColor: HTMLColorNameValues.euBlue,
        borderBackgroundColor: HTMLColorNameValues.grey,
        borderColor: HTMLColorNameValues.grey,
        hoverBackgroundColor: HTMLColorNameValues.lightGrey,
        hoverTextColor: HTMLColorNameValues.darkGrey,
        buttonBackgroundColor: HTMLColorNameValues.euBlue,
        buttonTextColor: HTMLColorNameValues.white,
        inputBackgroundColor: HTMLColorNameValues.white,
        inputTextColor: HTMLColorNameValues.black,
        linkBackgroundColor: HTMLColorNameValues.blue,
        linkTextColor: HTMLColorNameValues.white,
        buttonTextSecondary: HTMLColorNameValues.euBlue
    },
    card: {
        backgroundColor: HTMLColorNameValues.white,
        textColor: HTMLColorNameValues.black,
        borderBackgroundColor: HTMLColorNameValues.grey,
        borderColor: HTMLColorNameValues.grey,
        hoverBackgroundColor: HTMLColorNameValues.lightGrey,
        hoverTextColor: HTMLColorNameValues.darkGrey,
        buttonBackgroundColor: HTMLColorNameValues.blue,
        buttonTextColor: HTMLColorNameValues.white,
        inputBackgroundColor: HTMLColorNameValues.white,
        inputTextColor: HTMLColorNameValues.black,
        linkBackgroundColor: HTMLColorNameValues.blueGrey,
        linkTextColor: HTMLColorNameValues.white,
        buttonTextSecondary: HTMLColorNameValues.euBlue
    }
}

const SiteCustomization: React.FC = () => {
    const [alertOpen, setAlertOpen] = useState(false)
    const [customization, setCustomization] = useState<IHTMLColorName>(initialUICustomization)
    const euContext = useContext(EmploymentUniverseContext)
    const updateClientColorMutation = useUpdateClientColor()

    const handleCustomizationChange = (category: keyof IHTMLColorName, key: keyof IHTMLColorNameItem, value: string) => {
        setCustomization({
            ...customization,
            [category]: {
                ...customization[category],
                [key]: value as HTMLColorNameValues
            }
        })
    }

    const handleSaveCustomizations = () => {
        euContext.setHtmlColors(customization)
        updateClientColorMutation.mutate(
            { subdomain: euContext.domain, htmlColors: customization },
            {
                onSuccess: () => {
                    setAlertOpen(true)
                },
                onError: (error) => {
                    console.error('Error saving customizations:', error)
                }
            }
        )
    }

    const handleResetCustomizations = () => {
        const defaultCustomization = {
            header: {
                backgroundColor: HTMLColorNameValues.euBlue,
                textColor: HTMLColorNameValues.white,
                borderBackgroundColor: HTMLColorNameValues.black,
                borderColor: HTMLColorNameValues.black,
                hoverBackgroundColor: HTMLColorNameValues.darkGrey,
                hoverTextColor: HTMLColorNameValues.lightGrey,
                buttonBackgroundColor: HTMLColorNameValues.teal,
                buttonTextColor: HTMLColorNameValues.black,
                inputBackgroundColor: HTMLColorNameValues.white,
                inputTextColor: HTMLColorNameValues.black,
                linkBackgroundColor: HTMLColorNameValues.blueGrey,
                linkTextColor: HTMLColorNameValues.white,
                buttonTextSecondary: HTMLColorNameValues.euBlue
            }
        } as IHTMLColorName
        setCustomization(defaultCustomization)
        euContext.setHtmlColors(defaultCustomization)

        updateClientColorMutation.mutate(
            { subdomain: euContext.domain, htmlColors: defaultCustomization },
            {
                onSuccess: () => {
                    setAlertOpen(true)
                },
                onError: (error) => {
                    console.error('Error resetting customizations:', error)
                }
            }
        )
    }

    const renderCustomizationForm = () => {
        return Object.entries(customization).map(([category, settings]) => (
            <Box
                key={category}
                mb={3}>
                <Typography
                    variant='h6'
                    gutterBottom>
                    {camelToTitle(category)}
                </Typography>
                <Grid
                    container
                    spacing={2}>
                    {Object.entries(settings).map(([key, value]) => (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={3}
                            key={key}>
                            <Box
                                display='flex'
                                alignItems='center'>
                                <ColorPicker
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    value={value}
                                    onChange={(color) => handleCustomizationChange(category as keyof IHTMLColorName, key as keyof IHTMLColorNameItem, `#${color.hex}`)}
                                    disableAlpha
                                    hideTextfield
                                    sx={{
                                        width: 24,
                                        height: 24,
                                        borderRadius: '50%',
                                        backgroundColor: value as string,
                                        marginRight: 1,
                                        border: '1px solid #ccc'
                                    }}
                                />
                                <TextField
                                    label={camelToTitle(key)}
                                    value={value}
                                    onChange={(e) => handleCustomizationChange(category as keyof IHTMLColorName, key as keyof IHTMLColorNameItem, e.target.value)}
                                    variant='outlined'
                                    fullWidth
                                />
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        ))
    }

    return (
        <Box>
            <Typography
                variant='h4'
                gutterBottom>
                Site Customization
            </Typography>
            {renderCustomizationForm()}
            <Button
                variant='contained'
                sx={{
                    backgroundColor: customization.card.buttonBackgroundColor,
                    color: customization.card.buttonTextColor,
                    borderColor: customization.card.borderBackgroundColor,
                    '&:hover': {
                        backgroundColor: customization.card.hoverBackgroundColor,
                        color: customization.card.hoverTextColor
                    }
                }}
                onClick={handleSaveCustomizations}>
                Save Customizations
            </Button>
            <Button
                variant='outlined'
                sx={{
                    backgroundColor: customization.card.buttonBackgroundColor,
                    color: customization.card.buttonTextColor,
                    borderColor: customization.card.borderBackgroundColor,
                    ml: 2,
                    '&:hover': {
                        backgroundColor: customization.card.hoverBackgroundColor,
                        color: customization.card.hoverTextColor
                    }
                }}
                onClick={handleResetCustomizations}>
                Reset to Defaults
            </Button>
            <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={() => setAlertOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert
                    onClose={() => setAlertOpen(false)}
                    severity='success'
                    sx={{ width: '100%' }}>
                    Customizations saved successfully!
                </Alert>
            </Snackbar>
        </Box>
    )
}

export default SiteCustomization

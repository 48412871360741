import React, { useContext, useRef, useState } from 'react'

import { Stack } from '@mui/material'
import { EmploymentUniverseContext } from 'context/EmploymentUniverseContext'
import { EmploymentUniverseType } from 'types/EmploymentUniverseType'
import useGetElementPosition from '../hooks/useGetElementPosition'
import { IStyles } from '../models/Styles'
import EditModal from './EditModal'
import FieldInputTypography from './forms/FieldInputTypography'

const NameContent = ({ style, sx, mt, isHighlighted, onClick }: { style: IStyles; sx?: object; mt?: number; isHighlighted?: boolean; onClick?: () => void }) => (
    <Stack
        spacing={0}
        direction='column'
        marginBottom={`${style?.SpaceCard ?? 10}px`}
        onClick={onClick}
        sx={{
            cursor: onClick ? 'pointer' : 'default',
            border: isHighlighted ? '2px solid #1976d2' : 'none',
            boxShadow: isHighlighted ? '0px 0px 10px rgba(25, 118, 210, 0.5)' : 'none',
            borderRadius: isHighlighted ? '8px' : 'none',
            padding: isHighlighted ? '10px' : '0'
        }}>
        <FieldInputTypography
            name='personalInfo.fullName'
            margin='none'
            fontFamily={style[style.NameFontName]}
            fontSize={`${style?.NameFontSize ?? 5}px`}
            fontWeight={style?.NameFontWeight ?? 'initial'}
            fontStyle={style?.NameFontStyle ?? 'initial'}
            textAlign={style?.NameAlign ?? 'end'}
            textTransform={style?.NameFontCase ?? 'none'}
            marginBottom={`${style?.SpaceSectionTitle}px`}
            marginTop={`${mt}px`}
            {...sx}
        />
    </Stack>
)

export const NameComponent = ({ sx, mt = 0 }: { sx?: object; mt?: number }): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const nameRef = useRef<HTMLDivElement | null>(null)
    const [isModalOpen, setModalOpen] = useState(false)

    const modalPosition = useGetElementPosition(nameRef, isModalOpen, -520, 0)

    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]

    const handleOpenModal = () => {
        setModalOpen(true)
    }

    const handleCloseModal = () => {
        setModalOpen(false)
    }

    return (
        <>
            <div ref={nameRef}>
                <NameContent
                    style={style}
                    onClick={handleOpenModal}
                    isHighlighted={isModalOpen}
                    mt={mt}
                    sx={sx}
                />
            </div>
            <EditModal
                open={isModalOpen}
                onClose={handleCloseModal}
                modalPosition={modalPosition}
                title='Edit Name'
                width={500}
                component={
                    <NameContent
                        style={style}
                        key='name-modal'
                        mt={mt}
                        sx={sx}
                    />
                }
            />
        </>
    )
}

import Stack from '@mui/material/Stack'
import React, { useContext } from 'react'

import { Grid } from '@mui/material'
import { Box } from '@mui/system'
import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { Degrees } from '../models/Education'
import { IStyles } from '../models/Styles'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'
import FieldInputMonth from './forms/FieldInputMonth'
import FieldInputSelect from './forms/FieldInputSelect'
import FieldInputText from './forms/FieldInputText'
import FieldInputTypography from './forms/FieldInputTypography'

export const EducationSummaryComponent = ({ sectionBgColor = '' }: { sectionBgColor?: string }): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const eduIndex: number = euContext.resume.educations.education.findIndex((edu) => edu.seqNum === euContext.resume.educations.education.length - 1)
    const fieldName = `educations.education[${eduIndex}]`

    return (
        <Stack>
            <FieldInputTypography
                name='educations.label'
                fontFamily={style[style.SectionFontName]}
                fontSize={`${style?.SectionFontSize ?? 5}px`}
                fontStyle={style?.SectionFontStyle ?? 'initial'}
                alignSelf={style?.SectionAlign ?? 'initial'}
                textAlign={style?.SectionAlign ?? 'initial'}
                fontWeight={style?.SectionFontWeight ?? 'initial'}
                marginBottom={`${style?.SpaceSectionTitle}px`}
                bgcolor={sectionBgColor}
                width='100%'
                sx={{ textTransform: style?.SectionFontCase ?? 'none' }}
            />
            <Box width='100%'>
                <Grid
                    container
                    spacing={0}
                    columns={12}
                    gap={2}>
                    <Grid
                        display='flex'
                        justifyContent='left'
                        alignItems='left'
                        xs={5}>
                        <FieldInputSelect
                            name={`${fieldName}.degree`}
                            label=''
                            placeholder='Enter Degree'
                            options={Degrees}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid
                        display='flex'
                        justifyContent='right'
                        alignItems='right'
                        textAlign='right'
                        xs={5}>
                        <FieldInputText
                            name={`${fieldName}.subject`}
                            fontFamily={style[style.BodyFontName]}
                            label=''
                            placeholder='Enter Major'
                            fullWidth
                        />
                    </Grid>
                    <Grid
                        display='flex'
                        justifyContent='left'
                        alignItems='left'
                        xs={5}>
                        <FieldInputText
                            name={`${fieldName}.school`}
                            label=''
                            fontFamily={style[style.BodyFontName]}
                            sx={{ alignSelf: 'left' }}
                            placeholder='School'
                            fullWidth
                        />
                    </Grid>
                    <Grid
                        display='flex'
                        justifyContent='right'
                        alignItems='left'
                        xs={5}>
                        <FieldInputMonth
                            name={`${fieldName}.graduation`}
                            label=''
                        />
                    </Grid>
                </Grid>
            </Box>
        </Stack>
    )
}

import { Box, Popper, Typography } from '@mui/material'
import { SimpleDialogProps } from 'components/interface/Interfaces'
import React, { useContext } from 'react'
import { EmploymentUniverseContext } from '../../context/EmploymentUniverseContext'
import { EmploymentUniverseType } from '../../types/EmploymentUniverseType'

const LocationDialog = (props: SimpleDialogProps) => {
    const { anchorEl, open, locationOptions, selectLocation } = props
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style = euContext.resume.style[euContext.resume.currentStyle]
    return (
        <Popper
            anchorEl={anchorEl}
            open={open}
            placement='top-end'>
            <Box sx={{ border: 1, p: 1, bgcolor: 'white' }}>
                {locationOptions.length > 0 &&
                    locationOptions.map((location, index) => {
                        return (
                            <Typography
                                key={index}
                                variant='body1'
                                gutterBottom
                                fontFamily={style[style.BodyFontName]}
                                onClick={() => selectLocation(location.PlaceId || '')}
                                sx={{ cursor: 'pointer' }}>
                                {location.Text}
                            </Typography>
                        )
                    })}
            </Box>
        </Popper>
    )
}

export default LocationDialog

import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Dialog, FormControl, FormLabel, IconButton, MenuItem, Modal, Popover, Select, Stack, Switch, TextField, Typography, type SelectChangeEvent } from '@mui/material'
import React, { ChangeEvent, MouseEvent, ReactNode, useContext, useEffect, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import axios from '../config/axiosConfig'
import { IResume } from '../models/Resume'
import { importResume } from '../utils/DBUtils'

import DeleteIcon from '@mui/icons-material/Delete'
import DisplaySettingsSharpIcon from '@mui/icons-material/DisplaySettingsSharp'
import { useNavigate } from 'react-router'
import { getView } from 'views/viewUtils'
import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { useResume, useResumeInfo, useUpdateResumeField } from '../hooks/useResume'
import { IStyles } from '../models/Styles'
import { IResumeInfo } from '../models/User'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'
import { MAX_RESUMES, resumeLink, resumesLink, ResumeStylesType, validResumeStyles } from '../utils/Constants'
import ErrorState from '../views/ErrorState'
import LoadingState from '../views/LoadingState'
import EditStyleDialog from './dialog/EditStyleDialog'
import OrderResumeDialog from './dialog/OrderResumeDialog'
import { getDashBoardDefaultValues } from './forms/defaultValues/FormDefaultValues'
import { DashboardSchema } from './forms/schemas/FormSchemas'

export const DashboardPage = (): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const { mutate: updateResumeField, isPending, isError } = useUpdateResumeField()
    const styles: ResumeStylesType = euContext.resume.style
    const style: IStyles | undefined = styles ? styles[euContext.resume.currentStyle || 'Default'] : undefined
    const [openPopover, setOpenPopover] = useState(false)
    const [openViewResume, setOpenViewResume] = useState(false)
    const [openImportResume, setOpenImportResume] = useState(false)
    const [importErrorMessage, setImportErrorMessage] = useState('')
    const [openOrderResume, setOpenOrderResume] = useState(false)
    const [viewResume, setViewResume] = useState<ReactNode>(getView(euContext.resume))
    const [currentFile, setCurrentFile] = useState<File>()
    const [openEditStyle, setOpenEditStyle] = useState<boolean>(false)
    const [selectedResumeId, setSelectedResumeId] = useState<string>('')
    const { data: resumeResponse } = useResume(selectedResumeId)
    const { data: resInfosResponse } = useResumeInfo(euContext.user._id)

    const navigate = useNavigate()

    const formContext = useForm({
        resolver: yupResolver(DashboardSchema),
        defaultValues: getDashBoardDefaultValues(euContext.resume),
        mode: 'onBlur',
        resetOptions: {
            keepDefaultValues: false
        }
    })

    useEffect(() => {
        formContext.reset(euContext.resume)
        setViewResume(getView(euContext.resume))
    }, [euContext.resume])

    useEffect(() => {
        if (resumeResponse?.resume) {
            euContext.setResume(resumeResponse.resume)
            setOpenPopover(false)
            navigate('/dashboard')
        }
    }, [resumeResponse])

    const deleteResume = async (evt: React.MouseEvent<HTMLElement>) => {
        evt.preventDefault()

        const response = await axios
            .delete(resumeLink(euContext.resume._id), {
                data: { user: euContext.user }
            })
            .catch((err) => {
                console.error(JSON.stringify(err.response))
                return err.response
            })

        if (response.data.resume) {
            formContext.reset(response.data.resume)
            euContext.setResume(response.data.resume)
            setOpenPopover(false)
        }
    }

    const addResume = async (evt: React.MouseEvent<HTMLElement>) => {
        evt.preventDefault()

        const response = await axios
            .post(resumesLink, {
                user: euContext.user
            })
            .catch((err) => {
                console.error(JSON.stringify(err.response))
                return err.response
            })

        if (response.data.resume) {
            formContext.reset(response.data.resume)
            euContext.setResume(response.data.resume)
            setOpenPopover(false)
            navigate('/dashboard')
        }
    }

    const changeResume = (event: SelectChangeEvent<string>) => {
        event.preventDefault()

        const newValue = event.target.value

        if (!newValue) return
        setSelectedResumeId(newValue)
    }

    const changeStyle = async (event: SelectChangeEvent<string>) => {
        event.preventDefault()
        const newValue = event.target.value
        if (!newValue) return

        const resume: IResume = euContext.resume
        const currentStyle: validResumeStyles = newValue as validResumeStyles

        updateResumeField(
            { id: resume._id, fieldName: 'currentStyle', fieldValue: currentStyle },
            {
                onSuccess: (data) => {
                    euContext.setResume(data.resume)
                    setViewResume(getView(data.resume))
                }
            }
        )
    }

    const changeIsPublic = async (evt: ChangeEvent<HTMLInputElement>) => {
        evt.preventDefault()
        const resume: IResume = euContext.resume
        updateResumeField(
            { id: resume._id, fieldName: 'isPublic', fieldValue: evt.target.checked },
            {
                onSuccess: (data) => {
                    euContext.setResume(data.resume)
                }
            }
        )
    }

    const selectFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        const { files } = event.target
        const selectedFiles = files as FileList
        setCurrentFile(selectedFiles?.[0])
    }

    const handleFileUpload = async (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        if (currentFile) {
            const file: File = currentFile
            const revisionDate = new Date(file.lastModified).toISOString().substring(0, 10)
            const importedResumePromise = importResume(euContext.user, file, revisionDate)

            importedResumePromise
                .then((importedResume) => {
                    if (importedResume) {
                        euContext.setResume(importedResume)
                        setOpenImportResume(false)
                        setOpenPopover(false)
                    }
                })
                .catch((err) => {
                    setImportErrorMessage(err.response.data.message || err.response.statusText)
                    console.error(JSON.stringify(err))
                })
        }
    }

    const anchorRef = useRef(null)

    if (isPending) {
        return <LoadingState message={'Updating...'} />
    }

    if (isError) {
        return <ErrorState message={'Error updating resume field'} />
    }

    return (
        <FormProvider {...formContext}>
            <form>
                <Popover
                    id='dashboardHeader'
                    open={openPopover}
                    onClose={() => setOpenPopover(false)}
                    anchorEl={anchorRef.current}
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
                    <Stack
                        direction='row'
                        spacing={2}
                        padding={1}>
                        {resInfosResponse?.resumeInfos && resInfosResponse.resumeInfos.length > 1 && (
                            <Select
                                value={euContext.resume._id?.toString()}
                                onChange={(e) => changeResume(e)}
                                size='small'
                                sx={{ width: '200px' }}>
                                {resInfosResponse.resumeInfos.map((res: IResumeInfo, index: number) => {
                                    return (
                                        <MenuItem
                                            key={index}
                                            value={res.resume_id.toString()}>
                                            {res.resumeName}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        )}
                        <IconButton
                            aria-label='delete'
                            onClick={deleteResume}>
                            <DeleteIcon />
                        </IconButton>
                        {resInfosResponse?.resumeInfos && resInfosResponse.resumeInfos.length < MAX_RESUMES && (
                            <>
                                <Button
                                    type='button'
                                    onClick={addResume}
                                    variant='outlined'
                                    sx={{ alignSelf: 'center' }}>
                                    Add
                                </Button>
                                <Button
                                    type='button'
                                    onClick={() => {
                                        setImportErrorMessage('')
                                        setOpenImportResume(true)
                                    }}
                                    variant='outlined'
                                    sx={{ alignSelf: 'center' }}>
                                    Import
                                </Button>
                            </>
                        )}

                        <Button
                            onClick={() => setOpenViewResume(true)}
                            variant='outlined'
                            sx={{ alignSelf: 'center' }}>
                            View
                        </Button>
                        <Button
                            onClick={() => setOpenOrderResume(true)}
                            variant='outlined'
                            sx={{ alignSelf: 'center' }}>
                            Order
                        </Button>
                        <Modal
                            open={openImportResume}
                            onClose={() => {
                                setOpenImportResume(false)
                                setOpenPopover(false)
                            }}>
                            <Box sx={{ backgroundColor: 'white', color: 'black', padding: 2 }}>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={handleFileUpload}>
                                    Upload
                                </Button>
                                <TextField
                                    type='file'
                                    inputProps={{ accept: 'application/pdf' }}
                                    onChange={selectFileUpload}
                                    sx={{ display: 'none' }}
                                    id='file-upload'
                                />
                                <label htmlFor='file-upload'>
                                    <Button
                                        variant='outlined'
                                        component='span'>
                                        Choose File
                                    </Button>
                                </label>
                                <Typography>{importErrorMessage}</Typography>
                            </Box>
                        </Modal>
                        <Dialog
                            open={openViewResume}
                            contentEditable={false}
                            fullWidth={true}
                            maxWidth='md'
                            onClose={() => {
                                setOpenViewResume(false)
                                setOpenPopover(false)
                            }}>
                            <Box sx={{ backgroundColor: 'white', color: 'black', padding: 2 }}>{getView(euContext.resume)}</Box>
                        </Dialog>
                        <OrderResumeDialog
                            openOrder={openOrderResume}
                            onCloseOrder={() => {
                                setOpenOrderResume(false)
                                setOpenPopover(false)
                            }}
                        />
                        {euContext.resume.style && (
                            <>
                                <Button
                                    onClick={() => setOpenEditStyle(true)}
                                    variant='outlined'
                                    sx={{ alignSelf: 'center' }}>
                                    Edit Style
                                </Button>
                                <Select
                                    value={euContext.resume.currentStyle}
                                    onChange={(e) => changeStyle(e)}
                                    size='small'
                                    sx={{ width: '150px' }}>
                                    {Object.keys(validResumeStyles).map((val, index) => {
                                        return (
                                            <MenuItem
                                                key={index}
                                                value={val}>
                                                {val}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </>
                        )}
                        <EditStyleDialog
                            openEditStyle={openEditStyle}
                            onCloseEditStyle={() => {
                                setOpenEditStyle(false)
                                setOpenPopover(false)
                            }}
                        />
                        <FormControl>
                            <FormLabel>Public?</FormLabel>
                            <Switch
                                checked={euContext.resume.isPublic}
                                onChange={changeIsPublic}
                            />
                        </FormControl>
                    </Stack>
                </Popover>
                <Stack width='815px'>
                    <IconButton
                        onClick={() => setOpenPopover(true)}
                        ref={anchorRef}
                        sx={{ left: '50%', top: '-70px', color: 'white' }}>
                        <DisplaySettingsSharpIcon />
                    </IconButton>
                    <Box
                        fontSize={`${style?.BodyFontSize ?? 5}px`}
                        fontStyle={style?.BodyFontStyle ?? 'initial'}
                        alignSelf={style?.BodyAlign ?? 'end'}
                        fontWeight={style?.BodyFontWeight ?? 'initial'}
                        fontFamily={style ? style[style?.BodyFontName] : 'cursive'}>
                        {viewResume}
                    </Box>
                </Stack>
            </form>
        </FormProvider>
    )
}

import React, { ChangeEvent, FocusEvent, MouseEvent, useContext, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { FormControl, FormHelperText, FormLabel, TextareaAutosize, Typography } from '@mui/material'
import { EmploymentUniverseContext } from 'context/EmploymentUniverseContext'
import { useUpdateResumeField } from '../../hooks/useResume'
import { IStyles } from '../../models/Styles'

// 👇 Type of Props the FormInput will receive
type FormInputProps = {
    name: string
    label: string
    handleChange?: (evt: ChangeEvent<HTMLTextAreaElement>) => void
    closeDialog?: () => void
    fontSize?: string
    fontStyle?: string
    fontWeight?: string
    alignSelf?: string
    placeholder: string
    sx?: object
}

const FieldInputTextArea = ({
    name,
    label,
    handleChange,
    closeDialog,
    fontSize = '12px',
    fontStyle = 'inherit',
    fontWeight = 'inherit',
    alignSelf = 'inherit',
    placeholder,
    sx
}: FormInputProps): JSX.Element => {
    // 👇 Utilizing useFormContext to have access to the form Context
    const { control } = useFormContext()
    const euContext = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const { setValue } = useFormContext()
    const [editable, setEditable] = useState(false)
    const { mutate: updateResumeField } = useUpdateResumeField()

    const handleClick = (evt: MouseEvent<HTMLPreElement>) => {
        evt.preventDefault()
        setEditable(!!euContext.user?.email)

        const element: HTMLPreElement = evt.currentTarget
        element.focus()
    }

    const handleBlur = async (evt: FocusEvent<HTMLTextAreaElement>) => {
        const value: string | null = evt.target.value

        if (value && euContext.resume._id) {
            // Update resume field in database.
            updateResumeField(
                { id: euContext.resume._id, fieldName: name, fieldValue: value },
                {
                    onSuccess: (data) => {
                        euContext.setResume(data.resume)
                    }
                }
            )

            // Update form field in context.
            setValue(name, value)
            setEditable(false)

            closeDialog && closeDialog()
        }
    }

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
                const makeChanges = (evt: ChangeEvent<HTMLTextAreaElement>) => {
                    onChange(evt)
                    handleChange && handleChange(evt)
                }

                return (
                    <FormControl
                        id='FieldInputTextArea'
                        size='small'
                        error={!!error}>
                        <FormLabel>{label}</FormLabel>
                        {editable ? (
                            <TextareaAutosize
                                minRows={2}
                                maxRows={10}
                                name={name}
                                id={name}
                                onChange={makeChanges}
                                onBlur={handleBlur}
                                placeholder={placeholder}
                                value={value || ''}
                                style={{
                                    width: '100%',
                                    fontFamily: style[style.BodyFontName],
                                    fontSize,
                                    fontStyle,
                                    fontWeight,
                                    alignSelf,
                                    ...sx
                                }}
                            />
                        ) : (
                            <Typography
                                id={name}
                                component='pre'
                                fontFamily={style[style.BodyFontName]}
                                fontSize={fontSize}
                                fontStyle={fontStyle}
                                fontWeight={fontWeight}
                                alignSelf={alignSelf}
                                width='100%'
                                onClick={handleClick}
                                sx={{ ...sx, overflow: 'hidden', whiteSpace: 'pre-wrap' }}>
                                {value?.length > 0 ? value : placeholder}
                            </Typography>
                        )}
                        <FormHelperText>{`${error?.message ?? ''}`}</FormHelperText>
                    </FormControl>
                )
            }}
        />
    )
}

export default FieldInputTextArea

import React, { useContext } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { FormControl, FormHelperText, FormLabel, Typography } from '@mui/material'
import { EmploymentUniverseContext } from '../../context/EmploymentUniverseContext'
import { IStyles } from '../../models/Styles'

// 👇 Type of Props the FormInput will receive
type FormInputProps = {
    name: string
    label: string
}

const FieldInputRichTextEditor = ({ name, label }: FormInputProps): JSX.Element => {
    // 👇 Utilizing useFormContext to have access to the form Context
    const { control } = useFormContext()

    const euContext = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    /*
    const { setValue } = useFormContext()

    const handleBlur = (value: string): void => {
        if (value !== null && euContext.resume._id) {
            // Update resume field in database.
            const updateResume = updateFieldResume(euContext.resume._id, name, value)

            // Update resume field in context.
            if (updateResume) {
                updateResume.then((newResume) => {
                    if (newResume) euContext.setResume(newResume)
                })
            }

            // Update form field in context.
            setValue(name, value)

            closeDialog && closeDialog()
        }
    }

    const editorConfiguration = {
        toolbar: ['bold', 'italic'],
        mention: {
            feeds: [
                {
                    marker: '@',
                    feed: [
                        {
                            id: '@Barney',
                            fullName: 'Barney Stinson',
                            // Custom text to be inserted into the editor
                            text: 'Barney Stinson'
                        },
                        {
                            id: '@Lily',
                            text: 'Lily'
                        },
                        { id: '@Mary Ann', text: 'Mary Ann' },
                        { id: '@Marshall', text: 'Marshall' },
                        { id: '@Robin', text: 'Robin' },
                        { id: '@Ted', text: 'Ted' }
                    ],
                    minimumCharacters: 1
                }
            ]
        }
    }

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { value }, fieldState: { error } }) => {
                return (
                    <FormControl
                        id={name}
                        size='sm'>
                        {label && <FormLabel>{label}</FormLabel>}
                        <Box width='100%'>
                            <CKEditor
                                editor={InlineEditor}
                                config={editorConfiguration}
                                disabled={!editable}
                                data={value}
                                id={name}
                                onBlur={(event: EventInfo<string, unknown>, editor: InlineEditor) => handleBlur(editor.getData())}
                            />
                        </Box>
                        <FormHelperText>{`${error?.message ?? ''}`}</FormHelperText>
                    </FormControl>
                )
            }}
        />
    )
    */

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { value }, fieldState: { error } }) => (
                <FormControl
                    id={name}
                    size='small'
                    error={!!error}>
                    <>
                        {label && <FormLabel>{label}</FormLabel>}
                        <Typography
                            width='100%'
                            fontFamily={style[style.BodyFontName]}
                            fontSize={`${style?.BodyFontSize ?? 5}px`}
                            fontStyle={style?.BodyFontStyle ?? 'initial'}
                            alignSelf={style?.BodyAlign ?? 'initial'}
                            fontWeight={style?.BodyFontWeight ?? 'initial'}>
                            {value}
                        </Typography>
                        <FormHelperText>{error?.message}</FormHelperText>
                    </>
                </FormControl>
            )}
        />
    )
}

export default FieldInputRichTextEditor

/* eslint-disable @typescript-eslint/no-var-requires */
import { defaultHost } from '../utils/Util'

export const getLabel = (domain: string, label: string) => {
    const defaultLabels = require(`./${defaultHost}.json`)

    try {
        const labels = require(`./${domain}.json`)
        return labels[label] || defaultLabels[label]
    } catch (e) {
        return defaultLabels[label]
    }
}

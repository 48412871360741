import FileUploadOutlined from '@mui/icons-material/FileUploadOutlined'
import { Avatar, Button, Stack, styled } from '@mui/material'
import { EmploymentUniverseContext } from 'context/EmploymentUniverseContext'
import React, { FormEvent, useContext, useEffect, useRef, useState } from 'react'
import useGetElementPosition from '../hooks/useGetElementPosition'
import { IStyles } from '../models/Styles'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'
import { updatePictureResume } from '../utils/DBUtils'
import EditModal from './EditModal'

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1
})

interface PictureComponentProps {
    picWidth?: number
    picHeight?: number
    picAlign?: string
    picMT?: number
    picML?: number
    picMR?: number
}

export const PictureComponent = ({ picWidth = 200, picHeight = 200, picAlign = 'center', picMT = 0, picML = 0, picMR = 0 }: PictureComponentProps): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const [selectedImage, setSelectedImage] = useState<string>()
    const [isEditable, setIsEditable] = useState(false)
    const [isModalOpen, setModalOpen] = useState(false)

    const pictureRef = useRef<HTMLDivElement | null>(null)
    const modalPosition = useGetElementPosition(pictureRef, isModalOpen, 330, 0)

    useEffect(() => {
        if (euContext.resume.picture) {
            const filePicture: string = euContext.resume.picture
            setSelectedImage(filePicture)
        } else {
            setSelectedImage('empty')
        }
    }, [euContext.resume])

    const handleChange = async (evt: FormEvent<HTMLInputElement>) => {
        evt.preventDefault()
        const pictureFile = evt.currentTarget.files ? evt.currentTarget.files[0] : null
        if (pictureFile) {
            const pictureReader = new FileReader()
            pictureReader.readAsDataURL(pictureFile)

            pictureReader.onload = () => {
                const pictureResult: string = pictureReader.result as string
                if (pictureResult) {
                    updatePictureResume(euContext.resume._id, pictureResult)
                    setSelectedImage(pictureResult)
                }
            }

            pictureReader.onerror = () => {
                console.error('Picture error: ' + pictureReader.error)
            }
        }
    }

    const handleOpenModal = () => {
        setModalOpen(true)
    }

    const handleCloseModal = () => {
        setModalOpen(false)
    }

    return (
        <>
            <div ref={pictureRef}>
                <Stack
                    spacing={0}
                    onMouseEnter={() => setIsEditable(true)}
                    onMouseLeave={() => setIsEditable(false)}
                    onClick={handleOpenModal}>
                    {selectedImage && style?.ShowGraphic && (
                        <>
                            <Avatar
                                src={selectedImage}
                                sx={{ width: picWidth, height: picHeight, alignSelf: picAlign, verticalAlign: 'middle', mt: picMT, ml: picML, mr: picMR }}
                            />
                            {isEditable && !!euContext.user?.email && (
                                <Button
                                    variant='outlined'
                                    startIcon={<FileUploadOutlined />}
                                    sx={{ width: 100, alignSelf: 'center' }}
                                    component='label'>
                                    Upload
                                    <VisuallyHiddenInput
                                        type='file'
                                        accept='image/*'
                                        onChange={handleChange}
                                    />
                                </Button>
                            )}
                        </>
                    )}
                </Stack>
            </div>
            <EditModal
                open={isModalOpen}
                onClose={handleCloseModal}
                modalPosition={modalPosition}
                title='Edit Picture'
                component={
                    <Stack
                        spacing={0}
                        onMouseEnter={() => setIsEditable(true)}
                        onMouseLeave={() => setIsEditable(false)}>
                        <Avatar
                            src={selectedImage}
                            sx={{ width: picWidth, height: picHeight, alignSelf: picAlign, verticalAlign: 'middle', mt: picMT, ml: picML, mr: picMR }}
                        />
                        <Button
                            variant='outlined'
                            startIcon={<FileUploadOutlined />}
                            sx={{ width: 100, alignSelf: 'center' }}
                            component='label'>
                            Upload
                            <VisuallyHiddenInput
                                type='file'
                                accept='image/*'
                                onChange={handleChange}
                            />
                        </Button>
                    </Stack>
                }
            />
        </>
    )
}

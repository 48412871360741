import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from '../config/axiosConfig'
import { IResume } from '../models/Resume'
import { IUser } from '../models/User'
import { loginApiLink, loginGoogleLink, loginLinkedInLink, registerLink, registerLinkedInLink } from '../utils/Constants'

interface LoginResponse {
    token: string
    user: IUser
    resume: IResume
}

const linkedInLogin = async (code: string | null, state: string | null) => {
    const response = await axios.post(loginLinkedInLink, { code, state })
    return response.data
}

const linkedInRegister = async (code: string | null, state: string | null) => {
    const response = await axios.post(registerLinkedInLink, { code, state })
    return response.data
}

const login = async ({ email, password, subdomain }: { email: string; password: string; subdomain: string }): Promise<LoginResponse> => {
    const response = await axios.post<LoginResponse>(loginApiLink, { email, password, subdomain })
    return response.data
}

const register = async ({ email, password, fullName, subdomain }: { email: string; password: string; fullName: string; subdomain: string }): Promise<LoginResponse> => {
    const response = await axios.post<LoginResponse>(registerLink, { email, password, fullName, subdomain })
    return response.data
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const googleLogin = async (googleUser: any): Promise<LoginResponse> => {
    const response = await axios.post<LoginResponse>(loginGoogleLink, { googleUser })
    return response.data
}

export const useLinkedInLogin = (code: string | null, state: string | null) => {
    return useQuery({
        queryKey: ['linkedInLogin', code, state],
        queryFn: () => linkedInLogin(code, state),
        enabled: !!code // Ensure the query runs only when the code is available
    })
}

export const useLinkedInRegister = (code: string | null, state: string | null) => {
    return useQuery({
        queryKey: ['linkedInRegister', code, state],
        queryFn: () => linkedInRegister(code, state),
        enabled: !!code // Ensure the query runs only when the code is available
    })
}

export const useLogin = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: login,
        onError: (error) => {
            console.error('Login error:', error)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['user']
            })
        }
    })
}

export const useRegister = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: register,
        onError: (error) => {
            console.error('Register error:', error)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['user']
            })
            queryClient.invalidateQueries({
                queryKey: ['users']
            })
        }
    })
}

export const useGoogleLogin = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: googleLogin,
        onError: (error) => {
            console.error('Google login error:', error)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['user']
            })
        }
    })
}

import { Box, List, ListItem, Stack, Typography } from '@mui/material'
import React, { useContext } from 'react'

import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { ISimpleListItem } from '../models/SimpleList'
import { IStyles } from '../models/Styles'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'
import FieldInputTypography from './forms/FieldInputTypography'

const SkillsBlockListComponent = ({ sectionBgColor = '', itemBgColor }: { sectionBgColor?: string; itemBgColor?: string }): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles | undefined = euContext.resume.style[euContext.resume.currentStyle]
    const orderedSkills: ISimpleListItem[] =
        euContext.resume.skills.category && euContext.resume.skills.category[0].list ? euContext.resume.skills.category[0].list?.sort((a, b) => a.seqNum - b.seqNum) : []

    return (
        <Stack>
            <FieldInputTypography
                name='skills.label'
                sx={{ mt: 2, textTransform: style?.SectionFontCase ?? 'none' }}
                fontFamily={style[style.SectionFontName]}
                fontSize={`${style?.SectionFontSize ?? 5}px`}
                fontStyle={style?.SectionFontStyle ?? 'initial'}
                alignSelf={style?.SectionAlign ?? 'initial'}
                textAlign={style?.SectionAlign ?? 'initial'}
                width='100%'
                bgcolor={sectionBgColor}
                fontWeight={style?.SectionFontWeight ?? 'initial'}
                marginBottom={`${style?.SpaceSectionTitle}px`}
            />
            <Box sx={{ paddingTop: 1, display: 'flex', flexWrap: 'wrap', minHeight: '50px' }}>
                <List sx={{ width: '90%' }}>
                    {orderedSkills.map((item: ISimpleListItem, index: number) => (
                        <ListItem
                            sx={{ padding: '2px', display: 'list-item', ml: 1, mb: 1 }}
                            key={item.item + index}>
                            <Typography
                                suppressContentEditableWarning={true}
                                fontFamily={style[style.BodyFontName]}
                                fontSize={`${style?.BodyFontSize ?? 5}px`}
                                fontStyle={style?.BodyFontStyle ?? 'initial'}
                                fontWeight={style?.BodyFontWeight ?? 'initial'}
                                paddingLeft={2}
                                bgcolor={itemBgColor}>
                                {item.item}
                            </Typography>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Stack>
    )
}

export default SkillsBlockListComponent

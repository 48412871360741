import React, { useContext } from 'react'
import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { IEmployment } from '../models/Employment'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'
import DroppableComponent from './DroppableComponent'
import EmploymentDraggableComponent from './EmploymentDraggableComponent'

const LicensesDroppableComponent = ({ sectionBgColor = '' }: { sectionBgColor?: string }): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const { resume } = euContext

    return (
        <DroppableComponent<IEmployment>
            section='licenses'
            card='license'
            items={resume.licenses.license}
            label={resume.licenses.label}
            sectionBgColor={sectionBgColor}
            DraggableComponent={EmploymentDraggableComponent}
        />
    )
}

export default LicensesDroppableComponent

import CloseIcon from '@mui/icons-material/Close'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import React from 'react'
import ViewCardsComponent from '../../components/category/ViewCardsComponent'

export interface SimpleDialogProps {
    open: boolean
    onClose: () => void
    addValueToDescription: (val: string[]) => void
    selectJobTitle: (val: string) => void
    selectJobSkill: (val: string) => void
}

const ViewStatementsDialog = (props: SimpleDialogProps) => {
    const { addValueToDescription, open, onClose } = props

    return (
        <Dialog
            onClose={onClose}
            open={open}
            maxWidth='md'
            fullWidth>
            <DialogTitle>
                View Statements
                <IconButton
                    aria-label='close'
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <ViewCardsComponent
                    addValueToDescription={addValueToDescription}
                    onClose={onClose}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    color='primary'>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ViewStatementsDialog

import { Box, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { IStyles } from '../models/Styles'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'

const ShowInitialsComponent = (): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const fullName: string[] = euContext.resume.personalInfo.fullName.split(' ')
    // Get style
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const [firstInitial, setfirstInitial] = useState<string>(fullName[0].charAt(0))
    const [lastInitial, setlastInitial] = useState<string>(fullName.length > 1 ? fullName[1].charAt(0) : '')

    useEffect(() => {
        // Get first and last initial
        const fullName: string[] = euContext.resume.personalInfo.fullName.split(' ')
        setfirstInitial(fullName[0].charAt(0))
        setlastInitial(fullName.length > 1 ? fullName[1].charAt(0) : '')
    }, [euContext.resume.personalInfo])

    return (
        <Box
            display='flex'
            position='absolute'>
            <Box
                position='absolute'
                sx={{ transform: 'translate(-40%, 5%)' }}>
                <Typography
                    fontFamily={style[style.SectionFontName]}
                    fontSize={30}
                    color='white'
                    display='inline'>
                    {firstInitial}
                </Typography>
                <Typography
                    fontFamily={style[style.SectionFontName]}
                    fontSize={30}
                    color='white'
                    display='inline'>
                    {lastInitial}
                </Typography>
            </Box>
        </Box>
    )
}

export default ShowInitialsComponent

import DragHandleIcon from '@mui/icons-material/DragHandle'
import { Box, Button, Grid, ListItem, ListItemIcon, Stack } from '@mui/material'
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state'
import HoverPopover from 'material-ui-popup-state/HoverPopover'
import React, { useContext } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { useAddEducation, useMinusEducation } from '../hooks/useResume'
import { Degrees } from '../models/Education'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'
import { cardStyleHover, cardStyleToUse, dragIconStyleHover, dragIconStyleToUse } from '../utils/Util'
import ErrorState from '../views/ErrorState'
import LoadingState from '../views/LoadingState'
import FieldInputMonth from './forms/FieldInputMonth'
import FieldInputSelect from './forms/FieldInputSelect'
import FieldInputText from './forms/FieldInputText'

interface Props {
    compNum: number
    index: number
    isDraggable: boolean
}

const EducationsDraggableComponent = ({ compNum, index, isDraggable }: Props): JSX.Element => {
    const fieldName = `educations.education[${compNum}]`
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style = euContext.resume.style[euContext.resume.currentStyle]
    const { resume, setResume } = euContext

    const { mutate: addEducation, isPending: isAdding, error: addError } = useAddEducation()
    const { mutate: minusEducation, isPending: isRemoving, error: removeError } = useMinusEducation()

    const handleAddEducation = (evt: React.MouseEvent<HTMLElement>) => {
        evt.preventDefault()
        addEducation(resume, {
            onSuccess: (data) => {
                setResume(data.resume)
            }
        })
    }

    const handleMinusEducation = (evt: React.MouseEvent<HTMLElement>) => {
        evt.preventDefault()
        minusEducation(
            { resume, compNum },
            {
                onSuccess: (data) => {
                    setResume(data.resume)
                }
            }
        )
    }

    if (isAdding || isRemoving) {
        return <LoadingState />
    }

    if (addError || removeError) {
        return <ErrorState message={'Error updating education.'} />
    }

    return (
        <PopupState
            variant='popover'
            popupId='skillsList'>
            {(popupState) => (
                <>
                    {!!euContext.user?.email && (
                        <HoverPopover
                            {...bindPopover(popupState)}
                            disableScrollLock={true}
                            sx={{ top: '-40px' }}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
                            <Stack
                                direction='row'
                                columnGap={1}
                                margin={0.5}>
                                <Button
                                    onClick={handleAddEducation}
                                    type='button'
                                    size='small'
                                    variant='outlined'
                                    sx={{ padding: 0, margin: 0 }}>
                                    +
                                </Button>
                                <Button
                                    onClick={handleMinusEducation}
                                    type='button'
                                    size='small'
                                    variant='outlined'
                                    sx={{ padding: 0, margin: 0 }}>
                                    -
                                </Button>
                            </Stack>
                        </HoverPopover>
                    )}
                    <Draggable
                        draggableId={'EducationDraggable' + index}
                        index={index}
                        isDragDisabled={!euContext.user?.email}>
                        {(provided) => (
                            <Stack
                                direction='row'
                                width='100%'
                                display='-webkit-box'>
                                <ListItem
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    sx={{ width: '100%' }}>
                                    <ListItemIcon
                                        {...provided.dragHandleProps}
                                        sx={popupState.isOpen ? { ...dragIconStyleToUse(isDraggable), ...dragIconStyleHover } : dragIconStyleToUse(isDraggable)}>
                                        <DragHandleIcon />
                                    </ListItemIcon>

                                    <Box
                                        width='100%'
                                        rowGap={0}
                                        sx={popupState.isOpen ? { ...cardStyleToUse(isDraggable, style.SpaceCard), ...cardStyleHover } : cardStyleToUse(isDraggable, style.SpaceCard)}
                                        {...bindHover(popupState)}>
                                        <Stack
                                            direction='column'
                                            spacing={0}
                                            rowGap={0}
                                            sx={{ flexGrow: 1 }}>
                                            <Stack direction='row'>
                                                <Grid
                                                    container
                                                    spacing={0}
                                                    direction='row'
                                                    justifyContent='space-between'
                                                    sx={{ flexGrow: 1 }}>
                                                    <Grid
                                                        item
                                                        xs='auto'>
                                                        <FieldInputSelect
                                                            name={`${fieldName}.degree`}
                                                            label=''
                                                            placeholder={popupState.isOpen ? 'Enter Degree' : ''}
                                                            options={Degrees}
                                                            variant='standard'
                                                            size='small'
                                                            fontSize={`${style?.BodyFontSize ?? 5}px`}
                                                            fontStyle={style?.BodyFontStyle ?? 'initial'}
                                                            alignSelf={style?.BodyAlign ?? 'end'}
                                                            fontWeight={style?.BodyFontWeight ?? 'initial'}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs='auto'>
                                                        <FieldInputText
                                                            name={`${fieldName}.Major`}
                                                            label=''
                                                            variant='standard'
                                                            sx={{ marginTop: 1 }}
                                                            placeholder={popupState.isOpen ? 'Enter Major' : ''}
                                                            fullWidth
                                                            fontFamily={style[style.BodyFontName]}
                                                            fontSize={`${style?.BodyFontSize ?? 5}px`}
                                                            fontStyle={style?.BodyFontStyle ?? 'initial'}
                                                            alignSelf={style?.BodyAlign ?? 'end'}
                                                            fontWeight={style?.BodyFontWeight ?? 'initial'}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Stack>
                                            <Stack direction='row'>
                                                <Grid
                                                    container
                                                    spacing={0}
                                                    direction='row'
                                                    justifyContent='space-between'
                                                    sx={{ flexGrow: 1 }}>
                                                    <Grid
                                                        item
                                                        xs='auto'>
                                                        <FieldInputText
                                                            name={`${fieldName}.school`}
                                                            label=''
                                                            variant='standard'
                                                            sx={{ alignSelf: 'left' }}
                                                            placeholder={popupState.isOpen ? 'School' : ''}
                                                            fullWidth
                                                            fontFamily={style[style.BodyFontName]}
                                                            fontSize={`${style?.BodyFontSize ?? 5}px`}
                                                            fontStyle={style?.BodyFontStyle ?? 'initial'}
                                                            alignSelf={style?.BodyAlign ?? 'end'}
                                                            fontWeight={style?.BodyFontWeight ?? 'initial'}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs='auto'>
                                                        <FieldInputMonth
                                                            name={`${fieldName}.graduation`}
                                                            label=''
                                                            variant='standard'
                                                            sx={{ textTransform: style?.DateFontCase ?? 'initial' }}
                                                            fontSize={`${style?.DateFontSize ?? 5}px`}
                                                            fontStyle={style?.DateFontStyle ?? 'initial'}
                                                            alignSelf={style?.DateAlign ?? 'end'}
                                                            fontWeight={style?.DateFontWeight ?? 'initial'}
                                                            dateFormat={style?.DateFormat ?? 'yyyy'}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                </ListItem>
                            </Stack>
                        )}
                    </Draggable>
                </>
            )}
        </PopupState>
    )
}

export default EducationsDraggableComponent

import Stack from '@mui/material/Stack'
import React, { useEffect, useState } from 'react'

import { useDistinctStatementCategories, useDistinctStatementJobTitles, useStatementsByCategoryJobTitle } from '../../hooks/useStatements'
import LoadingState from '../../views/LoadingState'
import CardComponent from './CardComponent'
import ViewStatementsComponent from './ViewStatementsComponent'

interface ViewCardsProps {
    addValueToDescription: (val: string[]) => void
    onClose: Function
}
const ViewCardsComponent = ({ addValueToDescription, onClose }: ViewCardsProps) => {
    const [selectedJobCategory, setSelectedJobCategory] = useState(0)
    const [selectedJobTitle, setSelectedJobTitle] = useState(0)
    const { data: distinctCategoriesResponse, isLoading: distinctCategoriessLoading } = useDistinctStatementCategories()
    const { data: distinctJobTitlesResponse, isLoading: distinctJobTitlesIsLoading } = useDistinctStatementJobTitles(
        distinctCategoriesResponse ? distinctCategoriesResponse[selectedJobCategory] : undefined
    )
    const { data: distinctCategoryJobTitlesResponse, isLoading: distinctCategoryJobTitlesIsLoading } = useStatementsByCategoryJobTitle(
        distinctCategoriesResponse ? distinctCategoriesResponse[selectedJobCategory] : undefined,
        distinctJobTitlesResponse ? distinctJobTitlesResponse[selectedJobTitle] : undefined
    )

    const loadStatementCategories = async (categoryNum: number, titleNum: number) => {
        setSelectedJobCategory(categoryNum)
        setSelectedJobTitle(titleNum)
    }

    useEffect(() => {
        loadStatementCategories(0, 0)
    }, [])

    useEffect(() => {
        if (selectedJobCategory >= 0) {
            setSelectedJobTitle(0)
        }
    }, [selectedJobCategory])

    const addStatements = (myStatements: string[]) => {
        addValueToDescription(myStatements)

        onClose()
    }

    return (
        <>
            <Stack
                direction='row'
                gap={2}
                width='100%'>
                {distinctCategoriessLoading && <LoadingState message='Loading Categories' />}
                {distinctJobTitlesIsLoading && <LoadingState message='Loading Job Titles' />}
                {!distinctCategoriessLoading && !distinctJobTitlesIsLoading && (
                    <CardComponent
                        jobCategories={distinctCategoriesResponse ?? []}
                        jobTitles={distinctJobTitlesResponse || []}
                        setSelectedJobCategory={setSelectedJobCategory}
                        setSelectedJobTitle={setSelectedJobTitle}
                        selectedJobCategory={selectedJobCategory}
                        selectedJobTitle={selectedJobTitle}
                    />
                )}
                {distinctCategoryJobTitlesIsLoading && <LoadingState message='Loading Statements' />}
                {!distinctCategoryJobTitlesIsLoading && (
                    <ViewStatementsComponent
                        label='Statements'
                        statements={distinctCategoryJobTitlesResponse ?? []}
                        addStatements={addStatements}
                    />
                )}
            </Stack>
        </>
    )
}

export default ViewCardsComponent

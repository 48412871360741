import { ReactNode } from 'react'
import { IResume } from '../models/Resume'
import { DefaultSeqNumPerStyle, validResumeStyles } from '../utils/Constants'
import DefaultResume from './Default'
import LoadingResume from './Loading'
import LoadingState from './LoadingState'
import Resume2 from './Resume2'
import Resume4 from './Resume4'
import Resume6 from './Resume6'
import Resume8 from './Resume8'
import ResumeOne from './ResumeOne'
import SingleColumnResume from './SingleColumnResume'

export const getView = (myResume: IResume): ReactNode => {
    if (!myResume || !myResume.style) {
        return LoadingState({ message: 'Loading...' })
    }

    switch (myResume.currentStyle) {
        case validResumeStyles.Colorado:
            return SingleColumnResume(myResume)
        case validResumeStyles.Default:
            return DefaultResume(myResume)
        case validResumeStyles.Harvard:
            return SingleColumnResume(myResume)
        case validResumeStyles.Miami:
            return SingleColumnResume(myResume)
        case validResumeStyles.Resume1:
            return ResumeOne(myResume)
        case validResumeStyles.Resume2:
            return Resume2(myResume)
        case validResumeStyles.Resume4:
            return Resume4(myResume)
        case validResumeStyles.Resume6:
            return Resume6(myResume)
        case validResumeStyles.Resume8:
            return Resume8(myResume)
        default:
            return LoadingResume()
    }
}

export const getStyleSeqNumForStyle = (style: validResumeStyles) => {
    switch (style) {
        case validResumeStyles.Colorado:
            return DefaultSeqNumPerStyle.Colorado
        case validResumeStyles.Harvard:
            return DefaultSeqNumPerStyle.Harvard
        case validResumeStyles.Miami:
            return DefaultSeqNumPerStyle.Miami
        case validResumeStyles.Resume1:
            return DefaultSeqNumPerStyle.Resume1
        case validResumeStyles.Resume2:
            return DefaultSeqNumPerStyle.Resume2
        case validResumeStyles.Resume4:
            return DefaultSeqNumPerStyle.Resume4
        case validResumeStyles.Resume6:
            return DefaultSeqNumPerStyle.Resume6
        case validResumeStyles.Resume8:
            return DefaultSeqNumPerStyle.Resume8
        default:
            return DefaultSeqNumPerStyle.Default
    }
}

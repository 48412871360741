import { List, Stack } from '@mui/material'
import React, { useContext } from 'react'
import { DragDropContext, Droppable, type DropResult } from 'react-beautiful-dnd'
import { useFormContext } from 'react-hook-form'
import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { useUpdateResumeField } from '../hooks/useResume'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'
import ErrorState from '../views/ErrorState'
import LoadingState from '../views/LoadingState'
import FieldInputTypography from './forms/FieldInputTypography'

interface DroppableComponentProps<T> {
    section: SectionName
    card: CardName
    items: T[]
    label: string
    sectionBgColor?: string
    DraggableComponent: React.ComponentType<{ item: T; index: number; compNum: number; isDraggable: boolean; section: string; card: string }>
}

interface IResumeSections {
    licenses: 'license'
    projects: 'topics'
    trainings: 'training'
    educations: 'education'
    volunteers: 'volunteer'
    employments: 'employment'
    affiliations: 'affiliation'
    publications: 'publication'
    honors: 'honor'
    talents: 'talent'
    references: 'reference'
}

type SectionName = keyof IResumeSections
type CardName = IResumeSections[SectionName]

const DroppableComponent = <T extends { seqNum: number }>({ section, card, items, label, sectionBgColor = '', DraggableComponent }: DroppableComponentProps<T>): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style = euContext.resume.style[euContext.resume.currentStyle]
    const { resume, setResume } = euContext
    const { reset } = useFormContext()
    const { mutate: updateResumeField, isPending, isError } = useUpdateResumeField()

    const handleDragEnd = (result: DropResult) => {
        const { destination, source } = result

        if (!destination) return

        const updatedItems = Array.from(items)
        const [movedItem] = updatedItems.splice(source.index, 1)
        updatedItems.splice(destination.index, 0, movedItem)

        for (let i = 0; i < updatedItems.length; i++) {
            updatedItems[i].seqNum = i
        }

        setResume({
            ...resume,
            [section]: { label: resume[section as SectionName].label, [card]: updatedItems }
        })

        updateResumeField({ id: resume._id, fieldName: `${section}.${card}`, fieldValue: updatedItems })
        reset(resume)
    }

    const sortedItems = (): T[] => {
        const copyItems = items.slice()
        return copyItems.sort((a, b) => a.seqNum - b.seqNum)
    }

    const getResumeIndexFromSeqNum = (seqNum: number): number => {
        return items.findIndex((item) => item.seqNum === seqNum)
    }

    if (isPending) {
        return <LoadingState message={'Updating...'} />
    }

    if (isError) {
        return <ErrorState message={'Error updating resume field'} />
    }

    return (
        <Stack>
            <FieldInputTypography
                name={`${section}.label`}
                fontFamily={style[style.SectionFontName]}
                fontSize={`${style?.SectionFontSize ?? 5}px`}
                fontStyle={style?.SectionFontStyle ?? 'initial'}
                alignSelf={style?.SectionAlign ?? 'initial'}
                textAlign={style?.SectionAlign ?? 'initial'}
                width='100%'
                bgcolor={sectionBgColor}
                fontWeight={style?.SectionFontWeight ?? 'initial'}
                marginBottom={`${style?.SpaceSectionTitle}px`}
                sx={{ textTransform: style?.SectionFontCase ?? 'none' }}>
                {label}
            </FieldInputTypography>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable
                    droppableId={`droppable${section}`}
                    direction='vertical'>
                    {(provided) => (
                        <List
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            sx={{ width: '100%' }}>
                            {sortedItems().map((item, index) => (
                                <DraggableComponent
                                    key={item.seqNum}
                                    item={item}
                                    compNum={getResumeIndexFromSeqNum(item.seqNum)}
                                    index={index}
                                    section={section}
                                    card={card}
                                    isDraggable={items.length > 1}
                                />
                            ))}
                            {provided.placeholder}
                        </List>
                    )}
                </Droppable>
            </DragDropContext>
        </Stack>
    )
}

export default DroppableComponent

import React, { useContext } from 'react'
import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { IEmployment } from '../models/Employment'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'
import DroppableComponent from './DroppableComponent'
import EmploymentDraggableComponent from './EmploymentDraggableComponent'

const HonorsDroppableComponent = ({ sectionBgColor = '' }: { sectionBgColor?: string }): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const { resume } = euContext

    return (
        <DroppableComponent<IEmployment>
            section='honors'
            card='honor'
            items={resume.honors.honor}
            label={resume.honors.label}
            sectionBgColor={sectionBgColor}
            DraggableComponent={EmploymentDraggableComponent}
        />
    )
}

export default HonorsDroppableComponent

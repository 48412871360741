import { Stack, Typography } from '@mui/material'
import React, { useContext } from 'react'

import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { Degrees, IEducation } from '../models/Education'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'
import FieldInputTypography from './forms/FieldInputTypography'

interface Props {
    sectionBgColor?: string
}

const EducationListComponent = ({ sectionBgColor = '' }: Props): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style = euContext.resume.style[euContext.resume.currentStyle]
    const orderedEducation: IEducation[] = euContext.resume.educations.education.sort((a, b) => a.graduationYear - b.graduationYear)

    return (
        <Stack>
            <FieldInputTypography
                name='educations.label'
                fontFamily={style[style.SectionFontName]}
                fontSize={`${style?.SectionFontSize ?? 5}px`}
                fontStyle={style?.SectionFontStyle ?? 'initial'}
                alignSelf={style?.SectionAlign ?? 'initial'}
                textAlign={style?.SectionAlign ?? 'initial'}
                width='100%'
                bgcolor={sectionBgColor}
                fontWeight={style?.SectionFontWeight ?? 'initial'}
                marginBottom={`${style?.SpaceSectionTitle}px`}
                sx={{ textTransform: style?.SectionFontCase ?? 'none' }}
            />
            <Stack
                direction='column'
                rowGap={1}>
                {orderedEducation.map((ed: IEducation, index: number) => {
                    const degreeLabel: string = Degrees.filter((d) => d.value === ed.degree)[0].label

                    return (
                        <Stack
                            direction='column'
                            key={index}>
                            <Typography
                                fontFamily={style[style.BodyFontName]}
                                fontSize={`${style.BodyFontSize}px`}
                                fontStyle={style.BodyFontStyle}
                                fontWeight={style.BodyFontWeight}
                                textAlign={style.BodyAlign}>
                                {ed.major}&nbsp;|&nbsp;{degreeLabel}
                            </Typography>
                            <Typography
                                fontFamily={style[style.BodyFontName]}
                                fontSize={`${style.BodyFontSize}px`}
                                fontStyle={style.BodyFontStyle}
                                fontWeight={style.BodyFontWeight}
                                textAlign={style.BodyAlign}>
                                {ed.school}
                            </Typography>
                            <Typography
                                fontFamily={style[style.BodyFontName]}
                                fontSize={`${style.BodyFontSize}px`}
                                fontStyle={style.BodyFontStyle}
                                fontWeight={style.BodyFontWeight}
                                textAlign={style.BodyAlign}>
                                {ed.graduationYear}
                            </Typography>
                        </Stack>
                    )
                })}
            </Stack>
        </Stack>
    )
}

export default EducationListComponent

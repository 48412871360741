import { MenuItem, Select, type SelectChangeEvent, TableCell, TableRow, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { useUpdateResumeField } from '../hooks/useResume'
import { IResume } from '../models/Resume'
import { FontNameFields, FontNameKeys, FontNames } from '../models/Styles'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'
import { validResumeStyles } from '../utils/Constants'
import FontSettingComponent from './FontSettingComponent'

const FontNameSettingComponent = ({ fieldName }: { fieldName: string }) => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const { mutate: updateResumeField } = useUpdateResumeField()
    const fontNameField = `${fieldName}FontName` as FontNameKeys

    const changeResumeFont = async (event: SelectChangeEvent<string>) => {
        event.preventDefault()
        event.stopPropagation()

        const newValue = event.target.value
        if (!newValue) return

        const resume: IResume = euContext.resume
        const styleName: validResumeStyles = resume.currentStyle
        const styleFieldName = `style.${styleName}.${fontNameField}`
        const fontName: FontNameKeys = newValue as FontNameKeys
        updateResumeField(
            { id: resume._id, fieldName: styleFieldName, fieldValue: fontName },
            {
                onSuccess: (data) => {
                    euContext.setResume(data.resume)
                }
            }
        )
    }

    return (
        <>
            <TableRow>
                <TableCell>
                    <Typography>{fieldName} Font Name</Typography>
                </TableCell>
                <TableCell>
                    <Select
                        value={euContext.resume.style[euContext.resume.currentStyle][fontNameField] as FontNames}
                        onChange={changeResumeFont}
                        size='small'
                        sx={{ width: '150px' }}>
                        {Object.keys(FontNameFields).map((val, index: number) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={val}>
                                    {Object.values(FontNameFields)[index]}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </TableCell>
            </TableRow>
            <FontSettingComponent fieldName={fieldName} />
        </>
    )
}

export default FontNameSettingComponent

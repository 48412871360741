import { Stack, Typography } from '@mui/material'
import React, { useContext } from 'react'

import { EmploymentUniverseContext } from 'context/EmploymentUniverseContext'
import { EmploymentUniverseType } from 'types/EmploymentUniverseType'
import { IStyles } from '../models/Styles'
import { AddressComponent } from './AddressComponent'

export const LocationComponent = (): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)

    const anchorRef = React.useRef(null)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]

    return (
        <>
            <Stack
                spacing={0}
                direction='column'>
                <Typography
                    ref={anchorRef}
                    variant='h6'
                    fontFamily={style[style.SectionFontName]}
                    sx={{
                        fontSize: `${style?.SectionFontSize ?? 5}px`,
                        fontStyle: style?.SectionFontStyle ?? 'initial',
                        alignSelf: style?.SectionAlign ?? 'initial',
                        fontWeight: style?.SectionFontWeight ?? 'initial',
                        marginBottom: `${style?.SpaceSectionTitle}px`,
                        textTransform: style?.SectionFontCase ?? 'none'
                    }}>
                    Location
                </Typography>
                <AddressComponent />
            </Stack>
        </>
    )
}

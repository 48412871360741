import { Alert, Button, Snackbar, Stack, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { useClient, useUpdateClient } from '../hooks/useClients'
import { IClients } from '../models/Clients'
import CustomTable from './CustomTable'

const columns = [
    { id: 'field', label: 'Field' },
    { id: 'value', label: 'Value' }
]

const ClientSettings = () => {
    const euContext = useContext(EmploymentUniverseContext)
    const [alertOpen, setAlertOpen] = useState(false)
    const { data: clientResponse } = useClient(euContext.user.client_id)
    const updateClient = useUpdateClient()
    const [myClient, setMyClient] = useState<IClients | undefined>(clientResponse?.client)

    useEffect(() => setMyClient(clientResponse?.client), [clientResponse])
    if (!myClient) return <Typography>Getting Client Info...</Typography>

    const handleClientChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target
        setMyClient({ ...myClient, [name]: value })
    }

    const handleSaveClient = () => {
        updateClient.mutate(
            { ...myClient },
            {
                onSuccess: () => {
                    setAlertOpen(true)
                },
                onError: (error) => {
                    console.error('Error updating client:', error)
                }
            }
        )
    }

    const rows = [
        {
            field: 'Name',
            value: (
                <TextField
                    name='name'
                    value={myClient.name}
                    onChange={handleClientChange}
                    variant='outlined'
                    fullWidth
                />
            )
        },
        { field: 'Type', value: <Typography>{myClient.type}</Typography> },
        { field: 'Domain', value: <Typography>{myClient.subdomain}</Typography> },
        {
            field: 'Client URL',
            value: (
                <TextField
                    name='client_url'
                    value={myClient.client_url}
                    onChange={handleClientChange}
                    variant='outlined'
                    fullWidth
                />
            )
        },
        {
            field: 'Client Email',
            value: (
                <TextField
                    name='default_email'
                    value={myClient.default_email}
                    onChange={handleClientChange}
                    variant='outlined'
                    fullWidth
                />
            )
        },
        {
            field: 'Client Talent Community Id',
            value: (
                <TextField
                    name='talent_community_id'
                    value={myClient.talent_community_id}
                    onChange={handleClientChange}
                    variant='outlined'
                    fullWidth
                />
            )
        },
        {
            field: 'Client Public Key',
            value: (
                <TextField
                    name='public_key'
                    value={myClient.public_key}
                    onChange={handleClientChange}
                    variant='outlined'
                    fullWidth
                />
            )
        },
        {
            field: 'Client Private Key',
            value: (
                <TextField
                    name='private_key'
                    value={myClient.private_key}
                    onChange={handleClientChange}
                    variant='outlined'
                    fullWidth
                />
            )
        },
        {
            field: 'Client Security Certificate',
            value: (
                <TextField
                    name='security_cert'
                    value={myClient.security_cert}
                    onChange={handleClientChange}
                    variant='outlined'
                    fullWidth
                />
            )
        },
        {
            field: 'Client Certificate Authority',
            value: (
                <TextField
                    name='certificate_authority'
                    value={myClient.certificate_authority}
                    onChange={handleClientChange}
                    variant='outlined'
                    fullWidth
                />
            )
        },
        { field: 'Created', value: clientResponse?.client.created.toLocaleString() },
        { field: 'Updated', value: clientResponse?.client.updated.toLocaleString() }
    ]

    return (
        <Stack direction={'column'}>
            <CustomTable
                columns={columns}
                rows={rows}
            />
            <Button
                variant='contained'
                sx={{
                    backgroundColor: euContext.htmlColors?.card.buttonBackgroundColor,
                    color: euContext.htmlColors?.card.buttonTextColor,
                    borderColor: euContext.htmlColors?.card.borderBackgroundColor,
                    '&:hover': {
                        backgroundColor: euContext.htmlColors?.card.hoverBackgroundColor,
                        color: euContext.htmlColors?.card.hoverTextColor
                    }
                }}
                onClick={handleSaveClient}>
                Save Client
            </Button>
            <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={() => setAlertOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert
                    onClose={() => setAlertOpen(false)}
                    severity='success'
                    sx={{ width: '100%' }}>
                    Client saved successfully!
                </Alert>
            </Snackbar>
        </Stack>
    )
}

export default ClientSettings

import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { getLabel } from '../labels/labelUtils'
import { IUser } from '../models/User'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'
import { INavigationItem, loggedOutNavigationItems, mainNavigationItems } from './AppNavigationItems'

export const AppNavigation = () => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const navigate = useNavigate()
    const [selectedIndex, setSelectedIndex] = useState(0)
    const navigationsItems = euContext.user.email ? mainNavigationItems : loggedOutNavigationItems

    const showItem = (item: INavigationItem) => {
        return (
            <ListItem
                key={item.id}
                disablePadding>
                <ListItemButton
                    selected={selectedIndex === item.id}
                    onClick={() => {
                        setSelectedIndex(item.id)
                        navigate(item.route)
                    }}
                    sx={{
                        '&.Mui-selected': {
                            backgroundColor: euContext.htmlColors?.navigationBar.backgroundColor,
                            borderColor: euContext.htmlColors?.navigationBar.borderColor,
                            borderWidth: '1px',
                            borderStyle: 'solid'
                        },
                        '&:hover': {
                            backgroundColor: euContext.htmlColors?.navigationBar.hoverBackgroundColor,
                            borderColor: euContext.htmlColors?.navigationBar.borderColor
                        },
                        backgroundColor: euContext.htmlColors?.navigationBar.backgroundColor,
                        color: euContext.htmlColors?.navigationBar.textColor
                    }}>
                    <ListItemIcon sx={{ color: euContext.htmlColors?.navigationBar.textColor }}>{item.icon}</ListItemIcon>
                    <ListItemText
                        primary={getLabel(euContext.domain, item.label)}
                        sx={{
                            display: { md: 'block' },
                            color: euContext.htmlColors?.navigationBar.textColor
                        }}
                    />
                </ListItemButton>
            </ListItem>
        )
    }

    return (
        <>
            <List key='UserNavList'>
                {navigationsItems.map((item) => {
                    if (!item.isAdmin && !item.isSuperAdmin) {
                        return showItem(item)
                    }
                    return null
                })}
            </List>
            {(euContext.user.isAdmin || euContext.user.isSuperAdmin) && (
                <>
                    <Divider sx={{ backgroundColor: '#37B7C3' }} />
                    <List key='AdminNavList'>
                        {navigationsItems.map((item) => {
                            if (item.isAdmin) {
                                return showItem(item)
                            }
                            return null
                        })}
                    </List>
                </>
            )}
            {euContext.user.isSuperAdmin && (
                <>
                    <Divider sx={{ backgroundColor: '#37B7C3' }} />
                    <List key='AdminNavList'>
                        {navigationsItems.map((item) => {
                            if (item.isSuperAdmin) {
                                return showItem(item)
                            }
                            return null
                        })}
                    </List>
                </>
            )}

            {euContext.user.email && <Divider sx={{ backgroundColor: '#37B7C3' }} />}
            {euContext.user.email && (
                <ListItem
                    key='logout'
                    disablePadding>
                    <ListItemButton
                        onClick={() => {
                            euContext.setToken('')
                            euContext.setUser({} as IUser)
                            setSelectedIndex(0)
                            navigate('/login')
                        }}
                        sx={{
                            '&.Mui-selected': {
                                backgroundColor: euContext.htmlColors?.navigationBar.hoverBackgroundColor,
                                borderColor: euContext.htmlColors?.navigationBar.borderColor,
                                borderWidth: '1px',
                                borderStyle: 'solid'
                            },
                            '&:hover': {
                                backgroundColor: euContext.htmlColors?.navigationBar.hoverBackgroundColor,
                                borderColor: euContext.htmlColors?.navigationBar.borderColor
                            },
                            backgroundColor: euContext.htmlColors?.navigationBar.backgroundColor,
                            color: euContext.htmlColors?.navigationBar.textColor
                        }}>
                        <ListItemIcon sx={{ color: euContext.htmlColors?.navigationBar.textColor }}>
                            <LogoutOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary='Logout'
                            sx={{ color: euContext.htmlColors?.navigationBar.textColor }}
                        />
                    </ListItemButton>
                </ListItem>
            )}
        </>
    )
}

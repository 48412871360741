import React from 'react'
import axios from '../config/axiosConfig'
import { IResume } from '../models/Resume'
import { IUser } from '../models/User'
import { loginLinkedInLink, registerLinkedInLink } from '../utils/Constants'

export const LinkedInSignIn = () => {
    const handleLogin = async (code: string, state: string | null) => {
        const response = await axios.post(loginLinkedInLink, { code, state })
        let user: IUser | undefined = undefined
        let resume: IResume | undefined = undefined
        let token: string | undefined = undefined

        if (response?.data.user != null) {
            user = response.data.user
        }

        if (response?.data.resume != null) {
            resume = response.data.resume
        }

        if (response?.data.token != null) {
            token = response.data.token
        }
        window.opener.postMessage({ user, resume, token })
        window.close()
    }

    const handleLinkedInCallback = () => {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        const code = urlParams.get('code')
        const state = urlParams.get('state')
        if (code) handleLogin(code, state)
    }

    React.useEffect(() => {
        handleLinkedInCallback()
    }, [])

    return <div>Logging In ...</div>
}

export const LinkedInRegister = () => {
    const handleLogin = async (code: string, state: string | null) => {
        const response = await axios.post(registerLinkedInLink, { code, state })
        let user: IUser | undefined = undefined
        let resume: IResume | undefined = undefined
        let token: string | undefined = undefined

        if (response?.data.user != null) {
            user = response.data.user
        }

        if (response?.data.resume != null) {
            resume = response.data.resume
        }

        if (response?.data.token != null) {
            token = response.data.token
        }

        window.opener.postMessage({ user, resume, token })
        window.close()
    }

    const handleLinkedInCallback = () => {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        const code = urlParams.get('code')
        const state = urlParams.get('state')
        if (code) handleLogin(code, state)
    }

    React.useEffect(() => {
        handleLinkedInCallback()
    }, [])

    return <div>Registering...</div>
}

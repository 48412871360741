import React, { useContext } from 'react'

import { Box, Typography } from '@mui/material'
import { EmploymentUniverseContext } from 'context/EmploymentUniverseContext'
import { EmploymentUniverseType } from 'types/EmploymentUniverseType'
import { IStyles } from '../models/Styles'

export const NameComponentShade = ({
    sx,
    mt = 0,
    firstNameColor = 'black',
    lastNameColor = 'lightgray'
}: {
    sx?: object
    mt?: number
    firstNameColor?: string
    lastNameColor?: string
}): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const fullName: string[] = euContext.resume.personalInfo.fullName.split(' ')

    return (
        <Box
            textAlign={style?.NameAlign ?? 'end'}
            textTransform={style?.NameFontCase ?? 'none'}
            marginBottom={`${style?.SpaceSectionTitle}px`}
            marginTop={`${mt}px`}
            width='100%'>
            <Typography
                margin='none'
                fontFamily={style[style.NameFontName]}
                fontSize={`${style?.NameFontSize ?? 5}px`}
                fontWeight={style?.NameFontWeight ?? 'initial'}
                fontStyle={style?.NameFontStyle ?? 'initial'}
                textAlign={style?.NameAlign ?? 'end'}
                textTransform={style?.NameFontCase ?? 'none'}
                color={firstNameColor}
                {...sx}>
                {fullName[0]}
            </Typography>
            <Typography
                margin='none'
                fontFamily={style[style.NameFontName]}
                fontSize={`${style?.NameFontSize ?? 5}px`}
                fontWeight={style?.NameFontWeight ?? 'initial'}
                fontStyle={style?.NameFontStyle ?? 'initial'}
                textAlign={style?.NameAlign ?? 'end'}
                textTransform={style?.NameFontCase ?? 'none'}
                {...sx}>
                &nbsp;
            </Typography>
            <Typography
                margin='none'
                fontFamily={style[style.NameFontName]}
                fontSize={`${style?.NameFontSize ?? 5}px`}
                fontWeight={style?.NameFontWeight ?? 'initial'}
                fontStyle={style?.NameFontStyle ?? 'initial'}
                textAlign={style?.NameAlign ?? 'end'}
                textTransform={style?.NameFontCase ?? 'none'}
                color={lastNameColor}
                {...sx}>
                {fullName[1]}
            </Typography>
        </Box>
    )
}

import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { Box, List, ListItem, Stack, Typography } from '@mui/material'
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state'
import HoverPopover from 'material-ui-popup-state/HoverPopover'
import React, { FocusEvent, MouseEvent, useContext, useRef, useState } from 'react'
import { DragDropContext, Draggable, Droppable, type DropResult } from 'react-beautiful-dnd'
import { useFormContext } from 'react-hook-form'

import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { useUpdateResumeField } from '../hooks/useResume'
import { ISimpleListItem } from '../models/SimpleList'
import { IStyles } from '../models/Styles'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'
import ErrorState from '../views/ErrorState'
import LoadingState from '../views/LoadingState'
import FieldInputTypography from './forms/FieldInputTypography'

const AccomplishmentsListComponent = ({ sectionBgColor = '' }: { sectionBgColor?: string }): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const [accomplishments, setLocalAccomplishments] = useState(euContext.resume.accomplishments.category[0].list?.sort((a, b) => a.seqNum - b.seqNum) || [])
    const { reset, setValue } = useFormContext()
    const { mutate: updateResumeField, isPending, isError } = useUpdateResumeField()

    const handleDelete = (evt: MouseEvent<SVGSVGElement>, index: number) => {
        evt.preventDefault()
        doDelete(index)
    }

    const setAccomplishments = (myAccs: ISimpleListItem[]) => {
        setLocalAccomplishments(myAccs)
        updateAccomplishmentsInDB(myAccs)
        const newAccomplishments = euContext.resume.accomplishments
        newAccomplishments.category[0].list = myAccs
        euContext.setResume({
            ...euContext.resume,
            accomplishments: newAccomplishments
        })
        reset(euContext.resume)
    }

    const updateAccomplishmentsInDB = (accomplishments: ISimpleListItem[]) => {
        updateResumeField({
            id: euContext.resume._id,
            fieldName: 'accomplishments.list',
            fieldValue: accomplishments
        })
    }

    const handleChange = async (evt: FocusEvent<HTMLSpanElement>, index: number) => {
        evt.preventDefault()

        const value: string | null = evt.currentTarget.textContent

        if (value !== null) {
            // Update form value
            const name = `accomplishments.list[${index}].item`
            setValue(name, value)

            // Update state
            const newAccomplishments = accomplishments.slice()
            newAccomplishments[index].item = value

            setAccomplishments(newAccomplishments)
        }
    }

    const doDelete = (index: number) => {
        const newList = accomplishments.filter((item: ISimpleListItem, idx: number) => {
            return idx !== index
        })

        newList.forEach((item: ISimpleListItem) => {
            if (item.seqNum > index) item.seqNum--
        })

        setAccomplishments(newList)
    }

    const addValueToAccomplishments = (value: string) => {
        const prevAccomplishments = accomplishments.slice()
        const maxSeqNum = prevAccomplishments.reduce((max, accomplishment) => (max > accomplishment.seqNum ? max : accomplishment.seqNum), -1)
        prevAccomplishments.push({ seqNum: maxSeqNum + 1, item: value, rate: 0 })

        setAccomplishments(prevAccomplishments)
    }

    const handleDragEnd = (result: DropResult) => {
        const { destination, source } = result

        if (!destination) {
            return
        }

        const newList = accomplishments.slice()

        if (source.index !== destination.index) {
            if (source.index < destination.index) {
                newList.forEach((accomplishment: ISimpleListItem) => {
                    if (accomplishment.seqNum > source.index && accomplishment.seqNum <= destination.index) {
                        accomplishment.seqNum -= 1
                    } else if (accomplishment.seqNum === source.index) {
                        accomplishment.seqNum = destination.index
                    }
                })
            } else {
                newList.forEach((accomplishment: ISimpleListItem) => {
                    if (accomplishment.seqNum < source.index && accomplishment.seqNum >= destination.index) {
                        accomplishment.seqNum += 1
                    } else if (accomplishment.seqNum === source.index) {
                        accomplishment.seqNum = destination.index
                    }
                })
            }

            setAccomplishments(newList)
        }
    }

    const anchorRef = useRef(null)

    if (isPending) {
        return <LoadingState message={'Updating...'} />
    }

    if (isError) {
        return <ErrorState message={'Error updating resume field'} />
    }

    return (
        <Stack>
            <FieldInputTypography
                name='accomplishments.label'
                fontFamily={style[style?.SectionFontName] ?? 'Roboto'}
                fontSize={`${style?.SectionFontSize ?? 5}px`}
                fontStyle={style?.SectionFontStyle ?? 'initial'}
                alignSelf={style?.SectionAlign ?? 'initial'}
                textAlign={style?.SectionAlign ?? 'initial'}
                width='100%'
                bgcolor={sectionBgColor}
                fontWeight={style?.SectionFontWeight ?? 'initial'}
                marginBottom={`${style?.SpaceSectionTitle}px`}
                sx={{ textTransform: style?.SectionFontCase ?? 'none' }}
            />
            <Box
                ref={anchorRef}
                sx={{ paddingTop: 1, display: 'flex', flexWrap: 'wrap', minHeight: '50px' }}
                onClick={(evt) => {
                    evt.preventDefault()
                    evt.stopPropagation()
                    addValueToAccomplishments(' ')
                }}>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable
                        droppableId='droppableAccomplishments'
                        direction='vertical'
                        isDropDisabled={!euContext.user?.email}>
                        {(provided) => (
                            <List
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                style={{ display: 'display-inside', flexWrap: 'wrap' }}>
                                {accomplishments.map((item: ISimpleListItem, index: number) => {
                                    return (
                                        <PopupState
                                            key={index}
                                            variant='popover'
                                            popupId='accomplishmentsDel'>
                                            {(popupState) => (
                                                <Box key={index}>
                                                    {!!euContext.user?.email && (
                                                        <HoverPopover
                                                            {...bindPopover(popupState)}
                                                            disableScrollLock={true}
                                                            sx={{ top: '-20px' }}
                                                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
                                                            <Stack
                                                                direction='row'
                                                                columnGap={1}>
                                                                <HighlightOffIcon
                                                                    onClick={(evt) => {
                                                                        evt.preventDefault()
                                                                        evt.stopPropagation()
                                                                        handleDelete(evt, index)
                                                                    }}
                                                                    sx={{ backgroundColor: 'transparent' }}
                                                                    color='error'
                                                                />
                                                            </Stack>
                                                        </HoverPopover>
                                                    )}
                                                    <Draggable
                                                        draggableId={accomplishments[index].item + index}
                                                        index={index}
                                                        isDragDisabled={!euContext.user?.email}>
                                                        {(provided, snapshot) => (
                                                            <ListItem
                                                                ref={provided.innerRef}
                                                                sx={{ padding: '2px' }}
                                                                className={snapshot.isDragging ? 'grey-background' : ''}
                                                                {...provided.dragHandleProps}
                                                                {...provided.draggableProps}>
                                                                <Typography
                                                                    {...bindHover(popupState)}
                                                                    onBlur={(evt) => handleChange(evt, index)}
                                                                    onClick={(evt) => {
                                                                        evt.preventDefault()
                                                                        evt.stopPropagation()
                                                                    }}
                                                                    suppressContentEditableWarning={true}
                                                                    contentEditable={!!euContext.user?.email}
                                                                    fontFamily={style[style?.BodyFontName] ?? 'Roboto'}
                                                                    fontSize={`${style?.BodyFontSize ?? 5}px`}
                                                                    fontStyle={style?.BodyFontStyle ?? 'initial'}
                                                                    alignSelf={style?.BodyAlign ?? 'end'}
                                                                    fontWeight={style?.BodyFontWeight ?? 'initial'}>
                                                                    {item.item}
                                                                </Typography>
                                                            </ListItem>
                                                        )}
                                                    </Draggable>
                                                </Box>
                                            )}
                                        </PopupState>
                                    )
                                })}
                                {provided.placeholder}
                            </List>
                        )}
                    </Droppable>
                </DragDropContext>
            </Box>
        </Stack>
    )
}

export default AccomplishmentsListComponent

import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import axios from '../config/axiosConfig'
import { IResume } from '../models/Resume'
import { IUser } from '../models/User'
import { tokensLink } from '../utils/Constants'
import { isTokenExpiringSoon } from '../utils/Util'

export interface ITokenResponse {
    token: string
    user: IUser
    resume: IResume
}

const fetchTokens = async (): Promise<ITokenResponse> => {
    const response = await axios.get<ITokenResponse>(tokensLink)
    return response.data
}

export const useTokens = () => {
    return useQuery<ITokenResponse>({
        queryKey: ['tokens'],
        queryFn: fetchTokens
    })
}

const useTokenExpiryAlert = (token: string, thresholdInMinutes: number = 5) => {
    const [isExpiringSoon, setIsExpiringSoon] = useState(false)

    useEffect(() => {
        const checkTokenExpiry = () => {
            if (isTokenExpiringSoon(token, thresholdInMinutes)) {
                setIsExpiringSoon(true)
            } else {
                setIsExpiringSoon(false)
            }
        }

        checkTokenExpiry()
        const intervalId = setInterval(checkTokenExpiry, 60000)

        return () => clearInterval(intervalId)
    }, [token, thresholdInMinutes])

    return isExpiringSoon
}

export default useTokenExpiryAlert

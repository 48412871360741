import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from '../config/axiosConfig'
import { IEducation } from '../models/Education'
import { IResume } from '../models/Resume'
import { IResumeInfo, IUser } from '../models/User'
import { educationLink, employmentLink, projectLink, publicationLink, referenceLink, resumeInfoLink, resumeLink, resumesLink, talentLink } from '../utils/Constants'

interface IResumeResponse {
    resume: IResume
}

const fetchResume = async (id: string): Promise<IResumeResponse> => {
    const response = await axios.get(resumeLink(id))
    return response.data
}

interface IResumeInfoResponse {
    resumeInfos: IResumeInfo[]
}

const fetchResumeinfo = async (userId: string): Promise<IResumeInfoResponse> => {
    if (userId) {
        const response = await axios.get(resumeInfoLink(userId))
        return response.data
    } else return { resumeInfos: [] }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const updateResumeField = async (id: string, fieldName: string, fieldValue: any) => {
    const response = await axios.patch(resumeLink(id), {
        fieldName,
        fieldValue
    })
    return response.data
}

const addEducation = async (resume: IResume | undefined) => {
    const response = await axios.post(educationLink(resume?._id))
    return response.data
}

const minusEducation = async (resume: IResume | undefined, compNum: number) => {
    const response = await axios.delete(`${educationLink(resume?._id)}/${compNum}`)
    return response.data
}

const updateEducation = async (resumeId: string, educations: IEducation[]) => {
    const response = await axios.patch(educationLink(resumeId), {
        fieldName: 'educations.education',
        fieldValue: educations
    })
    return response.data
}

const addEmployment = async (resume: IResume | undefined) => {
    const response = await axios.post(employmentLink(resume?._id))
    return response.data
}

const minusEmployment = async (resume: IResume | undefined, compNum: number) => {
    const response = await axios.delete(`${employmentLink(resume?._id)}/${compNum}`)
    return response.data
}

const addProject = async (resume: IResume | undefined) => {
    const response = await axios.post(projectLink(resume?._id))
    return response.data
}

const minusProject = async (resume: IResume | undefined, compNum: number) => {
    const response = await axios.delete(`${projectLink(resume?._id)}/${compNum}`)
    return response.data
}

const addPublication = async (resume: IResume | undefined) => {
    const response = await axios.post(publicationLink(resume?._id))
    return response.data
}

const minusPublication = async (resume: IResume | undefined, compNum: number) => {
    const response = await axios.delete(`${publicationLink(resume?._id)}/${compNum}`)
    return response.data
}

const addReference = async (resume: IResume | undefined) => {
    const response = await axios.post(referenceLink(resume?._id))
    return response.data
}

const minusReference = async (resume: IResume | undefined, compNum: number) => {
    const response = await axios.delete(`${referenceLink(resume?._id)}/${compNum}`)
    return response.data
}

const addTalent = async (resume: IResume | undefined) => {
    const response = await axios.post(talentLink(resume?._id))
    return response.data
}

const minusTalent = async (resume: IResume | undefined, compNum: number) => {
    const response = await axios.delete(`${talentLink(resume?._id)}/${compNum}`)
    return response.data
}

const addResume = async ({ user }: { user: IUser }): Promise<IResume> => {
    const response = await axios.post(resumesLink, { user })
    return response.data
}

const deleteResume = async (resumeId: string, user: IUser): Promise<IResume> => {
    const response = await axios.delete(resumeLink(resumeId), { data: user })
    return response.data
}

export const useResume = (id: string) => {
    return useQuery<IResumeResponse>({
        queryKey: ['resume', id],
        queryFn: () => fetchResume(id),
        enabled: !!id
    })
}

export const useResumeInfo = (userId: string) => {
    return useQuery<IResumeInfoResponse>({
        queryKey: ['resumeInfo', userId],
        queryFn: () => fetchResumeinfo(userId),
        enabled: !!userId
    })
}

export const useAddEducation = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (resume: IResume | undefined) => addEducation(resume),
        onError: (error) => {
            console.error('Error adding education:', error)
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({
                queryKey: ['resume']
            })
            return data
        }
    })
}

export const useMinusEducation = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ resume, compNum }: { resume: IResume | undefined; compNum: number }) => minusEducation(resume, compNum),
        onError: (error) => {
            console.error('Error removing education:', error)
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({
                queryKey: ['resume']
            })
            return data
        }
    })
}

export const useUpdateEducation = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ resumeId, educations }: { resumeId: string; educations: IEducation[] }) => updateEducation(resumeId, educations),
        onError: (error) => {
            console.error('Error updating education:', error)
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({
                queryKey: ['resume']
            })
            return data
        }
    })
}

export const useUpdateResumeField = () => {
    const queryClient = useQueryClient()

    return useMutation({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        mutationFn: ({ id, fieldName, fieldValue }: { id: string; fieldName: string; fieldValue: any }) => updateResumeField(id, fieldName, fieldValue),
        onError: (error) => {
            console.error('Error updating resume field:', error)
        },
        onSuccess: (data) => {
            // Invalidate or refetch queries that might be affected by this mutation
            queryClient.invalidateQueries({
                queryKey: ['resume']
            })

            return data
        }
    })
}

export const useUpdateResumeName = () => {
    const queryClient = useQueryClient()

    return useMutation({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        mutationFn: ({ id, fieldValue }: { id: string; fieldValue: any }) => updateResumeField(id, 'resumeName', fieldValue),
        onError: (error) => {
            console.error('Error updating resume field:', error)
        },
        onSuccess: (data) => {
            // Invalidate or refetch queries that might be affected by this mutation
            queryClient.invalidateQueries({
                queryKey: ['resume']
            })

            queryClient.invalidateQueries({
                queryKey: ['resumeInfo']
            })

            return data
        }
    })
}

export const useAddEmployment = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (resume: IResume | undefined) => addEmployment(resume),
        onError: (error) => {
            console.error('Error adding employment:', error)
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({
                queryKey: ['resume']
            })
            return data
        }
    })
}

export const useMinusEmployment = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ resume, compNum }: { resume: IResume | undefined; compNum: number }) => minusEmployment(resume, compNum),
        onError: (error) => {
            console.error('Error removing employment:', error)
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({
                queryKey: ['resume']
            })
            return data
        }
    })
}

export const useAddProject = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (resume: IResume | undefined) => addProject(resume),
        onError: (error) => {
            console.error('Error adding project:', error)
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({
                queryKey: ['resume']
            })
            return data
        }
    })
}

export const useMinusProject = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({ resume, compNum }: { resume: IResume | undefined; compNum: number }) => minusProject(resume, compNum),
        onError: (error) => {
            console.error('Error removing project:', error)
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({
                queryKey: ['resume']
            })
            return data
        }
    })
}

export const useAddPublication = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (resume: IResume | undefined) => addPublication(resume),
        onError: (error) => {
            console.error('Error adding publication:', error)
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({
                queryKey: ['resume']
            })
            return data
        }
    })
}

export const useMinusPublication = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({ resume, compNum }: { resume: IResume | undefined; compNum: number }) => minusPublication(resume, compNum),
        onError: (error) => {
            console.error('Error removing publication:', error)
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({
                queryKey: ['resume']
            })
            return data
        }
    })
}

export const useAddReference = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (resume: IResume | undefined) => addReference(resume),
        onError: (error) => {
            console.error('Error adding reference:', error)
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({
                queryKey: ['resume']
            })
            return data
        }
    })
}

export const useMinusReference = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({ resume, compNum }: { resume: IResume | undefined; compNum: number }) => minusReference(resume, compNum),
        onError: (error) => {
            console.error('Error removing reference:', error)
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({
                queryKey: ['resume']
            })
            return data
        }
    })
}

export const useAddTalent = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (resume: IResume | undefined) => addTalent(resume),
        onError: (error) => {
            console.error('Error adding talent:', error)
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({
                queryKey: ['resume']
            })
            return data
        }
    })
}

export const useMinusTalent = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ resume, compNum }: { resume: IResume | undefined; compNum: number }) => minusTalent(resume, compNum),
        onError: (error) => {
            console.error('Error removing talent:', error)
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({
                queryKey: ['resume']
            })
            return data
        }
    })
}

export const useAddResume = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: addResume,
        onError: (error) => {
            console.error('Error adding resume:', error)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['resumes']
            })
        }
    })
}

export const useDeleteResume = (resumeId: string, user: IUser) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: () => deleteResume(resumeId, user),
        onError: (error) => {
            console.error('Error deleting resume:', error)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['resumes']
            })
        }
    })
}

import Stack from '@mui/material/Stack'
import React, { useContext } from 'react'

import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { getLabel } from '../labels/labelUtils'
import { IStyles } from '../models/Styles'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'
import FieldInputTextArea from './forms/FieldInputTextArea'
import FieldInputTypography from './forms/FieldInputTypography'

const OtherComponent = ({ sectionBgColor = '' }: { sectionBgColor?: string }): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]

    return (
        <Stack spacing={0}>
            <FieldInputTypography
                name='other.label'
                fontFamily={style[style.SectionFontName]}
                textAlign={style?.SectionAlign ?? 'initial'}
                width='100%'
                bgcolor={sectionBgColor}
                fontSize={`${style?.SectionFontSize ?? 5}px`}
                fontStyle={style?.SectionFontStyle ?? 'initial'}
                alignSelf={style?.SectionAlign ?? 'initial'}
                fontWeight={style?.SectionFontWeight ?? 'initial'}
                marginBottom={`${style?.SpaceSectionTitle}px`}
                textTransform={style?.SectionFontCase ?? 'none'}
            />
            <FieldInputTextArea
                name='other.description'
                label=''
                placeholder={getLabel(euContext.domain, 'summaryCardDefaultDescription')}
                sx={{
                    fontSize: `${style?.BodyFontSize ?? 5}px`,
                    fontStyle: style?.BodyFontStyle ?? 'initial',
                    alignSelf: style?.BodyAlign ?? 'end',
                    fontWeight: style?.BodyFontWeight ?? 'initial'
                }}
            />
        </Stack>
    )
}

export default OtherComponent

import { Box, Button, Grid, ListItem, ListItemIcon, Stack } from '@mui/material'
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state'
import HoverPopover from 'material-ui-popup-state/HoverPopover'
import React, { MouseEvent, useContext } from 'react'
import { useFormContext } from 'react-hook-form'

import DragHandleIcon from '@mui/icons-material/DragHandle'
import { Draggable } from 'react-beautiful-dnd'

import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { useAddPublication, useMinusPublication } from '../hooks/useResume'
import { IStyles } from '../models/Styles'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'
import { cardStyleHover, cardStyleToUse, dragIconStyleHover, dragIconStyleToUse } from '../utils/Util'
import ErrorState from '../views/ErrorState'
import LoadingState from '../views/LoadingState'
import FieldInputMonth from './forms/FieldInputMonth'
import FieldInputText from './forms/FieldInputText'
import FieldInputTextArea from './forms/FieldInputTextArea'

type Props = {
    compNum: number
    index: number
    isDraggable: boolean
}

const PublicationDraggableComponent = ({ compNum, index, isDraggable }: Props): JSX.Element => {
    const fieldName = `publications.publication[${compNum}]`
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const { resume, setResume } = euContext
    const { reset } = useFormContext()

    const { mutate: addPublication, isPending: isAdding, error: addError } = useAddPublication()
    const { mutate: minusPublication, isPending: isRemoving, error: removeError } = useMinusPublication()

    const handleAddPublication = (evt: MouseEvent<HTMLElement>) => {
        evt.preventDefault()
        addPublication(resume, {
            onSuccess: (data) => {
                setResume(data.resume)
                reset(data.resume)
            }
        })
    }

    const handleMinusPublication = (evt: MouseEvent<HTMLElement>) => {
        evt.preventDefault()
        minusPublication(
            { resume, compNum },
            {
                onSuccess: (data) => {
                    setResume(data.resume)
                    reset(data.resume)
                }
            }
        )
    }

    if (isAdding || isRemoving) {
        return <LoadingState />
    }

    if (addError || removeError) {
        return <ErrorState message={'Error updating publication.'} />
    }

    return (
        <PopupState
            variant='popover'
            popupId='skillsList'>
            {(popupState) => (
                <>
                    {!!euContext.user?.email && (
                        <>
                            <HoverPopover
                                {...bindPopover(popupState)}
                                disableScrollLock={true}
                                sx={{ top: '-40px' }}
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
                                <Stack
                                    direction='row'
                                    columnGap={1}
                                    margin={0.5}>
                                    <Button
                                        onClick={handleAddPublication}
                                        type='button'
                                        size='small'
                                        variant='outlined'
                                        sx={{ padding: 0, margin: 0, width: '20px' }}>
                                        +
                                    </Button>
                                    <Button
                                        onClick={handleMinusPublication}
                                        type='button'
                                        size='small'
                                        variant='outlined'
                                        sx={{ padding: 0, margin: 0, width: '20px' }}>
                                        -
                                    </Button>
                                </Stack>
                            </HoverPopover>
                        </>
                    )}
                    <Draggable
                        draggableId={resume.publications.publication[compNum].title + index}
                        index={index}
                        isDragDisabled={!euContext.user?.email}>
                        {(provided) => (
                            <ListItem
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                sx={{ width: '100%' }}>
                                <ListItemIcon
                                    {...provided.dragHandleProps}
                                    sx={popupState.isOpen ? { ...dragIconStyleToUse(isDraggable), ...dragIconStyleHover } : dragIconStyleToUse(isDraggable)}>
                                    <DragHandleIcon />
                                </ListItemIcon>
                                <Box
                                    width='100%'
                                    rowGap={0}
                                    sx={popupState.isOpen ? { ...cardStyleToUse(isDraggable, style.SpaceCard), ...cardStyleHover } : cardStyleToUse(isDraggable, style.SpaceCard)}
                                    {...bindHover(popupState)}>
                                    <Stack
                                        direction='column'
                                        spacing={0}
                                        rowGap={0}
                                        sx={{ flexGrow: 1 }}>
                                        <FieldInputText
                                            name={`${fieldName}.title`}
                                            label=''
                                            variant='standard'
                                            placeholder={popupState.isOpen ? 'Enter Publication Title' : ''}
                                            fontFamily={style[style.JobTitleFontName]}
                                            fontSize={`${style?.JobTitleFontSize ?? 5}px`}
                                            fontStyle={style?.JobTitleFontStyle ?? 'initial'}
                                            alignSelf={style?.JobTitleAlign ?? 'end'}
                                            fontWeight={style?.JobTitleFontWeight ?? 'initial'}
                                        />
                                        <Grid
                                            container
                                            spacing={1}
                                            direction='row'
                                            justifyContent='space-between'
                                            sx={{ flexGrow: 1 }}>
                                            <Grid
                                                item
                                                xs='auto'>
                                                <FieldInputText
                                                    name={`${fieldName}.publisher`}
                                                    label=''
                                                    variant='standard'
                                                    sx={{ alignSelf: 'left', width: '100%' }}
                                                    placeholder={popupState.isOpen ? 'Publisher' : ''}
                                                    fontFamily={style[style.CompanyFontName]}
                                                    fontSize={`${style?.CompanyFontSize ?? 5}px`}
                                                    fontStyle={style?.CompanyFontStyle ?? 'initial'}
                                                    alignSelf={style?.CompanyAlign ?? 'end'}
                                                    fontWeight={style?.CompanyFontWeight ?? 'initial'}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs='auto'
                                                alignItems='right'>
                                                <FieldInputMonth
                                                    name={`${fieldName}.pub`}
                                                    label=''
                                                    variant='standard'
                                                    sx={{ alignSelf: 'end', marginTop: -0.75 }}
                                                    fontSize={`${style?.DateFontSize ?? 5}px`}
                                                    fontStyle={style?.DateFontStyle ?? 'initial'}
                                                    alignSelf={style?.DateAlign ?? 'end'}
                                                    fontWeight={style?.DateFontWeight ?? 'initial'}
                                                    dateFormat={style?.DateFormat ?? 'yyyy'}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            spacing={1}
                                            direction='row'
                                            justifyContent='space-between'
                                            sx={{ flexGrow: 1 }}>
                                            <Grid
                                                item
                                                xs='auto'>
                                                <FieldInputText
                                                    name={`${fieldName}.publications`}
                                                    label=''
                                                    variant='standard'
                                                    sx={{ alignSelf: 'left', width: '100%' }}
                                                    placeholder={popupState.isOpen ? 'Publications' : ''}
                                                    fontFamily={style[style.CompanyFontName]}
                                                    fontSize={`${style?.CompanyFontSize ?? 5}px`}
                                                    fontStyle={style?.CompanyFontStyle ?? 'initial'}
                                                    alignSelf={style?.CompanyAlign ?? 'end'}
                                                    fontWeight={style?.CompanyFontWeight ?? 'initial'}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs='auto'
                                                alignItems='right'>
                                                <Stack
                                                    direction='row'
                                                    alignItems='right'>
                                                    <FieldInputText
                                                        name={`${fieldName}.pages`}
                                                        label=''
                                                        variant='standard'
                                                        placeholder={popupState.isOpen ? 'Pages' : ''}
                                                        sx={{ alignSelf: 'end', marginTop: -0.75 }}
                                                        fontFamily={style[style.BodyFontName]}
                                                        fontSize={`${style?.BodyFontSize ?? 5}px`}
                                                        fontStyle={style?.BodyFontStyle ?? 'initial'}
                                                        alignSelf={style?.BodyAlign ?? 'end'}
                                                        fontWeight={style?.BodyFontWeight ?? 'initial'}
                                                    />
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                        <FieldInputTextArea
                                            name={`${fieldName}.description`}
                                            label=''
                                            placeholder={popupState.isOpen ? 'Enter Job Description' : ''}
                                            fontSize={`${style?.BodyFontSize ?? 5}px`}
                                            fontStyle={style?.BodyFontStyle ?? 'initial'}
                                            alignSelf={style?.BodyAlign ?? 'end'}
                                            fontWeight={style?.BodyFontWeight ?? 'initial'}
                                        />
                                    </Stack>
                                </Box>
                            </ListItem>
                        )}
                    </Draggable>
                </>
            )}
        </PopupState>
    )
}

export default PublicationDraggableComponent

import { IStyles } from '../models/Styles'
import Colorado from '../resumeStyles/ColoradoStyle'
import Default from '../resumeStyles/DefaultStyle'
import Harvard from '../resumeStyles/HarvardStyle'
import Miami from '../resumeStyles/MiamiStyle'
import Resume1 from '../resumeStyles/Resume1Style'
import Resume2 from '../resumeStyles/Resume2Style'
import Resume4 from '../resumeStyles/Resume4Style'
import Resume6 from '../resumeStyles/Resume6Style'
import Resume8 from '../resumeStyles/Resume8Style'

const usersLink = '/api/v1/users'
const userLink = (id: string) => `${usersLink}/${id}`
const loginApiLink = `${usersLink}/login`
const loginGoogleLink = `${usersLink}/loginGoogle`
const loginLinkedInLink = `${usersLink}/loginLinkedIn`
const registerLink = `${usersLink}/register`
const registerLinkedInLink = `${usersLink}/registerLinkedIn`
const registerGoogleLink = `${usersLink}/registerGoogle`
const restoreLink = '/api/v1/restore'
const restoreUsersLink = (id: string) => `${restoreLink}/${id}`
const restoreGetUsersLink = (subdomain: string) => `${restoreLink}/${subdomain}`
const resumeInfoLink = (userId: string) => `${usersLink}/${userId}/resumeInfos`
const askOpenAILink = '/api/askOpenAI'
const askGoogleAILink = '/api/askGoogleAI'
const updateFieldLink = '/api/updateField'
const updatePictureLink = '/api/updatePicture'
const addStatementLink = '/api/addStatement'
const updateResumeTitleLink = '/api/updateResumeTitle'
const updateStyleFieldLink = '/api/updateStyleField'
const addStyleLink = '/api/addStyle'
const deleteStyleLink = '/api/deleteStyle'
const importResumeLink = '/api/importResume'
const resumesLink = '/api/v1/resumes'
const viewResumeLink = (domain: string, fullName: string, resumeName: string, location: string, state: string) => `/api/v1/view/${domain}/${fullName}/${resumeName}/${location}/${state}`
const resumeLink = (id: string) => `${resumesLink}/${id}`
const checkIfPublicLink = (id: string) => `${resumeLink(id)}/public`
const affiliationLink = (id: string) => `${resumeLink(id)}/affiliations`
const educationLink = (id: string) => `${resumeLink(id)}/educations`
const employmentLink = (id: string) => `${resumeLink(id)}/employments`
const honorLink = (id: string) => `${resumeLink(id)}/honors`
const licenseLink = (id: string) => `${resumeLink(id)}/licenses`
const projectLink = (id: string) => `${resumeLink(id)}/projects`
const publicationLink = (id: string) => `${resumeLink(id)}/publications`
const referenceLink = (id: string) => `${resumeLink(id)}/references`
const talentLink = (id: string) => `${resumeLink(id)}/talents`
const trainingLink = (id: string) => `${resumeLink(id)}/trainings`
const volunteerLink = (id: string) => `${resumeLink(id)}/volunteers`
const clientsLink = '/api/v1/clients'
const stripeLink = '/api/v1/stripe'
const checkoutSessionLink = `${stripeLink}/create-checkout-session`
const sessionStatusLink = (sessionId: string) => `${stripeLink}/session-status?session_id=${sessionId}`
const clientLink = (id: string) => `${clientsLink}/${id}`
const environmentLink = '/api/v1/environment'
const clientExistLink = (subdomian: string) => `${environmentLink}/${subdomian}/exists`
const clientColorLink = (subdomian: string) => `${clientsLink}/${subdomian}/htmlColors`
const clientUsersLink = (subdomain: string) => `${clientsLink}/${subdomain}/users`
const statementsLink = '/api/v1/statements'
const statementLink = (id: string) => `${statementsLink}/${id}`
const getStripePaymentsLink = `${stripeLink}/payments`
const getPaymentMethodsLink = (customerId: string) => `${stripeLink}/payment-methods/${customerId}`
const getDistinctStatementCategoriesLink = `${statementsLink}/categories`
const getDistinctStatementJobTitlesLink = (category: string) => `${getDistinctStatementCategoriesLink}/${category}`
const getStatementsByCategoryJobTitleLink = (category: string, jobTitle: string) => `${getDistinctStatementCategoriesLink}/${category}/jobTitles/${jobTitle}`
const tokensLink = '/api/v1/tokens'
export {
    addStatementLink,
    addStyleLink,
    affiliationLink,
    askGoogleAILink,
    askOpenAILink,
    checkIfPublicLink,
    checkoutSessionLink,
    clientColorLink,
    clientExistLink,
    clientLink,
    clientsLink,
    clientUsersLink,
    deleteStyleLink,
    educationLink,
    employmentLink,
    environmentLink,
    getDistinctStatementCategoriesLink,
    getDistinctStatementJobTitlesLink,
    getPaymentMethodsLink,
    getStatementsByCategoryJobTitleLink,
    getStripePaymentsLink,
    honorLink,
    importResumeLink,
    licenseLink,
    loginApiLink,
    loginGoogleLink,
    loginLinkedInLink,
    projectLink,
    publicationLink,
    referenceLink,
    registerGoogleLink,
    registerLink,
    registerLinkedInLink,
    restoreGetUsersLink,
    restoreUsersLink,
    resumeInfoLink,
    resumeLink,
    resumesLink,
    sessionStatusLink,
    statementLink,
    statementsLink,
    talentLink,
    tokensLink,
    trainingLink,
    updateFieldLink,
    updatePictureLink,
    updateResumeTitleLink,
    updateStyleFieldLink,
    userLink,
    usersLink,
    viewResumeLink,
    volunteerLink
}

export const MAX_RESUMES = 25

export const notAddable = -2
export const notShown = -1

export enum cardTypes {
    accomplishments = 'accomplishments',
    affiliations = 'affiliations',
    capabilities = 'capabilities',
    educations = 'educations',
    employments = 'employments',
    honors = 'honors',
    licenses = 'licenses',
    objective = 'objective',
    other = 'other',
    personal = 'personal',
    projects = 'projects',
    professional = 'professional',
    publications = 'publications',
    references = 'references',
    skills = 'skills',
    summary = 'summary',
    talents = 'talents',
    trainings = 'trainings',
    volunteers = 'volunteers'
}

export type DefaultSeqNumPerStyleDetailType = {
    [card in cardTypes]?: number
}

export enum validResumeStyles {
    Default = 'Default',
    Colorado = 'Colorado',
    Harvard = 'Harvard',
    Miami = 'Miami',
    Resume1 = 'Resume1',
    Resume2 = 'Resume2',
    Resume4 = 'Resume4',
    Resume6 = 'Resume6',
    Resume8 = 'Resume8'
}

export type DefaultSeqNumPerStyleType = {
    [style in validResumeStyles]: DefaultSeqNumPerStyleDetailType
}

// -2 do not ever show, -1 not visible but can show, positive number is order or card
export const DefaultSeqNumPerStyle: DefaultSeqNumPerStyleType = {
    Colorado: { employments: 1, educations: 2, summary: 0, skills: 3 },
    Harvard: { employments: 1, educations: 2, summary: 0, skills: 3 },
    Miami: { employments: 1, educations: 2, summary: 0, skills: 3 },
    Default: { employments: 0, educations: 1, summary: notAddable, skills: 2 },
    Resume1: { employments: 1, educations: 0, summary: notAddable, skills: notAddable },
    Resume2: { employments: 1, educations: notAddable, summary: notAddable, skills: 0 },
    Resume4: { employments: 1, educations: notAddable, summary: 0, skills: notAddable },
    Resume6: { skills: notAddable, employments: notAddable, educations: notAddable, licenses: notAddable, summary: 0, accomplishments: 1, capabilities: 2 },
    Resume8: { educations: notAddable, skills: notAddable, capabilities: notAddable, other: notAddable, summary: 0, employments: 1 }
}

export type DefaultMaxSeqNumPerStyleType = {
    [style in validResumeStyles]: number
}

export const DefaultMaxSeqNumPerStyle: DefaultMaxSeqNumPerStyleType = {
    Colorado: 3,
    Harvard: 3,
    Miami: 3,
    Default: 2,
    Resume1: 1,
    Resume2: 1,
    Resume4: 1,
    Resume6: 2,
    Resume8: 1
}

export type ResumeStylesType = {
    [style in validResumeStyles]: IStyles
}

export const ResumeStyles: ResumeStylesType = {
    Colorado,
    Harvard,
    Miami,
    Default,
    Resume1,
    Resume2,
    Resume4,
    Resume6,
    Resume8
}

import { Stack } from '@mui/material'
import React, { ChangeEvent, useContext } from 'react'

import { EmploymentUniverseContext } from 'context/EmploymentUniverseContext'
import LocationDialog from 'pages/dialog/LocationDialog'
import { useFormContext } from 'react-hook-form'
import { EmploymentUniverseType } from 'types/EmploymentUniverseType'
import { useUpdateResumeField } from '../hooks/useResume'
import { IAddress } from '../models/Address'
import { IStyles } from '../models/Styles'
import { getLocation, getPlace } from '../services/LocationService'
import ErrorState from '../views/ErrorState'
import LoadingState from '../views/LoadingState'
import FieldInputText from './forms/FieldInputText'
import { locationInterface } from './interface/Interfaces'

interface AddressInterface {
    hideStreet?: boolean
    hideCity?: boolean
    hideState?: boolean
    hideZip?: boolean
}

export const AddressComponent = ({ hideCity = false, hideState = false, hideStreet = false, hideZip = false }: AddressInterface): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const { setValue } = useFormContext()
    const [open, setOpen] = React.useState(false)
    const [locationOptions, setLocationOptions] = React.useState([] as object[])
    const { mutate: updateResumeField, isPending, isError } = useUpdateResumeField()

    const handleChange = async (evt: ChangeEvent<HTMLInputElement>) => {
        evt.preventDefault()
        const value = evt.currentTarget.value

        if (value?.length > 0) {
            const location: locationInterface[] | undefined = await getLocation(value)

            if (location && location?.length > 0) {
                setLocationOptions(location)
                setOpen(true)
            }
        }
    }

    const selectLocation = async (location: string) => {
        setOpen(false)
        const place = await getPlace(location)

        if (place) {
            const address: IAddress = {
                city: place.Municipality ?? '',
                state: place.Region ?? '',
                street: `${place.AddressNumber ?? ''}${place.AddressNumber && place.Street ? ' ' : ''}${place.Street ?? ''}`,
                zip: place.PostalCode ?? ''
            }

            // Wait for a second for OnBlur to finish
            setTimeout(() => {
                // Update resume in database.
                updateResumeField(
                    { id: euContext.resume._id, fieldName: 'personalInfo.address', fieldValue: address },
                    {
                        onSuccess: (data) => {
                            euContext.setResume(data.resume)
                            setValue('personalInfo.address', data.resume.personalInfo.address)
                        }
                    }
                )
            }, 1000)
        }
    }

    const anchorRef = React.useRef(null)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]

    if (isPending) {
        return <LoadingState message={'Updating...'} />
    }

    if (isError) {
        return <ErrorState message={'Error updating resume field'} />
    }

    return (
        <>
            <Stack
                spacing={0}
                direction='column'>
                {!hideStreet && (
                    <FieldInputText
                        name='personalInfo.address.street'
                        label=''
                        variant='standard'
                        placeholder='Enter street address'
                        closeDialog={() => {
                            setTimeout(() => setOpen(false), 500)
                        }}
                        handleChange={handleChange}
                        fontFamily={style[style.AddressFontName]}
                        fontSize={`${style?.AddressFontSize ?? 5}px`}
                        fontStyle={style?.AddressFontStyle ?? 'initial'}
                        alignSelf={style?.AddressAlign ?? 'end'}
                        fontWeight={style?.AddressFontWeight ?? 'initial'}
                    />
                )}
                {!hideCity && (
                    <FieldInputText
                        name='personalInfo.address.city'
                        label=''
                        variant='standard'
                        placeholder='Enter city'
                        closeDialog={() => {
                            setTimeout(() => setOpen(false), 500)
                        }}
                        handleChange={handleChange}
                        fontFamily={style[style.AddressFontName]}
                        fontSize={`${style?.AddressFontSize ?? 5}px`}
                        fontStyle={style?.AddressFontStyle ?? 'initial'}
                        alignSelf={style?.AddressAlign ?? 'end'}
                        fontWeight={style?.AddressFontWeight ?? 'initial'}
                        sx={{
                            display: hideStreet ? 'inline' : 'block'
                        }}
                    />
                )}
                {!hideState && (
                    <FieldInputText
                        name='personalInfo.address.state'
                        label=''
                        variant='standard'
                        placeholder='Enter state'
                        closeDialog={() => {
                            setTimeout(() => setOpen(false), 500)
                        }}
                        handleChange={handleChange}
                        fontFamily={style[style.AddressFontName]}
                        fontSize={`${style?.AddressFontSize ?? 5}px`}
                        fontStyle={style?.AddressFontStyle ?? 'initial'}
                        alignSelf={style?.AddressAlign ?? 'end'}
                        fontWeight={style?.AddressFontWeight ?? 'initial'}
                        sx={{
                            display: hideStreet ? 'inline' : 'block'
                        }}
                    />
                )}
                {!hideZip && (
                    <FieldInputText
                        name='personalInfo.address.zip'
                        label=''
                        variant='standard'
                        placeholder='Enter zip'
                        closeDialog={() => {
                            setTimeout(() => setOpen(false), 500)
                        }}
                        handleChange={handleChange}
                        fontFamily={style[style.AddressFontName]}
                        fontSize={`${style?.AddressFontSize ?? 5}px`}
                        fontStyle={style?.AddressFontStyle ?? 'initial'}
                        alignSelf={style?.AddressAlign ?? 'end'}
                        fontWeight={style?.AddressFontWeight ?? 'initial'}
                        sx={{
                            display: hideStreet ? 'inline' : 'block'
                        }}
                    />
                )}
            </Stack>
            <LocationDialog
                open={open}
                anchorEl={anchorRef.current}
                locationOptions={locationOptions}
                selectLocation={selectLocation}
            />
        </>
    )
}

import React, { useContext } from 'react'

import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'
import { BulletListComponent } from './BulletListComponent'

export const AccomplishmentsBulletListComponent = ({ sectionBgColor = '' }: { sectionBgColor?: string }): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)

    return (
        <BulletListComponent
            list={euContext.resume.accomplishments}
            sectionBgColor={sectionBgColor}
        />
    )
}

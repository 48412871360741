import { isAxiosError } from 'axios'
import axios from '../config/axiosConfig'
// eslint-disable-next-line import/named
import { User as GoogleUser } from 'firebase/auth'
import { IResume } from '../models/Resume'
import { IStyles } from '../models/Styles'
import { IUser } from '../models/User'
import {
    addStyleLink,
    checkIfPublicLink,
    deleteStyleLink,
    importResumeLink,
    registerGoogleLink,
    registerLink,
    statementsLink,
    updatePictureLink,
    updateStyleFieldLink,
    viewResumeLink
} from './Constants'

export interface registerReturnInterface {
    user: IUser | undefined
    resume: IResume | undefined
    token: string | undefined
    error?: string
}

export const register = async (emailValue: string, passwordValue: string, fullNameValue: string): Promise<registerReturnInterface> => {
    try {
        const response = await axios.post(registerLink, {
            email: emailValue,
            password: passwordValue,
            fullName: fullNameValue
        })

        const { user, resume, token } = response.data
        return { user, resume, token }
    } catch (err) {
        if (isAxiosError(err) && err.response) {
            return { user: undefined, resume: undefined, token: undefined, error: err.response.data.message }
        } else {
            return { user: undefined, resume: undefined, token: undefined, error: 'An unknown error occurred' }
        }
    }
}

export const registerGoogle = async (googleUser: GoogleUser): Promise<registerReturnInterface> => {
    try {
        const response = await axios.post(registerGoogleLink, {
            googleUser
        })

        const { user, resume, token } = response.data
        return { user, resume, token, error: undefined }
    } catch (err) {
        if (isAxiosError(err) && err.response) {
            return { user: undefined, resume: undefined, token: undefined, error: err.response.data.message }
        } else {
            return { user: undefined, resume: undefined, token: undefined, error: 'An unknown error occurred' }
        }
    }
}

export const addDBStyle = async (): Promise<IStyles[]> => {
    let newStyle: IStyles[] = []

    await axios
        .post(addStyleLink, {})
        .then((val) => {
            newStyle = val.data.styles
        })
        .catch((err) => {
            console.error(JSON.stringify(err.response))
            return err.response
        })

    return newStyle
}

export const deleteDBStyle = async (newStyle: IStyles) => {
    await axios
        .delete(deleteStyleLink, {
            data: { fieldValue: newStyle }
        })
        .catch((err) => {
            console.error(JSON.stringify(err.response))
            return err.response
        })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateFieldStyle = async (id: string, name: string, value: any): Promise<IStyles | undefined> => {
    const result = await axios
        .post(updateStyleFieldLink, {
            id,
            fieldName: name,
            fieldValue: value
        })
        .catch((err) => {
            console.error(JSON.stringify(err.response))
            return err.response
        })
    return result.data as IStyles
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateFieldStatement = async (id: string, name: string, value: any) => {
    await axios
        .post(statementsLink, {
            id,
            fieldName: name,
            fieldValue: value
        })
        .catch((err) => {
            console.error(JSON.stringify(err.response))
            return err.response
        })
}

// export const updateFieldResume = async (id: string, name: string, value: any): Promise<IResume | undefined> => {
//     const result = await axios
//         .post(updateFieldLink, {
//             id,
//             fieldName: name,
//             fieldValue: value
//         })
//         .catch((err) => {
//             console.error(JSON.stringify(err.response))
//             return err.response
//         })
//     return result.data as IResume
// }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updatePictureResume = async (id: string, value: any) => {
    await axios
        .post(updatePictureLink, {
            id,
            fieldValue: value
        })
        .catch((err) => {
            console.error(JSON.stringify(err.response))
            return err.response
        })
}

export const checkIfResumePublic = async (resumeId: string): Promise<boolean> => {
    let isPublic = false
    await axios
        .get(checkIfPublicLink(resumeId))
        .then((val) => {
            isPublic = val.data.isPublic
        })
        .catch((err) => {
            console.error(JSON.stringify(err.response))
            return err.response
        })

    return isPublic
}

export const viewResume = async (domain: string, fullName: string, resumeName: string, location: string, state: string): Promise<IResume | undefined> => {
    let resume: IResume | undefined = undefined

    await axios
        .get(viewResumeLink(domain, fullName, resumeName, location, state))
        .then((val) => {
            resume = val.data.resume
        })
        .catch((err) => {
            console.error(JSON.stringify(err))
        })

    return resume
}

export const importResume = async (user: IUser, ImportFile: File, RevisionDate: string) => {
    const formData = new FormData()
    formData.append('userEmail', user.email)
    // formData.append('processTK', 'true')
    formData.append('ImportFile', ImportFile)
    formData.append('RevisionDate', RevisionDate)

    let resume = undefined
    await axios
        .post(importResumeLink, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {
            resume = response.data.resume
        })
        .catch((error) => {
            console.error(error)
            throw error
        })

    return resume
}

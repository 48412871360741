import { Alignments, DateDelims, DateFormats, FontCases, FontNames, FontStyles, FontWeights, IStyles } from '../models/Styles'
import { validResumeStyles } from '../utils/Constants'

const Default: IStyles = {
    StyleName: 'Default' as validResumeStyles,
    PrimaryFontName: FontNames.Aboreto,
    SecondaryFontName: FontNames.Montserrat,
    AlternateFontName: FontNames.Electrolize,
    NameFontName: 'PrimaryFontName',
    NameFontSize: 45,
    NameFontWeight: FontWeights.Bold,
    NameFontStyle: FontStyles.Initial,
    NameAlign: Alignments.Center,
    NameFontCase: FontCases.Uppercase,
    TitleFontName: 'PrimaryFontName',
    TitleFontSize: 14,
    TitleFontWeight: FontWeights.Bold,
    TitleFontStyle: FontStyles.Initial,
    TitleAlign: Alignments.Center,
    TitleFontCase: FontCases.None,
    AddressFontName: 'PrimaryFontName',
    AddressFontSize: 11,
    AddressFontWeight: FontWeights.Initial,
    AddressFontStyle: FontStyles.Initial,
    AddressAlign: Alignments.End,
    AddressFontCase: FontCases.Uppercase,
    SectionFontName: 'PrimaryFontName',
    SectionFontSize: 20,
    SectionFontWeight: FontWeights.Bold,
    SectionFontStyle: FontStyles.Italic,
    SectionAlign: Alignments.Center,
    SectionFontCase: FontCases.Uppercase,
    DateFontName: 'PrimaryFontName',
    DateFontSize: 10,
    DateFontWeight: FontWeights.Bold,
    DateFontStyle: FontStyles.Italic,
    DateAlign: Alignments.End,
    DateFontCase: FontCases.Uppercase,
    DateFormat: DateFormats.MMMyyyy,
    DateDelim: DateDelims.To,
    CompanyFontName: 'PrimaryFontName',
    CompanyFontSize: 10,
    CompanyFontWeight: FontWeights.Bold,
    CompanyFontStyle: FontStyles.Initial,
    CompanyAlign: Alignments.Center,
    CompanyFontCase: FontCases.Uppercase,
    JobTitleFontName: 'PrimaryFontName',
    JobTitleFontSize: 11,
    JobTitleFontWeight: FontWeights.Bold,
    JobTitleFontStyle: FontStyles.Initial,
    JobTitleAlign: Alignments.Center,
    JobTitleFontCase: FontCases.None,
    BodyFontName: 'PrimaryFontName',
    BodyFontSize: 14,
    BodyFontWeight: FontWeights.Initial,
    BodyFontStyle: FontStyles.Initial,
    BodyAlign: Alignments.Center,
    BodyFontCase: FontCases.Uppercase,
    Special: '',
    Alignment: 'None',
    SpaceHeader: 10,
    SpaceSectionTitle: 6,
    SpaceCard: 6,
    SpaceSection: 10,
    SpaceBorder: 1,
    BorderLocation: 'None',
    BorderStyle: 'None',
    seqNum: 0,
    ShowGraphic: false,
    hasGraphic: false,
    Colors: false
}

export default Default

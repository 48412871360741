import { Box, Button, Grid, ListItem, ListItemIcon, Stack } from '@mui/material'
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state'
import HoverPopover from 'material-ui-popup-state/HoverPopover'
import React, { MouseEvent, useContext } from 'react'

import DragHandleIcon from '@mui/icons-material/DragHandle'
import { Draggable } from 'react-beautiful-dnd'

import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { useAddReference, useMinusReference } from '../hooks/useResume'
import { IStyles } from '../models/Styles'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'
import { cardStyleHover, cardStyleToUse, dragIconStyleHover, dragIconStyleToUse } from '../utils/Util'
import ErrorState from '../views/ErrorState'
import LoadingState from '../views/LoadingState'
import FieldInputTel from './forms/FieldInputTel'
import FieldInputText from './forms/FieldInputText'

type Props = {
    compNum: number
    index: number
    isDraggable: boolean
}

const ReferenceComponent = ({ compNum, index, isDraggable }: Props): JSX.Element => {
    const fieldName = `references.reference[${compNum}]`
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const { resume, setResume } = euContext

    const { mutate: addReference, isPending: isAdding, error: addError } = useAddReference()
    const { mutate: minusReference, isPending: isRemoving, error: removeError } = useMinusReference()

    const handleAddReference = (evt: MouseEvent<HTMLElement>) => {
        evt.preventDefault()
        addReference(resume, {
            onSuccess: (data) => {
                setResume(data.resume)
            }
        })
    }

    const handleMinusReference = (evt: MouseEvent<HTMLElement>) => {
        evt.preventDefault()
        minusReference(
            { resume, compNum },
            {
                onSuccess: (data) => {
                    setResume(data.resume)
                }
            }
        )
    }

    if (isAdding || isRemoving) {
        return <LoadingState />
    }

    if (addError || removeError) {
        return <ErrorState message={'Error updating reference'} />
    }

    return (
        <PopupState
            variant='popover'
            popupId='skillsList'>
            {(popupState) => (
                <>
                    {!!euContext.user?.email && (
                        <HoverPopover
                            {...bindPopover(popupState)}
                            disableScrollLock={true}
                            sx={{ top: '-40px' }}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
                            <Stack
                                direction='row'
                                columnGap={1}
                                margin={0.5}>
                                <Button
                                    onClick={handleAddReference}
                                    type='button'
                                    size='small'
                                    variant='outlined'
                                    sx={{ padding: 0, margin: 0 }}>
                                    +
                                </Button>
                                <Button
                                    onClick={handleMinusReference}
                                    type='button'
                                    size='small'
                                    variant='outlined'
                                    sx={{ padding: 0, margin: 0 }}>
                                    -
                                </Button>
                            </Stack>
                        </HoverPopover>
                    )}
                    <Draggable
                        draggableId={'ReferenceDraggable' + index}
                        index={index}
                        isDragDisabled={!euContext.user?.email}>
                        {(provided) => (
                            <Stack
                                direction='row'
                                width='100%'
                                display='-webkit-box'>
                                <ListItem
                                    ref={provided.innerRef}
                                    sx={{ width: '100%' }}
                                    {...provided.draggableProps}>
                                    <ListItemIcon
                                        {...provided.dragHandleProps}
                                        sx={popupState.isOpen ? { ...dragIconStyleToUse(isDraggable), ...dragIconStyleHover } : dragIconStyleToUse(isDraggable)}>
                                        <DragHandleIcon />
                                    </ListItemIcon>
                                    <Box
                                        width='100%'
                                        rowGap={0}
                                        sx={popupState.isOpen ? { ...cardStyleToUse(isDraggable, style.SpaceCard), ...cardStyleHover } : cardStyleToUse(isDraggable, style.SpaceCard)}
                                        {...bindHover(popupState)}>
                                        <Grid
                                            container
                                            spacing={1}
                                            direction='row'
                                            justifyContent='space-between'
                                            sx={{ flexGrow: 1 }}>
                                            <Grid
                                                item
                                                xs='auto'>
                                                <FieldInputText
                                                    name={`${fieldName}.name`}
                                                    label=''
                                                    placeholder='Enter Reference Name'
                                                    sx={{ textTransform: style?.BodyFontCase ?? 'initial', alignItems: style?.BodyAlign }}
                                                    fontFamily={style?.BodyFontName ?? 'initial'}
                                                    fontSize={`${style?.BodyFontSize ?? 5}px`}
                                                    fontStyle={style?.BodyFontStyle ?? 'initial'}
                                                    alignSelf={style?.BodyAlign ?? 'end'}
                                                    fontWeight={style?.BodyFontWeight ?? 'initial'}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs='auto'>
                                                <FieldInputText
                                                    name={`${fieldName}.email`}
                                                    label=''
                                                    placeholder='Reference Email'
                                                    sx={{ textTransform: style?.BodyFontCase ?? 'initial', alignItems: style?.BodyAlign }}
                                                    fontFamily={style?.BodyFontName ?? 'initial'}
                                                    fontSize={`${style?.BodyFontSize ?? 5}px`}
                                                    fontStyle={style?.BodyFontStyle ?? 'initial'}
                                                    alignSelf={style?.BodyAlign ?? 'end'}
                                                    fontWeight={style?.BodyFontWeight ?? 'initial'}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs='auto'>
                                                <FieldInputTel
                                                    name={`${fieldName}.phone`}
                                                    label=''
                                                    fontSize={`${style?.BodyFontSize ?? 5}px`}
                                                    fontStyle={style?.BodyFontStyle ?? 'initial'}
                                                    alignSelf={style?.BodyAlign ?? 'end'}
                                                    fontWeight={style?.BodyFontWeight ?? 'initial'}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </ListItem>
                            </Stack>
                        )}
                    </Draggable>
                </>
            )}
        </PopupState>
    )
}

export default ReferenceComponent

import { List, ListItem, Stack, Typography } from '@mui/material'
import React, { useContext } from 'react'

import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { ISimpleList, ISimpleListItem } from '../models/SimpleList'
import { IStyles } from '../models/Styles'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'

export const BulletListComponent = ({ list, sectionBgColor = '' }: { list: ISimpleList; sectionBgColor?: string }): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles | undefined = euContext.resume.style[euContext.resume.currentStyle]
    const orderedSkills = (index: number) => (list.category && list.category[index].list ? list.category[index].list?.sort((a, b) => a.seqNum - b.seqNum) : ([] as ISimpleListItem[]))

    return (
        <Stack direction='column'>
            <Typography
                sx={{ mt: 2, textTransform: style?.SectionFontCase ?? 'none' }}
                fontFamily={style[style.SectionFontName]}
                fontSize={`${style?.SectionFontSize ?? 5}px`}
                fontStyle={style?.SectionFontStyle ?? 'initial'}
                alignSelf={style?.SectionAlign ?? 'initial'}
                textAlign={style?.SectionAlign ?? 'initial'}
                width='100%'
                bgcolor={sectionBgColor}
                fontWeight={style?.SectionFontWeight ?? 'initial'}
                marginBottom={`${style?.SpaceSectionTitle}px`}>
                {list.label}
            </Typography>
            <Stack
                direction='column'
                sx={{ paddingTop: 1, display: 'flex', flexWrap: 'wrap', minHeight: '50px' }}>
                {list.category.map((category, index) => (
                    <Stack
                        key={category.name + index}
                        direction='column'>
                        {category.name && category.name.length > 0 && (
                            <Typography
                                suppressContentEditableWarning={true}
                                fontFamily={style[style.BodyFontName]}
                                fontSize={`${style?.BodyFontSize ?? 5}px`}
                                fontStyle={style?.BodyFontStyle ?? 'initial'}
                                fontWeight={style?.BodyFontWeight ?? 'initial'}>
                                {category.name}
                            </Typography>
                        )}

                        <List sx={{ listStyleType: 'disc' }}>
                            {orderedSkills(index).map((item: ISimpleListItem, index: number) => (
                                <ListItem
                                    sx={{ padding: '2px', display: 'list-item', ml: 2 }}
                                    key={item.item + index}>
                                    <Typography
                                        suppressContentEditableWarning={true}
                                        fontFamily={style[style.BodyFontName]}
                                        fontSize={`${style?.BodyFontSize ?? 5}px`}
                                        fontStyle={style?.BodyFontStyle ?? 'initial'}
                                        fontWeight={style?.BodyFontWeight ?? 'initial'}>
                                        {item.item}
                                    </Typography>
                                </ListItem>
                            ))}
                        </List>
                    </Stack>
                ))}
            </Stack>
        </Stack>
    )
}

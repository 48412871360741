import { Box, Stack, Typography } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import useGetElementPosition from '../hooks/useGetElementPosition'
import { useUpdateResumeField } from '../hooks/useResume'
import { ISimpleListItem, ratingType } from '../models/SimpleList'
import { IStyles } from '../models/Styles'
import ErrorState from '../views/ErrorState'
import LoadingState from '../views/LoadingState'
import EditModal from './EditModal'
import FieldInputTypography from './forms/FieldInputTypography'

interface SkillCirclesProps {
    maxRating?: number
}

const SkillCirclesContent = ({
    maxRating = 5,
    style,
    skills,
    ratings,
    setSkills,
    setRatings,
    onClick,
    handleClick,
    isHighlighted
}: {
    maxRating?: number
    style: IStyles
    skills: ISimpleListItem[]
    ratings: number[]
    setSkills: (skills: ISimpleListItem[]) => void
    setRatings: (ratings: number[]) => void
    onClick?: () => void
    handleClick: () => void
    isHighlighted?: boolean
}) => {
    const { setValue } = useFormContext()

    const handleCircleClick = (skillIndex: number, rating: number) => {
        const newRating = rating + 1
        const updatedRatings = [...ratings]
        updatedRatings[skillIndex] = newRating
        setRatings(updatedRatings)

        setValue(`skills[${skills[skillIndex].seqNum}].rate`, newRating)
        const updatedSkills = [...skills]
        updatedSkills[skillIndex].rate = newRating as ratingType
        setSkills(updatedSkills)
    }

    return (
        <Box
            mb={4}
            sx={{
                cursor: onClick ? 'pointer' : 'default',
                border: isHighlighted ? '2px solid #1976d2' : 'none',
                boxShadow: isHighlighted ? '0px 0px 10px rgba(25, 118, 210, 0.5)' : 'none',
                borderRadius: isHighlighted ? '8px' : 'none',
                padding: isHighlighted ? '10px' : '0'
            }}
            onClick={onClick}>
            <Typography
                variant='h3'
                fontFamily={style[style.SectionFontName]}
                fontSize={`${style?.SectionFontSize ?? 5}px`}
                fontStyle={style?.SectionFontStyle ?? 'initial'}
                alignSelf={style?.SectionAlign ?? 'initial'}
                fontWeight={style?.SectionFontWeight ?? 'initial'}
                marginBottom={`${style?.SpaceSectionTitle}px`}
                sx={{ mt: 2, textTransform: style?.SectionFontCase ?? 'none', paddingBottom: '12px' }}>
                SKILLS
            </Typography>

            {skills.map((skill, index) => {
                const currentRating = ratings[index] || 0

                return (
                    <Stack
                        key={index}
                        direction='row'
                        alignItems='center'
                        spacing={2}
                        mb={2}>
                        <FieldInputTypography
                            name={`skills[${skill.seqNum}].item`}
                            sx={{ width: 150, fontWeight: 'bold' }}
                            onClick={handleClick}
                        />

                        <Box sx={{ display: 'flex', gap: 1 }}>
                            {Array.from({ length: maxRating }).map((_, circleIndex) => (
                                <Box
                                    key={circleIndex}
                                    sx={{
                                        width: 20,
                                        height: 20,
                                        borderRadius: '50%',
                                        backgroundColor: circleIndex < currentRating ? 'darkgray' : 'lightgray',
                                        cursor: 'pointer',
                                        transition: 'background-color 0.3s ease',
                                        '&:hover': {
                                            backgroundColor: circleIndex < currentRating ? 'gray' : '#b0b0b0'
                                        }
                                    }}
                                    onClick={() => handleCircleClick(index, circleIndex)}
                                />
                            ))}
                        </Box>
                    </Stack>
                )
            })}
        </Box>
    )
}

const SkillCirclesComponent = ({ maxRating = 5 }: SkillCirclesProps) => {
    const { getValues, reset } = useFormContext()
    const euContext = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const orderedSkills = euContext.resume.skills.category?.[0]?.list ?? []
    const [skills, setLocalSkills] = useState<ISimpleListItem[]>(orderedSkills)
    const [ratings, setRatings] = useState<number[]>([])
    const [newSkill, setNewSkill] = useState('')
    const [isModalOpen, setModalOpen] = useState(false)

    const { mutate: updateResumeField, isPending, isError } = useUpdateResumeField()

    const skillRef = useRef<HTMLDivElement | null>(null)
    const modalPosition = useGetElementPosition(skillRef, isModalOpen, 290, 0)

    useEffect(() => {
        const initialRatings = skills.map((skill) => getValues(`skills[${skill.seqNum}].rate`) || 0)
        setRatings(initialRatings)
    }, [getValues, skills])

    const setSkills = (mySkills: ISimpleListItem[]) => {
        setLocalSkills(mySkills)
        updateResumeField(
            {
                id: euContext.resume._id,
                fieldName: 'skills.category[0].list',
                fieldValue: mySkills
            },
            {
                onSuccess: (data) => {
                    euContext.setResume(data.resume)
                    reset(data.resume)
                }
            }
        )
    }

    const handleAddSkill = () => {
        if (newSkill.trim() === '') return

        const newSeqNum = skills.length
        const updatedSkills = [...skills, { seqNum: newSeqNum, item: newSkill, rate: 0 }]

        setNewSkill('')
        //@ts-ignore
        setSkills(updatedSkills)
    }

    const handleOpenModal = () => {
        setModalOpen(true)
    }

    const handleCloseModal = () => {
        setModalOpen(false)
    }

    if (isPending) {
        return <LoadingState message={'Updating Skills'} />
    }
    if (isError) {
        return <ErrorState message={'Error updating skills.'} />
    }

    return (
        <>
            <div ref={skillRef}>
                <SkillCirclesContent
                    maxRating={maxRating}
                    style={style}
                    skills={skills}
                    ratings={ratings}
                    setSkills={setLocalSkills}
                    setRatings={setRatings}
                    onClick={handleOpenModal}
                    handleClick={handleAddSkill}
                    isHighlighted={isModalOpen}
                />
            </div>
            <EditModal
                open={isModalOpen}
                onClose={handleCloseModal}
                modalPosition={modalPosition}
                title='Edit Skills'
                component={
                    <SkillCirclesContent
                        maxRating={maxRating}
                        style={style}
                        skills={skills}
                        ratings={ratings}
                        setSkills={setLocalSkills}
                        setRatings={setRatings}
                        handleClick={handleAddSkill}
                        key='skill-modal'
                    />
                }
            />
        </>
    )
}

export default SkillCirclesComponent

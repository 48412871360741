import React, { useContext } from 'react'
import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'
import DroppableComponent from './DroppableComponent'
import TalentDraggableComponent from './TalentDraggableComponent'

const TalentsDroppableComponent = ({ sectionBgColor = '' }: { sectionBgColor?: string }): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const { resume } = euContext

    return (
        <DroppableComponent
            section='talents'
            card='talent'
            items={resume.talents.talent}
            label={resume.talents.label}
            sectionBgColor={sectionBgColor}
            DraggableComponent={TalentDraggableComponent}
        />
    )
}

export default TalentsDroppableComponent

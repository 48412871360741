import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { Box, List, ListItem, Stack, Typography } from '@mui/material'
import React, { FocusEvent, MouseEvent, useContext, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state'
import HoverPopover from 'material-ui-popup-state/HoverPopover'
import { DragDropContext, Draggable, Droppable, type DropResult } from 'react-beautiful-dnd'
import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { useUpdateResumeField } from '../hooks/useResume'
import { ISimpleListItem } from '../models/SimpleList'
import { IStyles } from '../models/Styles'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'
import ErrorState from '../views/ErrorState'
import LoadingState from '../views/LoadingState'
import FieldInputTypography from './forms/FieldInputTypography'

export const SkillsBubbleListComponent = ({ sectionBgColor = '' }: { sectionBgColor?: string }): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles | undefined = euContext.resume.style[euContext.resume.currentStyle]
    const orderedSkills: ISimpleListItem[] =
        euContext.resume.skills.category && euContext.resume.skills.category[0].list ? euContext.resume.skills.category[0].list?.sort((a, b) => a.seqNum - b.seqNum) : []
    const [skills, setLocalSkills] = useState(orderedSkills)
    const { reset, setValue } = useFormContext()

    const { mutate: updateResumeField, isPending, isError } = useUpdateResumeField()

    const handleDelete = (evt: MouseEvent<SVGSVGElement>, index: number) => {
        evt.preventDefault()
        doDelete(index)
    }

    const setSkills = (mySkills: ISimpleListItem[]) => {
        setLocalSkills(mySkills)
        updateResumeField(
            {
                id: euContext.resume._id,
                fieldName: 'skills.category[0].list',
                fieldValue: mySkills
            },
            {
                onSuccess: (data) => {
                    euContext.setResume(data.resume)
                    reset(data.resume)
                }
            }
        )
    }

    const handleChange = (evt: FocusEvent<HTMLSpanElement>, index: number) => {
        evt.preventDefault()
        const value: string | null = evt.currentTarget.textContent

        if (value !== null) {
            // Update form value
            const name = `skills.category[0].list[${index}].item`
            setValue(name, value)
            // Update state
            const newSkills = [...skills]
            newSkills[index].item = value

            setSkills(newSkills)
        }
    }

    const doDelete = (index: number) => {
        const newList = skills.filter((item: ISimpleListItem, idx: number) => idx !== index)

        newList.forEach((item: ISimpleListItem) => {
            if (item.seqNum > index) item.seqNum--
        })

        setSkills(newList)
    }

    const addValueToSkills = (value: string) => {
        const prevSkills = [...skills]
        const maxSeqNum = prevSkills.reduce((max, skill) => (max > skill.seqNum ? max : skill.seqNum), -1)
        prevSkills.push({ seqNum: maxSeqNum + 1, item: value, rate: 0 })

        setSkills(prevSkills)
    }

    const handleDragEnd = (result: DropResult) => {
        const { destination, source } = result
        const newList = [...skills]

        if (source && destination && source.index !== destination.index) {
            if (source.index < destination.index) {
                newList.forEach((skill: ISimpleListItem) => {
                    if (skill.seqNum > source.index && skill.seqNum <= destination.index) {
                        skill.seqNum -= 1
                    } else if (skill.seqNum === source.index) {
                        skill.seqNum = destination.index
                    }
                })
            } else {
                newList.forEach((skill: ISimpleListItem) => {
                    if (skill.seqNum < source.index && skill.seqNum >= destination.index) {
                        skill.seqNum += 1
                    } else if (skill.seqNum === source.index) {
                        skill.seqNum = destination.index
                    }
                })
            }

            setSkills(newList)
        }
    }

    const anchorRef = useRef(null)

    if (isPending) {
        return <LoadingState />
    }

    if (isError) {
        return <ErrorState message={'Error updating skills'} />
    }

    return (
        <Stack>
            <FieldInputTypography
                name='skills.label'
                sx={{ mb: 0, mt: 2, textTransform: style?.SectionFontCase ?? 'none' }}
                fontFamily={style[style.SectionFontName]}
                fontSize={`${style?.SectionFontSize ?? 5}px`}
                fontStyle={style?.SectionFontStyle ?? 'initial'}
                alignSelf={style?.SectionAlign ?? 'initial'}
                textAlign={style?.SectionAlign ?? 'initial'}
                width='100%'
                bgcolor={sectionBgColor}
                fontWeight={style?.SectionFontWeight ?? 'initial'}
                marginBottom={`${style?.SpaceSectionTitle}px`}
            />
            <Box
                ref={anchorRef}
                sx={{ paddingTop: 1, display: 'flex', flexWrap: 'wrap', minHeight: '50px' }}
                onClick={(evt) => {
                    evt.preventDefault()
                    evt.stopPropagation()
                    addValueToSkills(' ')
                }}>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable
                        droppableId='droppableSkills'
                        direction='horizontal'
                        isDropDisabled={!euContext.user?.email}>
                        {(provided) => (
                            <List
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {skills.map((item: ISimpleListItem, index: number) => (
                                    <PopupState
                                        key={index}
                                        variant='popover'
                                        popupId='skillDel'>
                                        {(popupState) => (
                                            <Box key={index}>
                                                {!!euContext.user?.email && (
                                                    <>
                                                        <HoverPopover
                                                            {...bindPopover(popupState)}
                                                            disableScrollLock={true}
                                                            sx={{ top: '-20px' }}
                                                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
                                                            <Stack
                                                                direction='row'
                                                                columnGap={1}>
                                                                <HighlightOffIcon
                                                                    onClick={(evt) => {
                                                                        evt.preventDefault()
                                                                        evt.stopPropagation()
                                                                        handleDelete(evt, index)
                                                                    }}
                                                                    sx={{ backgroundColor: 'transparent' }}
                                                                    color='error'
                                                                />
                                                            </Stack>
                                                        </HoverPopover>
                                                    </>
                                                )}
                                                <Draggable
                                                    draggableId={skills[index].item + index}
                                                    index={index}
                                                    isDragDisabled={!euContext.user?.email}>
                                                    {(provided, snapshot) => (
                                                        <ListItem
                                                            ref={provided.innerRef}
                                                            sx={{ padding: '2px' }}
                                                            key={item.item + index}
                                                            className={snapshot.isDragging ? 'grey-background' : ''}
                                                            {...provided.dragHandleProps}
                                                            {...provided.draggableProps}>
                                                            <Typography
                                                                onBlur={(evt) => handleChange(evt, index)}
                                                                onClick={(evt) => {
                                                                    evt.preventDefault()
                                                                    evt.stopPropagation()
                                                                }}
                                                                {...bindHover(popupState)}
                                                                suppressContentEditableWarning={true}
                                                                contentEditable={!!euContext.user?.email}
                                                                sx={{
                                                                    borderRadius: 6,
                                                                    minWidth: '50px',
                                                                    textAlign: 'center',
                                                                    backgroundColor: 'lightgrey'
                                                                }}
                                                                fontFamily={style[style.BodyFontName]}
                                                                fontSize={`${style?.BodyFontSize ?? 5}px`}
                                                                fontStyle={style?.BodyFontStyle ?? 'initial'}
                                                                fontWeight={style?.BodyFontWeight ?? 'initial'}>
                                                                {item.item}
                                                            </Typography>
                                                        </ListItem>
                                                    )}
                                                </Draggable>
                                            </Box>
                                        )}
                                    </PopupState>
                                ))}
                                {provided.placeholder}
                            </List>
                        )}
                    </Droppable>
                </DragDropContext>
            </Box>
        </Stack>
    )
}

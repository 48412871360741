import React, { useContext } from 'react'
import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { IEducation } from '../models/Education'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'
import DroppableComponent from './DroppableComponent'
import EducationDraggableComponent from './EducationDraggableComponent'

const EducationsDroppableComponent = ({ sectionBgColor = '' }: { sectionBgColor?: string }): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const { resume } = euContext

    return (
        <DroppableComponent<IEducation>
            section='educations'
            card='education'
            items={resume.educations.education}
            label={resume.educations.label}
            sectionBgColor={sectionBgColor}
            DraggableComponent={EducationDraggableComponent}
        />
    )
}

export default EducationsDroppableComponent

import AccountTreeIcon from '@mui/icons-material/AccountTree'
import AltRouteIcon from '@mui/icons-material/AltRoute'
import ApprovalIcon from '@mui/icons-material/Approval'
import BoltIcon from '@mui/icons-material/Bolt'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import HandshakeIcon from '@mui/icons-material/Handshake'
import NewspaperIcon from '@mui/icons-material/Newspaper'
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import PhotoCameraFrontIcon from '@mui/icons-material/PhotoCameraFront'
import PsychologyIcon from '@mui/icons-material/Psychology'
import SchoolIcon from '@mui/icons-material/School'
import StarIcon from '@mui/icons-material/Star'
import SummarizeIcon from '@mui/icons-material/Summarize'
import TrackChangesIcon from '@mui/icons-material/TrackChanges'
import TrainIcon from '@mui/icons-material/Train'
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism'
import WorkIcon from '@mui/icons-material/Work'
import { SvgIcon } from '@mui/material'
import AccomplishmentsListComponent from 'components/AccomplishmentsListComponent'
import AffiliationsDroppableComponent from 'components/AffiliationsDroppableComponent'
import CapabilitiesListComponent from 'components/CapabilitiesListComponent'
import EducationsDroppableComponent from 'components/EducationsDroppableComponent'
import EmploymentsDroppableComponent from 'components/EmploymentsDroppableComponent'
import HonorsDroppableComponent from 'components/HonorsDroppableComponent'
import LicensesDroppableComponent from 'components/LicensesDroppableComponent'
import { ObjectiveComponent } from 'components/ObjectiveComponent'
import OtherComponent from 'components/OtherComponent'
import PersonalComponent from 'components/PersonalComponent'
import ProfessionalComponent from 'components/ProfessionalComponent'
import ProjectsDroppableComponent from 'components/ProjectsDroppableComponent'
import PublicationsDroppableComponent from 'components/PublicationsDroppableComponent'
import ReferencesComponent from 'components/ReferencesComponent'
import { SummaryComponent } from 'components/SummaryComponent'
import TalentsDroppableComponent from 'components/TalentsDroppableComponent'
import TrainingsDroppableComponent from 'components/TrainingsDroppableComponent'
import VolunteersDroppableComponent from 'components/VolunteersDroppableComponent'
import { jwtDecode } from 'jwt-decode'
import { ReactNode } from 'react'
import { AccomplishmentsBulletListComponent } from '../components/AccomplishmentsBulletListComponent'
import { CapabilitiessBulletListComponent } from '../components/CapabilitiesBulletListComponent'
import { SkillsBubbleListComponent } from '../components/SkillsBubbleListComponent'
import { SkillsBulletListComponent } from '../components/SkillsBulletListComponent'
import { cardTypes, validResumeStyles } from './Constants'

export const defaultHost: string = 'blue'

export enum ButtonTypes {
    Add = 'Add',
    Remove = 'Remove'
}

export interface defaultCard {
    title: string
    description: string
    cardType: cardTypes
    isDraggable?: boolean
    cardComponent: ({ sectionBgColor }: { sectionBgColor?: string }) => ReactNode
    icon: typeof SvgIcon & { muiName: string }
}

interface TokenPayload {
    exp: number
    iat: number
}

export const DefaultDefaultCards: defaultCard[] = [
    { title: 'Accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsListComponent, description: 'List of accomplishments', icon: EmojiEventsIcon },
    { title: 'Affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsDroppableComponent, description: 'List of affiliations', icon: HandshakeIcon },
    { title: 'Capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'List of capabilities', icon: BoltIcon },
    { title: 'Educations', cardType: cardTypes.educations, cardComponent: EducationsDroppableComponent, description: 'List of educations', icon: SchoolIcon },
    { title: 'Employments', cardType: cardTypes.employments, cardComponent: EmploymentsDroppableComponent, description: 'List of employments', icon: WorkIcon },
    { title: 'Honors', cardType: cardTypes.honors, cardComponent: HonorsDroppableComponent, description: 'List of honors', icon: EmojiEventsIcon },
    { title: 'Licenses', cardType: cardTypes.licenses, cardComponent: LicensesDroppableComponent, description: 'List of licenses', icon: ApprovalIcon },
    { title: 'Objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'Objective', icon: TrackChangesIcon },
    { title: 'Other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'Other', icon: AltRouteIcon },
    { title: 'Personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'Personal', icon: PermIdentityIcon },
    { title: 'Projects', cardType: cardTypes.projects, cardComponent: ProjectsDroppableComponent, description: 'Projects', icon: AccountTreeIcon },
    { title: 'Professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'Professional', icon: AccountTreeIcon },
    { title: 'Publications', cardType: cardTypes.publications, cardComponent: PublicationsDroppableComponent, description: 'Publications', icon: NewspaperIcon },
    { title: 'References', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'Reference', icon: PsychologyIcon },
    { title: 'Skills', cardType: cardTypes.skills, cardComponent: SkillsBubbleListComponent, description: 'Skills', icon: StarIcon },
    { title: 'Summary', cardType: cardTypes.summary, cardComponent: SummaryComponent, description: 'Summary', icon: SummarizeIcon },
    { title: 'Talents', cardType: cardTypes.talents, cardComponent: TalentsDroppableComponent, description: 'Talents', icon: PhotoCameraFrontIcon },
    { title: 'Trainings', cardType: cardTypes.trainings, cardComponent: TrainingsDroppableComponent, description: 'Trainings', icon: TrainIcon },
    { title: 'Volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersDroppableComponent, description: 'Volunteers', icon: VolunteerActivismIcon }
]

export const Resume1DefaultCards: defaultCard[] = [
    { title: 'Accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsListComponent, description: 'List of accomplishments', icon: EmojiEventsIcon },
    { title: 'Affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsDroppableComponent, description: 'List of affiliations', icon: HandshakeIcon },
    { title: 'Capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'List of capabilities', icon: BoltIcon },
    { title: 'Educations', cardType: cardTypes.educations, cardComponent: EducationsDroppableComponent, description: 'List of educations', icon: SchoolIcon },
    { title: 'Employments', cardType: cardTypes.employments, cardComponent: EmploymentsDroppableComponent, description: 'List of employments', icon: WorkIcon },
    { title: 'Honors', cardType: cardTypes.honors, cardComponent: HonorsDroppableComponent, description: 'List of honors', icon: EmojiEventsIcon },
    { title: 'Licenses', cardType: cardTypes.licenses, cardComponent: LicensesDroppableComponent, description: 'List of licenses', icon: ApprovalIcon },
    { title: 'Objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'Objective', icon: TrackChangesIcon },
    { title: 'Other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'Other', icon: AltRouteIcon },
    { title: 'Personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'Personal', icon: PermIdentityIcon },
    { title: 'Projects', cardType: cardTypes.projects, cardComponent: ProjectsDroppableComponent, description: 'Projects', icon: AccountTreeIcon },
    { title: 'Professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'Professional', icon: AccountTreeIcon },
    { title: 'Publications', cardType: cardTypes.publications, cardComponent: PublicationsDroppableComponent, description: 'Publications', icon: NewspaperIcon },
    { title: 'References', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'Reference', icon: PsychologyIcon },
    { title: 'Talents', cardType: cardTypes.talents, cardComponent: TalentsDroppableComponent, description: 'Talents', icon: PhotoCameraFrontIcon },
    { title: 'Trainings', cardType: cardTypes.trainings, cardComponent: TrainingsDroppableComponent, description: 'Trainings', icon: TrainIcon },
    { title: 'Volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersDroppableComponent, description: 'Volunteers', icon: VolunteerActivismIcon }
]

export const Resume2DefaultCards: defaultCard[] = [
    { title: 'Accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsListComponent, description: 'List of accomplishments', icon: EmojiEventsIcon },
    { title: 'Affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsDroppableComponent, description: 'List of affiliations', icon: HandshakeIcon },
    { title: 'Capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'List of capabilities', icon: BoltIcon },
    { title: 'Employments', cardType: cardTypes.employments, cardComponent: EmploymentsDroppableComponent, isDraggable: true, description: 'List of employments', icon: WorkIcon },
    { title: 'Honors', cardType: cardTypes.honors, cardComponent: HonorsDroppableComponent, description: 'List of honors', icon: EmojiEventsIcon },
    { title: 'Licenses', cardType: cardTypes.licenses, cardComponent: LicensesDroppableComponent, description: 'List of licenses', icon: ApprovalIcon },
    { title: 'Objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'Objective', icon: TrackChangesIcon },
    { title: 'Other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'Other', icon: AltRouteIcon },
    { title: 'Personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'Personal', icon: PermIdentityIcon },
    { title: 'Projects', cardType: cardTypes.projects, cardComponent: ProjectsDroppableComponent, description: 'Projects', icon: AccountTreeIcon },
    { title: 'Professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'Professional', icon: AccountTreeIcon },
    { title: 'Publications', cardType: cardTypes.publications, cardComponent: PublicationsDroppableComponent, description: 'Publications', icon: NewspaperIcon },
    { title: 'References', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'Reference', icon: PsychologyIcon },
    { title: 'Skills', cardType: cardTypes.skills, cardComponent: SkillsBulletListComponent, description: 'Skills', icon: StarIcon },
    { title: 'Talents', cardType: cardTypes.talents, cardComponent: TalentsDroppableComponent, description: 'Talents', icon: PhotoCameraFrontIcon },
    { title: 'Trainings', cardType: cardTypes.trainings, cardComponent: TrainingsDroppableComponent, description: 'Trainings', icon: TrainIcon },
    { title: 'Volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersDroppableComponent, description: 'Volunteers', icon: VolunteerActivismIcon }
]

export const Resume4DefaultCards: defaultCard[] = [
    {
        title: 'Accomplishments',
        cardType: cardTypes.accomplishments,
        cardComponent: AccomplishmentsListComponent,
        description: 'List of accomplishments',
        icon: EmojiEventsIcon
    },
    {
        title: 'Affiliations',
        cardType: cardTypes.affiliations,
        cardComponent: AffiliationsDroppableComponent,
        description: 'List of affiliations',
        icon: HandshakeIcon
    },
    { title: 'Capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'List of capabilities', icon: BoltIcon },
    {
        title: 'Employments',
        cardType: cardTypes.employments,
        cardComponent: EmploymentsDroppableComponent,
        isDraggable: true,
        description: 'List of employments',
        icon: WorkIcon
    },
    { title: 'Honors', cardType: cardTypes.honors, cardComponent: HonorsDroppableComponent, description: 'List of honors', icon: EmojiEventsIcon },
    { title: 'Licenses', cardType: cardTypes.licenses, cardComponent: LicensesDroppableComponent, description: 'List of licenses', icon: ApprovalIcon },
    { title: 'Objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'Objective', icon: TrackChangesIcon },
    { title: 'Other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'Other', icon: AltRouteIcon },
    { title: 'Personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'Personal', icon: PermIdentityIcon },
    { title: 'Projects', cardType: cardTypes.projects, cardComponent: ProjectsDroppableComponent, description: 'Projects', icon: AccountTreeIcon },
    { title: 'Professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'Professional', icon: AccountTreeIcon },
    { title: 'Publications', cardType: cardTypes.publications, cardComponent: PublicationsDroppableComponent, description: 'Publications', icon: NewspaperIcon },
    { title: 'References', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'Reference', icon: PsychologyIcon },
    { title: 'Summary', cardType: cardTypes.summary, cardComponent: SummaryComponent, description: 'Professional Profile', icon: SummarizeIcon },
    { title: 'Talents', cardType: cardTypes.talents, cardComponent: TalentsDroppableComponent, description: 'Talents', icon: PhotoCameraFrontIcon },
    { title: 'Trainings', cardType: cardTypes.trainings, cardComponent: TrainingsDroppableComponent, description: 'Trainings', icon: TrainIcon },
    { title: 'Volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersDroppableComponent, description: 'Volunteers', icon: VolunteerActivismIcon }
]

export const Resume6DefaultCards: defaultCard[] = [
    { title: 'Accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsBulletListComponent, description: 'Summary Qualifications', icon: EmojiEventsIcon },
    { title: 'Affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsDroppableComponent, description: 'List of affiliations', icon: HandshakeIcon },
    { title: 'Capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiessBulletListComponent, description: 'Professional Skills', icon: BoltIcon },
    { title: 'Honors', cardType: cardTypes.honors, cardComponent: HonorsDroppableComponent, description: 'List of honors', icon: EmojiEventsIcon },
    { title: 'Objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'Objective', icon: TrackChangesIcon },
    { title: 'Other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'Other', icon: AltRouteIcon },
    { title: 'Personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'Personal', icon: PermIdentityIcon },
    { title: 'Projects', cardType: cardTypes.projects, cardComponent: ProjectsDroppableComponent, description: 'Projects', icon: AccountTreeIcon },
    { title: 'Professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'Professional', icon: AccountTreeIcon },
    { title: 'Publications', cardType: cardTypes.publications, cardComponent: PublicationsDroppableComponent, description: 'Publications', icon: NewspaperIcon },
    { title: 'References', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'Reference', icon: PsychologyIcon },
    { title: 'Summary', cardType: cardTypes.summary, cardComponent: SummaryComponent, description: 'Summary Statement', icon: SummarizeIcon },
    { title: 'Talents', cardType: cardTypes.talents, cardComponent: TalentsDroppableComponent, description: 'Talents', icon: PhotoCameraFrontIcon },
    { title: 'Trainings', cardType: cardTypes.trainings, cardComponent: TrainingsDroppableComponent, description: 'Trainings', icon: TrainIcon },
    { title: 'Volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersDroppableComponent, description: 'Volunteers', icon: VolunteerActivismIcon }
]

export const Resume8DefaultCards: defaultCard[] = [
    { title: 'Accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsListComponent, description: 'List of accomplishments', icon: EmojiEventsIcon },
    { title: 'Affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsDroppableComponent, description: 'List of affiliations', icon: HandshakeIcon },
    { title: 'Employments', cardType: cardTypes.employments, cardComponent: EmploymentsDroppableComponent, description: 'Work Experience', icon: WorkIcon },
    { title: 'Honors', cardType: cardTypes.honors, cardComponent: HonorsDroppableComponent, description: 'List of honors', icon: EmojiEventsIcon },
    { title: 'Licenses', cardType: cardTypes.licenses, cardComponent: LicensesDroppableComponent, description: 'List of licenses', icon: ApprovalIcon },
    { title: 'Objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'Objective', icon: TrackChangesIcon },
    { title: 'Personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'Personal', icon: PermIdentityIcon },
    { title: 'Projects', cardType: cardTypes.projects, cardComponent: ProjectsDroppableComponent, description: 'Projects', icon: AccountTreeIcon },
    { title: 'Professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'Professional', icon: AccountTreeIcon },
    { title: 'Publications', cardType: cardTypes.publications, cardComponent: PublicationsDroppableComponent, description: 'Publications', icon: NewspaperIcon },
    { title: 'References', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'Reference', icon: PsychologyIcon },
    { title: 'Summary', cardType: cardTypes.summary, cardComponent: SummaryComponent, description: 'Profile', icon: SummarizeIcon },
    { title: 'Talents', cardType: cardTypes.talents, cardComponent: TalentsDroppableComponent, description: 'Talents', icon: PhotoCameraFrontIcon },
    { title: 'Trainings', cardType: cardTypes.trainings, cardComponent: TrainingsDroppableComponent, description: 'Trainings', icon: TrainIcon },
    { title: 'Volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersDroppableComponent, description: 'Volunteers', icon: VolunteerActivismIcon }
]

export const SingleColumnDefaultCards: defaultCard[] = [
    { title: 'Accomplishments', cardType: cardTypes.accomplishments, cardComponent: AccomplishmentsListComponent, description: 'List of accomplishments', icon: EmojiEventsIcon },
    { title: 'Affiliations', cardType: cardTypes.affiliations, cardComponent: AffiliationsDroppableComponent, description: 'List of affiliations', icon: HandshakeIcon },
    { title: 'Capabilities', cardType: cardTypes.capabilities, cardComponent: CapabilitiesListComponent, description: 'List of capabilities', icon: BoltIcon },
    { title: 'Educations', cardType: cardTypes.educations, cardComponent: EducationsDroppableComponent, description: 'List of educations', icon: SchoolIcon },
    { title: 'Employments', cardType: cardTypes.employments, cardComponent: EmploymentsDroppableComponent, description: 'List of employments', icon: WorkIcon },
    { title: 'Honors', cardType: cardTypes.honors, cardComponent: HonorsDroppableComponent, description: 'List of honors', icon: EmojiEventsIcon },
    { title: 'Licenses', cardType: cardTypes.licenses, cardComponent: LicensesDroppableComponent, description: 'List of licenses', icon: ApprovalIcon },
    { title: 'Objective', cardType: cardTypes.objective, cardComponent: ObjectiveComponent, description: 'Objective', icon: TrackChangesIcon },
    { title: 'Other', cardType: cardTypes.other, cardComponent: OtherComponent, description: 'Other', icon: AltRouteIcon },
    { title: 'Personal', cardType: cardTypes.personal, cardComponent: PersonalComponent, description: 'Personal', icon: PermIdentityIcon },
    { title: 'Projects', cardType: cardTypes.projects, cardComponent: ProjectsDroppableComponent, description: 'Projects', icon: AccountTreeIcon },
    { title: 'Professional', cardType: cardTypes.professional, cardComponent: ProfessionalComponent, description: 'Professional', icon: AccountTreeIcon },
    { title: 'Publications', cardType: cardTypes.publications, cardComponent: PublicationsDroppableComponent, description: 'Publications', icon: NewspaperIcon },
    { title: 'References', cardType: cardTypes.references, cardComponent: ReferencesComponent, description: 'Reference', icon: PsychologyIcon },
    { title: 'Skills', cardType: cardTypes.skills, cardComponent: SkillsBubbleListComponent, description: 'Skills', icon: StarIcon },
    { title: 'Summary', cardType: cardTypes.summary, cardComponent: SummaryComponent, description: 'Summary', icon: SummarizeIcon },
    { title: 'Talents', cardType: cardTypes.talents, cardComponent: TalentsDroppableComponent, description: 'Talents', icon: PhotoCameraFrontIcon },
    { title: 'Trainings', cardType: cardTypes.trainings, cardComponent: TrainingsDroppableComponent, description: 'Trainings', icon: TrainIcon },
    { title: 'Volunteers', cardType: cardTypes.volunteers, cardComponent: VolunteersDroppableComponent, description: 'Volunteers', icon: VolunteerActivismIcon }
]

type DefaultCardsPerStyleType = {
    [style in validResumeStyles]: defaultCard[]
}

export const AllDefaultCards: DefaultCardsPerStyleType = {
    Colorado: SingleColumnDefaultCards,
    Default: DefaultDefaultCards,
    Harvard: SingleColumnDefaultCards,
    Miami: SingleColumnDefaultCards,
    Resume1: Resume1DefaultCards,
    Resume2: Resume2DefaultCards,
    Resume4: Resume4DefaultCards,
    Resume6: Resume6DefaultCards,
    Resume8: Resume8DefaultCards
}

export const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
export const currentYear = new Date().getFullYear()
export const years = Array.from({ length: 101 }, (_, i) => currentYear - i)
export const currentMonth = new Date().getMonth()

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const compareArrays = (a: any, b: any) => a.length === b.length && a.every((element: any, index: number) => element === b[index])

export const cardStyleHover = {
    border: '1px solid black'
}

const noDragCardStyle = {
    left: '0px'
}

const cardStyle = (spaceCard: number) => {
    return {
        left: '-40px',
        margin: '0px',
        marginBottom: `${spaceCard}px`,
        position: 'relative',
        zIndex: '1200'
    }
}

export const cardStyleToUse = (isDraggable: boolean, spaceCard: number) => {
    return isDraggable ? cardStyle(spaceCard) : { ...cardStyle(spaceCard), ...noDragCardStyle }
}

export const dragIconStyleHover = {
    borderTop: '1px solid black',
    borderLeft: '1px solid black',
    borderBottom: '1px solid black'
    //display: 'inline-block'
}

const noDragIconStyle = {
    display: 'none'
}

const dragIconStyle = {
    left: '-40px',
    marginTop: '4px',
    display: '-webkit-box',
    position: 'relative',
    zIndex: '1200'
    //display: 'none'
}

export const dragIconStyleToUse = (isDraggable: boolean) => {
    return isDraggable ? dragIconStyle : { ...dragIconStyle, ...noDragIconStyle }
}

export const camelToTitle = (camelCase: string) =>
    camelCase
        .replace(/([A-Z])/g, (match) => ` ${match}`)
        .replace(/^./, (match) => match.toUpperCase())
        .trim()

export const isValidISODate = (dateString: string): boolean => {
    const isoFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/

    if (!isoFormat.test(dateString)) {
        return false
    }

    const date = new Date(dateString)
    return !isNaN(date.getTime())
}

export const formatMoney = (amount: number): string => {
    const dollars = amount / 100
    return dollars.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
    })
}

export const capitalizeFirstLetter = (str: string): string => {
    if (!str) return ''
    return str.charAt(0).toUpperCase() + str.slice(1)
}

export const isTokenExpiringSoon = (token: string, thresholdInMinutes: number = 5): boolean => {
    if (!token) return false

    const decoded: TokenPayload = jwtDecode(token)
    const currentTime = Date.now() / 1000
    const expiryTime = decoded.exp
    const timeRemaining = expiryTime - currentTime

    return timeRemaining < thresholdInMinutes * 60
}

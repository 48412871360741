import { Box, Container, Grid } from '@mui/material'
import Stack from '@mui/material/Stack'
import React from 'react'
import { ContactComponent } from '../components/ContactComponent'
import { NameComponent } from '../components/NameComponent'
import { PictureComponent } from '../components/PictureComponent'
import SkillCirclesComponent from '../components/SkillCirclesComponent'
import { SummaryComponent } from '../components/SummaryComponent'
import { TitleComponent } from '../components/TitleComponent'
import BottomLeftImage from '../components/templates/images/bottom.png'
import TopLeftImage from '../components/templates/images/top-left.png'
import TopRightImage from '../components/templates/images/top-right.png'
import { IResume } from '../models/Resume'
import { AllDefaultCards, defaultCard } from '../utils/Util'

const ResumeOne = (resume: IResume): JSX.Element => {
    const orderUsedCards = AllDefaultCards.Resume1.filter((value: defaultCard) => {
        const style = resume.cardOrder[resume.currentStyle]
        if (style) {
            return (style[value.cardType] ?? -1) >= 0
        }
        return false
    }).sort((a: defaultCard, b: defaultCard) => {
        return (resume.cardOrder[resume.currentStyle][a.cardType] ?? -1) - (resume.cardOrder[resume.currentStyle][b.cardType] ?? -1)
    })

    return (
        <Stack direction='column'>
            <Stack
                direction='row'
                fontSize={resume.style[resume.currentStyle].BodyFontSize}
                fontStyle={resume.style[resume.currentStyle].BodyFontStyle}
                fontWeight={resume.style[resume.currentStyle].BodyFontWeight}
                fontFamily={resume.style[resume.currentStyle].BodyFontName}
                marginTop={`${resume.style[resume.currentStyle].SpaceHeader}px`}
                columnGap={0}>
                <Stack
                    direction='column'
                    width='100%'
                    ml={2}
                    sx={{ backgroundColor: '#f7f9f9' }}>
                    <Container>
                        <Grid
                            container
                            spacing={4}>
                            <Grid
                                item
                                xs={12}
                                container
                                alignItems='center'>
                                <Box>
                                    <Grid
                                        container
                                        zIndex={2}>
                                        <Grid
                                            item
                                            xs={12}
                                            md={4}>
                                            <Box
                                                sx={{
                                                    position: 'relative',
                                                    width: '450px',
                                                    height: '600px'
                                                }}>
                                                <Box
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '-23px',
                                                        left: '-56px',
                                                        width: '100%',
                                                        height: '100%',
                                                        backgroundImage: `url(${TopLeftImage})`,
                                                        backgroundSize: 'cover',
                                                        backgroundPosition: 'center',
                                                        zIndex: 0
                                                    }}
                                                />
                                                <PictureComponent
                                                    picWidth={275}
                                                    picHeight={275}
                                                    picAlign={'center'}
                                                    picMT={5}
                                                    picMR={12}
                                                />
                                                <Box sx={{ paddingLeft: '25px', width: '300px', zIndex: 10 }}>
                                                    <SummaryComponent />
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={8}>
                                            <Box
                                                sx={{
                                                    position: 'relative',
                                                    width: '500px',
                                                    height: '665px',
                                                    marginLeft: '68px'
                                                }}>
                                                <Box
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '-48px',
                                                        right: 0,
                                                        width: '100%',
                                                        height: '100%',
                                                        backgroundImage: `url(${TopRightImage})`,
                                                        backgroundSize: 'cover',
                                                        backgroundPosition: 'center',
                                                        zIndex: 0
                                                    }}
                                                />
                                                <Box
                                                    sx={{
                                                        position: 'relative',
                                                        paddingTop: '100px',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        height: '70%',
                                                        width: '75%',
                                                        margin: 'auto'
                                                    }}>
                                                    <NameComponent />
                                                    <Box
                                                        sx={{
                                                            fontSize: '40px',
                                                            fontWeight: 300,
                                                            color: 'rgb(192, 171, 164)',
                                                            wordBreak: 'break-word',
                                                            zIndex: 2,
                                                            position: 'inherit'
                                                        }}>
                                                        <TitleComponent />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>

                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '90%',
                                    margin: 'auto',
                                    position: 'relative',
                                    gap: '100px'
                                }}>
                                <Grid
                                    item
                                    xs={12}
                                    md={4}
                                    sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', zIndex: 2 }}>
                                    <Box sx={{ marginLeft: '10px' }}>
                                        <SkillCirclesComponent />
                                    </Box>
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            zIndex: 2,
                                            height: '350px',
                                            width: '350px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                            paddingTop: '30px'
                                        }}>
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: 0,
                                                left: '-56px',
                                                width: '100%',
                                                height: '100%',
                                                backgroundImage: `url(${BottomLeftImage})`,
                                                backgroundSize: 'cover',
                                                zIndex: 0
                                            }}
                                        />
                                        <ContactComponent includeIcons={false} />
                                    </Box>
                                </Grid>
                                <Box>
                                    <Stack
                                        direction='column'
                                        paddingLeft={3}
                                        paddingRight={3}
                                        sx={{ backgroundColor: '#f7f9f9', paddingTop: '16px', gap: '12px' }}>
                                        {orderUsedCards.map((value: defaultCard) => {
                                            return <value.cardComponent key={value.cardType} />
                                        })}
                                    </Stack>
                                </Box>
                            </Box>
                        </Grid>
                    </Container>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default ResumeOne

import { Container, Stack } from '@mui/material'
import React from 'react'
import { AllDefaultCards, defaultCard } from 'utils/Util'
import { ContactComponent } from '../components/ContactComponent'
import EducationListComponent from '../components/EducationListComponent'
import EmploymentListComponent from '../components/EmploymentListComponent'
import LicenseListComponent from '../components/LicenseListComponent'
import { NameComponent } from '../components/NameComponent'
import ShowInitialsComponent from '../components/ShowInitialsComponent'
import { SkillsBulletListComponent } from '../components/SkillsBulletListComponent'
import { IResume } from '../models/Resume'

const Resume6 = (resume: IResume): JSX.Element => {
    const orderUsedCards = AllDefaultCards.Resume6.filter((value: defaultCard) => {
        const style = resume.cardOrder[resume.currentStyle]
        if (style) {
            return (style[value.cardType] ?? -1) >= 0
        }
        return false
    }).sort((a: defaultCard, b: defaultCard) => {
        return (resume.cardOrder[resume.currentStyle][a.cardType] ?? -1) - (resume.cardOrder[resume.currentStyle][b.cardType] ?? -1)
    })

    const style = resume.style[resume.currentStyle]

    return (
        <Stack direction='column'>
            <Container sx={{ backgroundColor: '#c2b280' }}>
                <Container sx={{ border: 2, width: 60, height: 60, textAlign: 'center', color: 'white', mt: 3 }}>
                    <ShowInitialsComponent />
                </Container>
                <NameComponent sx={{ color: 'white' }} />
            </Container>

            <Stack
                direction='row'
                fontSize={style.BodyFontSize}
                fontStyle={style.BodyFontStyle}
                fontWeight={style.BodyFontWeight}
                fontFamily={style[style.BodyFontName]}
                columnGap={0}>
                <Stack
                    direction='column'
                    width='540px'
                    paddingLeft={0}
                    paddingRight={3}>
                    {orderUsedCards.map((value: defaultCard) => {
                        return <value.cardComponent key={value.cardType} />
                    })}
                </Stack>
                <Stack
                    direction='column'
                    width='275px'
                    paddingLeft={3}
                    paddingRight={3}
                    bgcolor='#e5e4e2'
                    rowGap={`${style.SpaceSection}px`}>
                    <ContactComponent
                        includeLabel={false}
                        includeAddress={true}
                        hideState={false}
                        hideCity={false}
                        hideStreet={true}
                        hideZip={false}
                        hideEmail={false}
                        hideLinkedIn={true}
                        hidePhone={false}
                        includeIcons={false}
                    />
                    <SkillsBulletListComponent />
                    <EmploymentListComponent />
                    <EducationListComponent />
                    <LicenseListComponent />
                </Stack>
            </Stack>
        </Stack>
    )
}

export default Resume6

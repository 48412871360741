import React, { useContext } from 'react'
import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { IEmployment } from '../models/Employment'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'
import DroppableComponent from './DroppableComponent'
import EmploymentDraggableComponent from './EmploymentDraggableComponent'

const AffiliationsDroppableComponent = ({ sectionBgColor = '' }: { sectionBgColor?: string }): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const { resume } = euContext

    return (
        <DroppableComponent<IEmployment>
            section='affiliations'
            card='affiliation'
            items={resume.affiliations.affiliation}
            label={resume.affiliations.label}
            sectionBgColor={sectionBgColor}
            DraggableComponent={EmploymentDraggableComponent}
        />
    )
}

export default AffiliationsDroppableComponent

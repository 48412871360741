import DragHandleIcon from '@mui/icons-material/DragHandle'
import { Box, Button, Grid, ListItem, ListItemIcon, Stack, Typography } from '@mui/material'
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state'
import HoverPopover from 'material-ui-popup-state/HoverPopover'
import React, { MouseEvent, useContext, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { useFormContext } from 'react-hook-form'

import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { useAddEmployment, useMinusEmployment, useUpdateResumeField } from '../hooks/useResume'
import { IEmployment } from '../models/Employment'
import { IStyles } from '../models/Styles'
import ViewStatementsDialog from '../pages/dialog/ViewStatementsDialog'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'
import { cardStyleHover, cardStyleToUse, dragIconStyleHover, dragIconStyleToUse } from '../utils/Util'
import ErrorState from '../views/ErrorState'
import LoadingState from '../views/LoadingState'
import FieldInputMonth from './forms/FieldInputMonth'
import FieldInputText from './forms/FieldInputText'
import FieldInputTextArea from './forms/FieldInputTextArea'

type Props = {
    compNum: number
    index: number
    section: string
    card: string
    isDraggable: boolean
}

const EmploymentDraggableComponent = ({ compNum, index, section, card, isDraggable }: Props): JSX.Element => {
    const fieldName = `${section}.${card}[${compNum}]`
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const { resume, setResume } = euContext
    const sectionObject = Object.getOwnPropertyDescriptor(resume, section)
    const cardObject = Object.getOwnPropertyDescriptor(sectionObject?.value, card)
    const { reset, setValue } = useFormContext()
    const [openDialog, setOpenDialog] = useState(false)

    const { mutate: addEmployment, isPending: isAdding, error: addError } = useAddEmployment()
    const { mutate: minusEmployment, isPending: isRemoving, error: removeError } = useMinusEmployment()
    const { mutate: updateResumeField, isPending: isUpdating, isError: updateError } = useUpdateResumeField()

    const handleAddEmployment = (evt: MouseEvent<HTMLElement>) => {
        evt.preventDefault()
        addEmployment(resume, {
            onSuccess: (data) => {
                setResume(data.resume)
                reset(data.resume)
            }
        })
    }

    const handleMinusEmployment = (evt: MouseEvent<HTMLElement>) => {
        evt.preventDefault()
        minusEmployment(
            { resume, compNum },
            {
                onSuccess: (data) => {
                    setResume(data.resume)
                    reset(data.resume)
                }
            }
        )
    }

    const openSkillsDialog = () => {
        setOpenDialog(true)
    }

    const closeSkillsDialog = () => {
        setOpenDialog(false)
    }

    const addValueToDescription = (value: string[]) => {
        if (value?.length > 0 && resume._id) {
            let addedDescription: string = cardObject?.value[compNum].summary.description?.slice() ?? ''
            if (addedDescription.length > 0) addedDescription += '\n'

            addedDescription += '- ' + value.join('\n- ')

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const myResume: any = { ...resume }
            const newEmployments: IEmployment[] = myResume[section][card]
            newEmployments[compNum]['summary']['description'] = addedDescription
            setResume(myResume)

            const descriptionField = `${fieldName}.summary.description`
            setValue(descriptionField, addedDescription, { shouldValidate: true })
            updateResumeField({ id: resume._id, fieldName: descriptionField, fieldValue: addedDescription })
        }
    }

    const addValueToSkill = (value: string) => {
        if (value && resume._id) {
            const addedSkill = cardObject?.value[compNum].skills.list?.slice() ?? []
            const maxSeqNum = addedSkill.reduce((max: number, skill: { seqNum: number }) => (skill.seqNum > max ? skill.seqNum : max), 0)

            addedSkill.push({
                seqNum: maxSeqNum + 1,
                item: value
            })

            const descriptionField = `${fieldName}.skills.list`
            setResume({
                ...resume,
                [descriptionField]: addedSkill
            })
            setValue(descriptionField, addedSkill, { shouldValidate: true })
            updateResumeField({ id: resume._id, fieldName: descriptionField, fieldValue: addedSkill })
        }
    }

    const addValueToTitle = (value: string) => {
        if (value && resume._id) {
            const descriptionField = `${fieldName}.title`
            setResume({
                ...resume,
                [descriptionField]: value
            })
            setValue(descriptionField, value, { shouldValidate: true })
            updateResumeField({ id: resume._id, fieldName: descriptionField, fieldValue: value })
        }
    }

    if (isAdding || isRemoving || isUpdating) {
        return <LoadingState />
    }

    if (addError || removeError || updateError) {
        return <ErrorState message={'Error updating employment.'} />
    }

    return (
        <PopupState
            variant='popover'
            popupId='skillsList'>
            {(popupState) => (
                <>
                    {!!euContext.user?.email && (
                        <>
                            <HoverPopover
                                {...bindPopover(popupState)}
                                disableScrollLock={true}
                                sx={{ top: '-40px' }}
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
                                <Stack
                                    direction='row'
                                    columnGap={1}
                                    margin={0.5}>
                                    <Button
                                        onClick={openSkillsDialog}
                                        size='small'
                                        variant='outlined'
                                        sx={{ paddingLeft: 1, paddingRight: 1, margin: 0 }}>
                                        AI Assist
                                    </Button>
                                    <Button
                                        onClick={handleAddEmployment}
                                        type='button'
                                        size='small'
                                        variant='outlined'
                                        sx={{ padding: 0, margin: 0, width: '20px' }}>
                                        +
                                    </Button>
                                    <Button
                                        onClick={handleMinusEmployment}
                                        type='button'
                                        size='small'
                                        variant='outlined'
                                        sx={{ padding: 0, margin: 0, width: '20px' }}>
                                        -
                                    </Button>
                                </Stack>
                            </HoverPopover>
                            <ViewStatementsDialog
                                open={openDialog}
                                onClose={closeSkillsDialog}
                                addValueToDescription={addValueToDescription}
                                selectJobSkill={addValueToSkill}
                                selectJobTitle={addValueToTitle}
                            />
                        </>
                    )}
                    <Draggable
                        draggableId={cardObject?.value[compNum].title + index}
                        index={index}
                        isDragDisabled={!euContext.user?.email}>
                        {(provided) => (
                            <Stack
                                direction='row'
                                width='100%'
                                display='-webkit-box'>
                                <ListItem
                                    ref={provided.innerRef}
                                    sx={{ width: '100%' }}
                                    {...provided.draggableProps}>
                                    <ListItemIcon
                                        {...provided.dragHandleProps}
                                        sx={popupState.isOpen ? { ...dragIconStyleToUse(isDraggable), ...dragIconStyleHover } : dragIconStyleToUse(isDraggable)}>
                                        <DragHandleIcon />
                                    </ListItemIcon>
                                    <Box
                                        width='100%'
                                        rowGap={0}
                                        sx={popupState.isOpen ? { ...cardStyleToUse(isDraggable, style.SpaceCard), ...cardStyleHover } : cardStyleToUse(isDraggable, style.SpaceCard)}
                                        {...bindHover(popupState)}>
                                        <Stack
                                            direction='column'
                                            spacing={0}
                                            rowGap={0}
                                            sx={{ flexGrow: 1 }}>
                                            <FieldInputText
                                                name={`${fieldName}.title`}
                                                label=''
                                                variant='standard'
                                                placeholder={popupState.isOpen ? 'Enter Job Title' : ''}
                                                fontFamily={style[style.JobTitleFontName]}
                                                fontSize={`${style?.JobTitleFontSize ?? 5}px`}
                                                fontStyle={style?.JobTitleFontStyle ?? 'initial'}
                                                alignSelf={style?.JobTitleAlign ?? 'end'}
                                                fontWeight={style?.JobTitleFontWeight ?? 'initial'}
                                            />
                                            <Grid
                                                container
                                                spacing={0}
                                                direction='row'
                                                justifyContent='space-between'
                                                sx={{ flexGrow: 1 }}>
                                                <Grid
                                                    item
                                                    xs='auto'>
                                                    <FieldInputText
                                                        name={`${fieldName}.companyName`}
                                                        label=''
                                                        sx={{ textTransform: style?.CompanyFontCase ?? 'initial', alignItems: style?.CompanyAlign }}
                                                        fullWidth
                                                        placeholder={popupState.isOpen ? 'Company' : ''}
                                                        fontFamily={style[style.CompanyFontName]}
                                                        fontSize={`${style?.CompanyFontSize ?? 5}px`}
                                                        fontStyle={style?.CompanyFontStyle ?? 'initial'}
                                                        fontWeight={style?.CompanyFontWeight ?? 'initial'}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs='auto'
                                                    alignContent={style?.DateAlign ?? 'end'}
                                                    display={'inline-flex'}>
                                                    <FieldInputMonth
                                                        name={`${fieldName}.start`}
                                                        label=''
                                                        variant='standard'
                                                        sx={{ textTransform: style?.DateFontCase ?? 'initial' }}
                                                        fontSize={`${style?.DateFontSize ?? 5}px`}
                                                        fontStyle={style?.DateFontStyle ?? 'initial'}
                                                        alignSelf={style?.DateAlign ?? 'end'}
                                                        fontWeight={style?.DateFontWeight ?? 'initial'}
                                                        dateFormat={style?.DateFormat ?? 'yyyy'}
                                                    />
                                                    <Typography
                                                        sx={{ ml: 1, mr: 1, textTransform: style?.DateFontCase ?? 'initial' }}
                                                        fontFamily={style[style.DateFontName]}
                                                        fontSize={`${style?.DateFontSize ?? 5}px`}
                                                        fontStyle={style?.DateFontStyle ?? 'initial'}
                                                        alignSelf={style?.DateAlign ?? 'end'}
                                                        fontWeight={style?.DateFontWeight ?? 'initial'}>
                                                        {style?.DateDelim}
                                                    </Typography>
                                                    <FieldInputMonth
                                                        name={`${fieldName}.end`}
                                                        label=''
                                                        variant='standard'
                                                        includePresent={true}
                                                        sx={{ textTransform: style?.DateFontCase ?? 'initial' }}
                                                        fontSize={`${style?.DateFontSize ?? 5}px`}
                                                        fontStyle={style?.DateFontStyle ?? 'initial'}
                                                        alignSelf={style?.DateAlign ?? 'end'}
                                                        fontWeight={style?.DateFontWeight ?? 'initial'}
                                                        dateFormat={style?.DateFormat ?? 'yyyy'}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <FieldInputTextArea
                                                name={`${fieldName}.summary.description`}
                                                label=''
                                                placeholder={popupState.isOpen ? 'Enter Job Description' : ''}
                                                fontSize={`${style?.BodyFontSize ?? 5}px`}
                                                fontStyle={style?.BodyFontStyle ?? 'initial'}
                                                alignSelf={style?.BodyAlign ?? 'end'}
                                                fontWeight={style?.BodyFontWeight ?? 'initial'}
                                            />
                                        </Stack>
                                    </Box>
                                </ListItem>
                            </Stack>
                        )}
                    </Draggable>
                </>
            )}
        </PopupState>
    )
}

export default EmploymentDraggableComponent

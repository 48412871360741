import { Stack } from '@mui/material'
import { EmploymentUniverseContext } from 'context/EmploymentUniverseContext'
import MuiPhoneNumber from 'material-ui-phone-number'
import React, { FocusEvent, useContext, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useUpdateResumeField } from '../../hooks/useResume'
import { IStyles } from '../../models/Styles'

type Props = {
    name: string
    label: string
    fullWidth?: boolean
    fontSize?: string
    fontStyle?: string
    fontWeight?: string
    alignSelf?: string
}

const FieldInputTel = ({ name, label, fullWidth = false, fontSize = '12px', fontStyle = 'inherit', fontWeight = 'inherit', alignSelf = 'inherit' }: Props): JSX.Element => {
    const { control } = useFormContext()
    const euContext = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const { setValue } = useFormContext()
    const [editable, setEditable] = useState(false)
    const { mutate: updateResumeField } = useUpdateResumeField()

    const handleBlur = async (evt: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value: string | null = evt.target.value

        if (value && euContext.resume._id) {
            // Update resume field in database.
            updateResumeField(
                { id: euContext.resume._id, fieldName: name, fieldValue: value },
                {
                    onSuccess: (data) => {
                        euContext.setResume(data.resume)
                    }
                }
            )

            // Update form field in context.
            setValue(name, value)
            setEditable(false)
        }
    }

    const handleClick = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        evt.preventDefault()
        setEditable(true)
    }

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Stack onClick={handleClick}>
                    <MuiPhoneNumber
                        type='tel'
                        value={value}
                        onBlur={handleBlur}
                        onChange={onChange}
                        label={label}
                        fullWidth={fullWidth}
                        disabled={!editable}
                        disableDropdown={!editable}
                        variant='standard'
                        size='small'
                        sx={{
                            ml: '1rem',
                            mt: '.3rem',
                            fontFamily: style[style.BodyFontName],
                            fontSize,
                            fontStyle,
                            fontWeight,
                            alignSelf
                        }}
                        defaultCountry='us'
                        disableAreaCodes
                        countryCodeEditable={false}
                        InputLabelProps={{ shrink: true }}
                        helperText={error ? error.message : ''}
                        error={!!error}
                    />
                </Stack>
            )}
        />
    )
}

export default FieldInputTel

import React, { useContext, useRef, useState } from 'react'

import { Stack } from '@mui/material'
import { EmploymentUniverseContext } from 'context/EmploymentUniverseContext'
import { EmploymentUniverseType } from 'types/EmploymentUniverseType'
import useGetElementPosition from '../hooks/useGetElementPosition'
import { IStyles } from '../models/Styles'
import EditModal from './EditModal'
import FieldInputTypography from './forms/FieldInputTypography'

const TitleContent = ({ style, isHighlighted, onClick, sx }: { style: IStyles; isHighlighted?: boolean; onClick?: () => void; sx?: object }) => (
    <Stack
        spacing={0}
        direction='column'
        marginBottom={`${style?.SpaceCard ?? 10}px`}
        onClick={onClick}
        sx={{
            cursor: onClick ? 'pointer' : 'default',
            border: isHighlighted ? '2px solid #1976d2' : 'none',
            boxShadow: isHighlighted ? '0px 0px 10px rgba(25, 118, 210, 0.5)' : 'none',
            borderRadius: isHighlighted ? '8px' : 'none',
            padding: isHighlighted ? '10px' : '0'
        }}>
        <FieldInputTypography
            name='title'
            variant='h2'
            fontFamily={style[style.TitleFontName]}
            fontSize={`${style?.TitleFontSize ?? 40}px`}
            fontWeight={style?.TitleFontWeight ?? 'initial'}
            fontStyle={style?.TitleFontStyle ?? 'initial'}
            textAlign={style?.TitleAlign ?? 'end'}
            marginBottom={`${style?.SpaceSectionTitle}px`}
            textTransform={style?.TitleFontCase ?? 'none'}
            {...sx}
        />
    </Stack>
)

export const TitleComponent = ({ sx }: { sx?: object }): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const titleRef = useRef<HTMLDivElement | null>(null)
    const [isModalOpen, setModalOpen] = useState(false)

    const modalPosition = useGetElementPosition(titleRef, isModalOpen, -420, 0)

    const handleOpenModal = () => {
        setModalOpen(true)
    }

    const handleCloseModal = () => {
        setModalOpen(false)
    }

    return (
        <>
            <div ref={titleRef}>
                <TitleContent
                    style={style}
                    onClick={handleOpenModal}
                    isHighlighted={isModalOpen}
                    sx={sx}
                />
            </div>
            <EditModal
                open={isModalOpen}
                onClose={handleCloseModal}
                title='Edit Title'
                modalPosition={modalPosition}
                component={
                    <TitleContent
                        style={style}
                        key='title-modal'
                        sx={sx}
                    />
                }
            />
        </>
    )
}

import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined'
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined'
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined'
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined'
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined'
import SettingsAccessibilityOutlinedIcon from '@mui/icons-material/SettingsAccessibilityOutlined'
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined'

import React, { ReactNode } from 'react'

export interface INavigationItem {
    id: number
    icon: ReactNode
    label: string
    route: string
    isAdmin?: boolean
    isSuperAdmin?: boolean
}

export const loggedOutNavigationItems: INavigationItem[] = [
    {
        id: 0,
        icon: <LoginOutlinedIcon />,
        label: 'login',
        route: '/login'
    },
    {
        id: 1,
        icon: <PersonAddAltOutlinedIcon />,
        label: 'register',
        route: '/register'
    },
    {
        id: 2,
        icon: <LockResetOutlinedIcon />,
        label: 'forgotPassword',
        route: '/forgot-password'
    }
]

export const mainNavigationItems: INavigationItem[] = [
    {
        id: 12,
        icon: <SettingsAccessibilityOutlinedIcon />,
        label: 'askOpenAI',
        route: '/askOpenAI',
        isAdmin: true
    },
    {
        id: 11,
        icon: <DashboardOutlinedIcon />,
        label: 'dashboard',
        route: '/dashboard'
    },
    {
        id: 16,
        icon: <SettingsSuggestOutlinedIcon />,
        label: 'settings',
        route: '/settings',
        isAdmin: true
    },
    {
        id: 10,
        icon: <EditNoteOutlinedIcon />,
        label: 'createresumes',
        route: '/createResumeStepperPage'
    },
    {
        id: 13,
        icon: <CategoryOutlinedIcon />,
        label: 'assignStatements',
        route: '/assignStatements',
        isAdmin: true
    },
    { id: 15, icon: <SettingsSuggestOutlinedIcon />, label: 'clients', route: '/clients', isAdmin: false, isSuperAdmin: true },
    { id: 16, icon: <PeopleOutlinedIcon />, label: 'users', route: '/users', isAdmin: false, isSuperAdmin: true },
    { id: 17, icon: <SettingsSuggestOutlinedIcon />, label: 'checkout', route: '/checkout', isAdmin: true }
]

import CallOutlinedIcon from '@mui/icons-material/CallOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined'
import { Box, Stack, Typography } from '@mui/material'
import React, { useContext, useRef, useState } from 'react'

import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import useGetElementPosition from '../hooks/useGetElementPosition'
import { IPhone } from '../models/Phone'
import { IStyles } from '../models/Styles'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'
import { AddressComponent } from './AddressComponent'
import EditModal from './EditModal'
import FieldInputEmail from './forms/FieldInputEmail'
import FieldInputText from './forms/FieldInputText'

interface ContactInterface {
    includeLabel?: boolean
    includeAddress?: boolean
    hideStreet?: boolean
    hideCity?: boolean
    hideState?: boolean
    hideZip?: boolean
    hideEmail?: boolean
    hidePhone?: boolean
    hideLinkedIn?: boolean
    sectionBgColor?: string
    includeIcons?: boolean
}

const ContactContent = ({
    includeLabel,
    includeAddress,
    hideState,
    hideCity,
    hideStreet,
    hideZip,
    hideEmail,
    hidePhone,
    hideLinkedIn,
    sectionBgColor,
    includeIcons,
    style,
    isHighlighted,
    onClick,
    euContext
}: ContactInterface & { style: IStyles; isHighlighted?: boolean; onClick?: () => void; euContext: EmploymentUniverseType }) => (
    <Stack
        sx={{
            mt: '1rem',
            zIndex: 2,
            cursor: onClick ? 'pointer' : 'default',
            border: isHighlighted ? '2px solid #1976d2' : 'none',
            boxShadow: isHighlighted ? '0px 0px 10px rgba(25, 118, 210, 0.5)' : 'none',
            borderRadius: isHighlighted ? '8px' : 'none',
            padding: isHighlighted ? '10px' : '0'
        }}
        direction='column'
        onClick={onClick}>
        {includeLabel && (
            <Typography
                fontFamily={style[style.SectionFontName]}
                fontSize={`${style?.SectionFontSize ?? 5}px`}
                fontStyle={style?.SectionFontStyle ?? 'initial'}
                alignSelf={style?.SectionAlign ?? 'initial'}
                textAlign={style?.SectionAlign ?? 'initial'}
                fontWeight={style?.SectionFontWeight ?? 'initial'}
                marginBottom={`${style?.SpaceSectionTitle}px`}
                width='100%'
                bgcolor={sectionBgColor}
                sx={{ textTransform: style?.SectionFontCase ?? 'none', zIndex: 2 }}>
                Contact
            </Typography>
        )}
        {includeAddress && (
            <Stack
                direction='row'
                sx={{ verticalAlign: 'middle' }}>
                {includeIcons && <HomeOutlinedIcon sx={{ mt: 0.5, mr: 1 }} />}
                <AddressComponent
                    hideCity={hideCity}
                    hideState={hideState}
                    hideStreet={hideStreet}
                    hideZip={hideZip}
                />
            </Stack>
        )}
        {!hideEmail && (
            <Stack
                direction='row'
                sx={{ verticalAlign: 'middle' }}>
                {includeIcons && <EmailOutlinedIcon sx={{ mt: 0.5, mr: 1 }} />}
                <FieldInputEmail
                    name='personalInfo.email'
                    label=''
                    variant='standard'
                    fullWidth
                    fontSize={`${style?.BodyFontSize ?? 5}px`}
                    fontStyle={style?.BodyFontStyle ?? 'initial'}
                    alignSelf={style?.BodyAlign ?? 'end'}
                    fontWeight={style?.BodyFontWeight ?? 'initial'}
                />
            </Stack>
        )}
        {!hidePhone &&
            euContext.resume.personalInfo.phone.map((val: IPhone, index: number) => {
                return (
                    <Box key={index}>
                        {includeIcons && <CallOutlinedIcon sx={{ mt: 1 }} />}
                        <Typography
                            fontFamily={style[style.BodyFontName]}
                            fontSize={`${style?.BodyFontSize ?? 5}px`}
                            fontStyle={style?.BodyFontStyle ?? 'initial'}
                            alignSelf={style?.BodyAlign ?? 'end'}
                            textAlign={style?.BodyAlign ?? 'end'}
                            fontWeight={style?.BodyFontWeight ?? 'initial'}>
                            {euContext.resume.personalInfo.phone[index].phone}
                        </Typography>
                    </Box>
                )
            })}
        {!hideLinkedIn && (
            <Stack direction='row'>
                {includeIcons && <LinkOutlinedIcon sx={{ mt: 0.5, mr: 1 }} />}
                <FieldInputText
                    name='personalInfo.linkedIn'
                    label=''
                    variant='standard'
                    fullWidth
                    placeholder='Enter your LinkedIn profile id'
                    fontFamily={style[style.BodyFontName]}
                    fontSize={`${style?.BodyFontSize ?? 5}px`}
                    fontStyle={style?.BodyFontStyle ?? 'initial'}
                    alignSelf={style?.BodyAlign ?? 'end'}
                    fontWeight={style?.BodyFontWeight ?? 'initial'}
                    sx={{ mt: 0.7 }}
                />
            </Stack>
        )}
    </Stack>
)

export const ContactComponent = ({
    includeLabel = true,
    includeAddress = false,
    hideState = false,
    hideCity = false,
    hideStreet = false,
    hideZip = false,
    hideEmail = false,
    hidePhone = false,
    hideLinkedIn = false,
    sectionBgColor = '',
    includeIcons = true
}: ContactInterface): JSX.Element => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]

    const [isModalOpen, setModalOpen] = useState(false)
    const contactRef = useRef<HTMLDivElement | null>(null)

    const modalPosition = useGetElementPosition(contactRef, isModalOpen, 250, -50)

    const handleOpenModal = () => {
        setModalOpen(true)
    }

    const handleCloseModal = () => {
        setModalOpen(false)
    }

    return (
        <>
            <div ref={contactRef}>
                <ContactContent
                    includeLabel={includeLabel}
                    includeAddress={includeAddress}
                    hideState={hideState}
                    hideCity={hideCity}
                    hideStreet={hideStreet}
                    hideZip={hideZip}
                    hideEmail={hideEmail}
                    hidePhone={hidePhone}
                    hideLinkedIn={hideLinkedIn}
                    sectionBgColor={sectionBgColor}
                    includeIcons={includeIcons}
                    style={style}
                    isHighlighted={isModalOpen}
                    onClick={handleOpenModal}
                    euContext={euContext}
                />
            </div>
            <EditModal
                open={isModalOpen}
                onClose={handleCloseModal}
                modalPosition={modalPosition}
                title='Edit Contact'
                component={
                    <ContactContent
                        includeLabel={includeLabel}
                        includeAddress={includeAddress}
                        hideState={hideState}
                        hideCity={hideCity}
                        hideStreet={hideStreet}
                        hideZip={hideZip}
                        hideEmail={hideEmail}
                        hidePhone={hidePhone}
                        hideLinkedIn={hideLinkedIn}
                        sectionBgColor={sectionBgColor}
                        includeIcons={includeIcons}
                        style={style}
                        key='contact-modal'
                        euContext={euContext}
                    />
                }
            />
        </>
    )
}

/* eslint-disable indent */
export enum HTMLColorNameValues {
    euBlue = '#071952',
    // red colors
    darkRed = 'darkRed',
    red = 'red',
    lightRed = 'lightRed',
    // pink colors
    darkPink = 'darkPink',
    pink = 'pink',
    lightPink = 'lightPink',
    // purple colors
    darkPurple = 'darkPurple',
    purple = 'purple',
    lightPurple = 'lightPurple',
    // deep purple colors
    darkDeepPurple = 'darkDeepPurple',
    deepPurple = 'deepPurple',
    lightDeepPurple = 'lightDeepPurple',
    // indigo colors
    darkIndigo = 'darkIndigo',
    indigo = 'indigo',
    lightIndigo = 'lightIndigo',
    // blue colors
    darkBlue = 'darkBlue',
    blue = 'blue',
    lightBlue = 'lightBlue',
    // light blue colors
    darkCyan = 'darkCyan',
    cyan = 'cyan',
    lightCyan = 'lightCyan',
    // teal colors
    darkTeal = 'darkTeal',
    teal = 'teal',
    lightTeal = 'lightTeal',
    // green colors
    darkGreen = 'darkGreen',
    green = 'green',
    // light green colors
    darkLightGreen = 'darkLightGreen',
    lightGreen = 'lightGreen',
    lightLightGreen = 'lightLightGreen',
    // lime colors
    darkLime = 'darkLime',
    lime = 'lime',
    lightLime = 'lightLime',
    // yellow colors
    darkYellow = 'darkYellow',
    yellow = 'yellow',
    lightYellow = 'lightYellow',
    // amber colors
    darkAmber = 'darkAmber',
    amber = 'amber',
    lightAmber = 'lightAmber',
    // orange colors
    darkOrange = 'darkOrange',
    orange = 'orange',
    lightOrange = 'lightOrange',
    // deep orange colors
    darkDeepOrange = 'darkDeepOrange',
    deepOrange = 'deepOrange',
    lightDeepOrange = 'lightDeepOrange',
    // brown colors
    darkBrown = 'darkBrown',
    brown = 'brown',
    lightBrown = 'lightBrown',
    // grey colors
    darkGrey = 'darkGrey',
    grey = 'grey',
    lightGrey = 'lightGrey',
    // blue grey colors
    darkBlueGrey = 'darkBlueGrey',
    blueGrey = 'blueGrey',
    lightBlueGrey = 'lightBlueGrey',
    // black & white colors
    black = 'black',
    white = 'white',
    // transparent
    transparent = 'transparent'
}

export interface IHTMLColorNameItem {
    backgroundColor: HTMLColorNameValues
    textColor: HTMLColorNameValues
    borderBackgroundColor: HTMLColorNameValues
    borderColor: HTMLColorNameValues
    hoverBackgroundColor: HTMLColorNameValues
    hoverTextColor: HTMLColorNameValues
    buttonBackgroundColor: HTMLColorNameValues
    buttonTextColor: HTMLColorNameValues
    inputBackgroundColor: HTMLColorNameValues
    inputTextColor: HTMLColorNameValues
    linkBackgroundColor: HTMLColorNameValues
    linkTextColor: HTMLColorNameValues
    buttonTextSecondary: HTMLColorNameValues
}

export interface IHTMLColorName {
    navigationBar: IHTMLColorNameItem
    footer: IHTMLColorNameItem
    login: IHTMLColorNameItem
    header: IHTMLColorNameItem
    card: IHTMLColorNameItem
}

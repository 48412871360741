import { MenuItem, Select, TableCell, TableRow, TextField, Typography, type SelectChangeEvent } from '@mui/material'
import React, { FocusEvent, useContext, useState } from 'react'
import { EmploymentUniverseContext } from '../context/EmploymentUniverseContext'
import { useUpdateResumeField } from '../hooks/useResume'
import { IResume } from '../models/Resume'
import { Alignments, FontCases, FontStyles, FontWeights, IStyles } from '../models/Styles'
import { EmploymentUniverseType } from '../types/EmploymentUniverseType'

const FontSettingComponent = ({ fieldName }: { fieldName: string }) => {
    const euContext: EmploymentUniverseType = useContext(EmploymentUniverseContext)
    const style: IStyles = euContext.resume.style[euContext.resume.currentStyle]
    const { mutate: updateResumeField } = useUpdateResumeField()
    type ObjectKey = keyof typeof style
    const sizeField = `${fieldName}FontSize` as ObjectKey
    const fieldFontSize: number = style[sizeField] as number
    const [fontSize, setFontSize] = useState<number>(fieldFontSize)
    const caseField = `${fieldName}FontCase` as ObjectKey
    const weightField = `${fieldName}FontWeight` as ObjectKey
    const styleField = `${fieldName}FontStyle` as ObjectKey
    const alignmentField = `${fieldName}Align` as ObjectKey

    const changeFontCase = async (event: SelectChangeEvent<string>, fieldName: string) => {
        event.preventDefault()
        event.stopPropagation()

        const newValue = event.target.value
        if (!newValue) return

        const resume: IResume = euContext.resume
        const fontCase: FontCases = newValue as FontCases
        const longFieldName: string = `style.${euContext.resume.currentStyle}.${fieldName}`

        updateResumeField(
            { id: resume._id, fieldName: longFieldName, fieldValue: fontCase },
            {
                onSuccess: (data) => {
                    euContext.setResume(data.resume)
                }
            }
        )
    }

    const changeFontWeight = async (event: SelectChangeEvent<string>, fieldName: string) => {
        event.preventDefault()
        event.stopPropagation()

        const newValue = event.target.value
        if (!newValue) return

        const resume: IResume = euContext.resume
        const fontWeight: FontWeights = newValue as FontWeights
        const longFieldName: string = `style.${euContext.resume.currentStyle}.${fieldName}`

        updateResumeField(
            { id: resume._id, fieldName: longFieldName, fieldValue: fontWeight },
            {
                onSuccess: (data) => {
                    euContext.setResume(data.resume)
                }
            }
        )
    }

    const changeFontStyle = async (event: SelectChangeEvent<string>, fieldName: string) => {
        event.preventDefault()
        event.stopPropagation()

        const newValue = event.target.value
        if (!newValue) return

        const resume: IResume = euContext.resume
        const fontStyles: FontStyles = newValue as FontStyles
        const longFieldName: string = `style.${euContext.resume.currentStyle}.${fieldName}`

        updateResumeField(
            { id: resume._id, fieldName: longFieldName, fieldValue: fontStyles },
            {
                onSuccess: (data) => {
                    euContext.setResume(data.resume)
                }
            }
        )
    }

    const changeAlignment = async (event: SelectChangeEvent<string>, fieldName: string) => {
        event.preventDefault()
        event.stopPropagation()

        const newValue = event.target.value
        if (!newValue) return

        const resume: IResume = euContext.resume
        const alignment: Alignments = newValue as Alignments
        const longFieldName: string = `style.${euContext.resume.currentStyle}.${fieldName}`

        updateResumeField(
            { id: resume._id, fieldName: longFieldName, fieldValue: alignment },
            {
                onSuccess: (data) => {
                    euContext.setResume(data.resume)
                }
            }
        )
    }

    const blurFontSize = async (event: FocusEvent<HTMLSpanElement>, fieldName: string, fontSize: number) => {
        event.preventDefault()
        event.stopPropagation()

        const resume: IResume = euContext.resume
        const longFieldName: string = `style.${euContext.resume.currentStyle}.${fieldName}`

        updateResumeField(
            { id: resume._id, fieldName: longFieldName, fieldValue: fontSize },
            {
                onSuccess: (data) => {
                    euContext.setResume(data.resume)
                }
            }
        )
    }

    return (
        <>
            <TableRow>
                <TableCell>
                    <Typography>{fieldName} Font Case</Typography>
                </TableCell>
                <TableCell>
                    <Select
                        value={style[caseField] as FontCases}
                        onChange={(e) => changeFontCase(e, `${fieldName}FontCase`)}
                        size='small'
                        sx={{ width: '150px' }}>
                        {Object.values(FontCases).map((val, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={val}>
                                    {val}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    <Typography>{fieldName} Font Size</Typography>
                </TableCell>
                <TableCell>
                    <TextField
                        type='number'
                        name={`${fieldName}FontSize`}
                        label=''
                        size='small'
                        value={fontSize}
                        onBlur={(e) => blurFontSize(e, `${fieldName}FontSize`, fontSize)}
                        onChange={(e) => {
                            e.stopPropagation()
                            const newSize: number = +e.target.value
                            setFontSize(newSize)
                        }}
                    />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    <Typography>{fieldName} Font Weight</Typography>
                </TableCell>
                <TableCell>
                    <Select
                        value={style[weightField] as FontWeights}
                        onChange={(e) => changeFontWeight(e, `${fieldName}FontWeight`)}
                        size='small'
                        sx={{ width: '150px' }}>
                        {Object.values(FontWeights).map((val, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={val}>
                                    {val}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    <Typography>{fieldName} Font Style</Typography>
                </TableCell>
                <TableCell>
                    <Select
                        value={style[styleField] as FontStyles}
                        onChange={(e) => changeFontStyle(e, `${fieldName}FontStyle`)}
                        size='small'
                        sx={{ width: '150px' }}>
                        {Object.values(FontStyles).map((val, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={val}>
                                    {val}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    <Typography>{fieldName} Align</Typography>
                </TableCell>
                <TableCell>
                    <Select
                        value={style[alignmentField] as Alignments}
                        onChange={(e) => changeAlignment(e, `${fieldName}Align`)}
                        size='small'
                        sx={{ width: '150px' }}>
                        {Object.values(Alignments).map((val, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={val}>
                                    {val}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </TableCell>
            </TableRow>
        </>
    )
}

export default FontSettingComponent
